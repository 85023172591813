import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

//Import Components
import MiniCard from "./mini-card";
import MonthlyEarnings from "./montly-earnings";
import EmailSent from "./email-sent";
import MonthlyEarnings2 from "./montly-earnings2";
import Inbox from "./inbox";
import RecentActivity from "./recent-activity";
import WidgetUser from "./widget-user";
import YearlySales from "./yearly-sales";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "Dashboard", link : "#" },
                // { title : "Dashboard", link : "#" },
            ],
            reports : [
                { title : "Santander", icon : "mdi-cube-outline", result : "+11%", value : "$12,220.65", desc : "From last month", color : "info" },
                { title : "CitiBank", icon : "mdi-buffer", result : "-29%", value : "$25,070", desc : "From last month", color : "danger" },
                { title : "Bank of America", icon : "mdi-tag-text-outline", result : "0%", value : "$570.00", desc : "From last month", color : "warning" },
                { title : "Chase Bank", icon : "mdi-briefcase-check", result : "+89%", value : "$2,680.50", desc : "From last month", color : "info" },
            ],
        }
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Dashboard", this.state.breadcrumbItems);
    }

    render() {
        return (
            <React.Fragment>
                
                    <Row>
                        <MiniCard reports={this.state.reports} />
                    </Row>

                    <Row>
                        <Col xl="3">
                            {/* Monthly Earnings */}
                            <MonthlyEarnings/>
                        </Col>

                        <Col xl="6">
                            {/* Email sent */}
                            <EmailSent/>
                        </Col>

                        <Col xl="3">
                            <MonthlyEarnings2/>
                        </Col>

                    </Row>
                    

                    
                    
                    <Row>
                        <Col xl="6">
                            {/* latest transactions */}
                            <LatestTransactions/>
                        </Col>

                        <Col xl="6">
                            {/* latest orders */}
                            <LatestOrders/>
                        </Col>
                    </Row>

                    <Row>

                        <Col xl="4" lg="6">
                            {/* inbox */}
                            <Inbox/>
                        </Col>
                        <Col xl="4" lg="6">
                            {/* recent activity */}
                            <RecentActivity/>

                        </Col>
                        <Col xl="4">
                            {/* widget user */}
                            <WidgetUser/>

                            {/* yearly sales */}
                            <YearlySales/>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(Dashboard);