import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CardBody, CardTitle, FormGroup, Col, Row, Card, Input, Button, CardHeader, UncontrolledTooltip } from 'reactstrap';
import * as QueryString from 'query-string'

import IosInformationOutline from 'react-ionicons/lib/IosInformationCircleOutline'
import IosSettingsOutline from 'react-ionicons/lib/IosSettingsOutline'
import IosTrashOutline from 'react-ionicons/lib/IosTrashOutline'

import { useToasts } from 'react-toast-notifications'

import socketIOClient from 'socket.io-client'

//Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import user1 from "../../assets/images/users/user-1.jpg";
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";
import user7 from "../../assets/images/users/user-7.jpg";
import user8 from "../../assets/images/users/user-8.jpg";
import user9 from "../../assets/images/users/user-9.jpg";
import user10 from "../../assets/images/users/user-10.jpg";

import '../../assets/css/slim.css'

import { config } from '../../config'
const API_URL = config.url.API_URL
const CHAT_URL = config.url.CHAT_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

const scrollable = {
    overflowY: 'scroll',
    display: 'block',
    maxHeight: '200px'
}

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Messages", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            user : 
                { id : 1, imgUrl : user2, designation : "Founder", name : "Joyce Chua", desc : "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
                socials : [
                    { id : 1, title : "Facebook", icon : "fab fa-facebook-f", link : "#" },
                    { id : 2, title : "Twitter", icon : "fab fa-twitter", link : "#" },
                    { id : 3, title : "mobile", icon : "fa fa-phone", link : "#" },
                    { id : 4, title : "skype", icon : "fab fa-skype", link : "#" },
                ]
            },
            inputText: '',
            activeChat: 0,
            activePersonTitle: "Test",
            activePersonName: "Test",
            activePersonTitle: "Test",
            activePersonEmployer: "Test",
            activePersonDescription: "Test",
            currentChat: [],
            chats:[],
            lenderId: '',
            empId: '',
            conn: null
        }
        this.selectChat = this.selectChat.bind(this)
        this.submitMessage = this.submitMessage.bind(this)
    } 

    componentDidMount() {
        this.props.setBreadcrumbItems("Messages", this.state.breadcrumbItems);
        const empId = JSON.parse(localStorage.getItem('cb-lender-user'))._id
        //this.setState({empId: empId})
        this.setState({empId: empId, conn: socketIOClient(CHAT_URL+'?clientId='+empId, {})}, () => {
            this.state.conn.on("INITIAL_CONNECTION", (data) => {
                console.log("Initial Connection...")
                this.setState({chats: data.chats, currentChat: data.messages})
            })
            this.state.conn.on("CURRENT_MESSAGES", (msgs) => {
                console.log("Current Messages")
                console.log(msgs)
                this.setState({currentChat: msgs})
            })
            this.state.conn.on("SEND_MESSAGE", (msg) => {
                console.log('SEND MESSAGE')
                console.log(msg)
                console.log(this.state.activeChat)
                console.log(this.state.chats[this.state.activeChat])
                if (msg.chatId === this.state.chats[this.state.activeChat]._id) {
                    this.state.conn.emit("CURRENT_MESSAGES", msg.chatId)
                }
            })
            this.state.conn.emit('INITIAL_CONNECTION', {})
        })
    }

    componentWillUnmount() {
        this.state.conn.emit("EXIT", {})
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    selectChat(chatIndex) {
        this.setState({activeChat: chatIndex})
        this.state.conn.emit("CURRENT_MESSAGES", this.state.chats[chatIndex]._id)
    }

    submitMessage(e) {
        e.preventDefault()
        const currentChatId = this.state.chats[this.state.activeChat]._id
        this.state.conn.emit('SEND_MESSAGE',{ text: this.state.inputText, chatId: currentChatId })

        this.setState({
            currentChat: [...this.state.currentChat, { messagerId:'',message:this.state.inputText,timestamp: ''} ],
            inputText : ''
        })
        this.scrollToBottom();
        setTimeout(() => {this.setState({currentChat: [...this.state.currentChat, { messagerId:'ABC',message:'Just an echo',timestamp: ''} ]})}, 2000)
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        this._msgRef.scrollTop = 999999
    }

    notify() {
        toast.success('Rock my world!!')
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <Row>
                    <Col xs="9">
                        <Card>
                            <CardBody>
                                <div className="container container-messages with-sidebar">
                                    <div className="messages-left">
                                        <div className="messages-list">
                                            <PerfectScrollbar style={{height : "650px"}}>
                                                {this.state.chats.map((chat, index) => (
                                                    <a href="#" className="media" id={index} onClick={() => this.selectChat(index)}>
                                                        <div className="media-left">
                                                            <img src={user3} alt="" />
                                                            <span className="square-10 bg-success"></span>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <h6>{this.state.empId == chat.participant1Id ? chat.participant2FullName : chat.participant1FullName}</h6>
                                                                <p>{this.state.empId == chat.participant1Id ? chat.participant2Title : chat.participant1Title}<br /><a href="#">{this.state.empId == chat.participant1Id ? chat.participant2Company : chat.participant1Company}</a></p>
                                                            </div>
                                                            <div>
                                                                <span>{chat.dateStarted}</span>
                                                                <span>{5}</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                ))}
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                    <div className="messages-right">
                                        <div className="message-header">
                                            <a href="" className="message-back"><i className="fa fa-angle-left"></i></a>
                                            <div className="media">
                                                <img src={user2} alt="" />
                                                <div className="media-body">
                                                    <h6>{this.state.activePersonName} ({this.state.activePersonTitle} - <a href="#">{this.state.activePersonEmployer}</a>)</h6>
                                                    <p>Last seen: 2 hours ago</p>
                                                </div>
                                            </div>
                                            <div className="message-option">
                                                <div className="d-none d-sm-flex">
                                                    {/* <a href=""><i className="icon ion-ios-telephone-outline"></i></a>
                                                    <a href=""><i className="icon ion-ios-videocam-outline"></i></a>
                                                    <a href=""><i className="icon ion-ios-gear-outline"></i></a> */}
                                                    <IosSettingsOutline onClick={() => alert('Settings')} />
                                                    &nbsp;
                                                    <IosInformationOutline onClick={() => alert('Information')} />
                                                    &nbsp;
                                                    <IosTrashOutline onClick={() => alert('Delete?')}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="message-body">
                                            <PerfectScrollbar style={{height : "500px"}} containerRef={(ref) => this._msgRef = ref}>
                                                <div className="media-list">
                                                    {
                                                        this.state.currentChat.map((msg, index) => {
                                                            return msg.messagerId === this.state.empId ?
                                                                <div className="media">
                                                                    <img src={user10} alt="" />
                                                                    <div className="media-body">
                                                                        <div className="msg">
                                                                            <p>{msg.message}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            :
                                                                <div className="media">
                                                                    <div className="media-body reverse">
                                                                        <div className="msg">
                                                                            <p>{msg.message}</p>
                                                                        </div>
                                                                    </div>
                                                                    <img src={user2} className="wd-50 rounded-circle" alt="" />
                                                                </div>
                                                        })
                                                    }
                                                    <div style={{ float:"left", clear: "both" }} ref={(el) => { this.messagesEnd = el; }}></div>
                                                </div>
                                            </PerfectScrollbar>
                                        </div>
                                        <div className="message-footer">
                                            <div className="row row-sm">
                                                <div className="col-9 col-sm-8 col-xl-9">
                                                    <form onSubmit={this.submitMessage}>
                                                        <input type="text" className="form-control" placeholder="Type something here..." value={this.state.inputText} onChange={(e) => this.setState({inputText: e.target.value })} />
                                                    </form>
                                                </div>
                                                <div className="col-3 col-sm-4 col-xl-3 tx-right">
                                                    <div className="d-none d-sm-block">
                                                        <a href=""><i className="icon ion-happy-outline"></i></a>
                                                        <a href=""><i className="icon ion-ios-game-controller-b-outline"></i></a>
                                                        <a href=""><i className="icon ion-ios-camera-outline"></i></a>
                                                        <a href=""><i className="icon ion-ios-mic-outline"></i></a>
                                                    </div>
                                                    <div className="d-sm-none">
                                                        <a href=""><i className="icon ion-more"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>    
                    </Col>
                    <Col xs="3">
                        <Card className="directory-card">
                            <div>
                                <div className="directory-bg text-center">
                                    <div className="directory-overlay">
                                        <img className="rounded-circle avatar-lg img-thumbnail" src={this.state.user.imgUrl} alt="Generic placeholder"/>
                                    </div>
                                </div>
                                <div className="directory-content text-center p-4">
                                    <p className=" mt-4">{this.state.activePersonTitle}<br /><a href="#">{this.state.activePersonEmployer}</a></p>
                                    <h5 className="font-size-16">{this.state.activePersonName}</h5>
                                    <p className="text-muted">{this.state.activePersonDescription}</p>
                                    <Link to="/view-application">
                                        <Button>View Current Loan Application</Button>
                                    </Link>
                                    <ul className="social-links list-inline mb-0 mt-4">
                                        {
                                            this.state.user.socials.map((social, key) =>
                                                <li key={key} className="list-inline-item">
                                                    <Link title="" className="btn-primary tooltips" id={social.title+this.state.user.id} to={social.link}><i className={social.icon}></i></Link>
                                                    <UncontrolledTooltip placement="top" target={social.title+this.state.user.id}>
                                                        {social.title}
                                                    </UncontrolledTooltip>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Card>
                        <button onClick={this.notify}>Notify !</button>
                    </Col>
                </Row>        
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(Messages)