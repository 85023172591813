import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Card,
    CardBody, 
    Col,
    FormGroup, 
    Input,
    Row,
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledTooltip,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { setBreadcrumbItems } from '../../store/actions'
import { getTest } from '../../services/testService'
import { getLoggedInUser } from '../../helpers/authUtils'
import myTeam from '../../pages/Demo/my-team';

import CardUser from './card-user'
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";
import user7 from "../../assets/images/users/user-7.jpg";

import '../../assets/css/slim.css'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Team Management", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            username: "",
            dropdownOpen: false,
            users : [
                { id : 1, imgUrl : user2, designation : "Creative Director", name : "Katherine J. McAvoy", desc : "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
                socials : [
                        { id : 1, title : "Facebook", icon : "fab fa-facebook-f", link : "#" },
                        { id : 2, title : "Twitter", icon : "fab fa-twitter", link : "#" },
                        { id : 3, title : "mobile", icon : "fa fa-phone", link : "#" },
                        { id : 4, title : "skype", icon : "fab fa-skype", link : "#" },
                    ]
                },
                { id : 2, imgUrl : user3, designation : "Creative Director", name : "Richard L. Jackson", desc : "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
                socials : [
                        { id : 1, title : "Facebook", icon : "fab fa-facebook-f", link : "#" },
                        { id : 2, title : "Twitter", icon : "fab fa-twitter", link : "#" },
                        { id : 3, title : "mobile", icon : "fa fa-phone", link : "#" },
                        { id : 4, title : "skype", icon : "fab fa-skype", link : "#" },
                    ]
                },
                { id : 3, imgUrl : user4, designation : "Creative Director", name : "Joshua D. Pearson", desc : "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
                socials : [
                        { id : 1, title : "Facebook", icon : "fab fa-facebook-f", link : "#" },
                        { id : 2, title : "Twitter", icon : "fab fa-twitter", link : "#" },
                        { id : 3, title : "mobile", icon : "fa fa-phone", link : "#" },
                        { id : 4, title : "skype", icon : "fab fa-skype", link : "#" },
                    ]
                },
                { id : 4, imgUrl : user5, designation : "Creative Director", name : "Michael J. Folma", desc : "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
                socials : [
                        { id : 1, title : "Facebook", icon : "fab fa-facebook-f", link : "#" },
                        { id : 2, title : "Twitter", icon : "fab fa-twitter", link : "#" },
                        { id : 3, title : "mobile", icon : "fa fa-phone", link : "#" },
                        { id : 4, title : "skype", icon : "fab fa-skype", link : "#" },
                    ]
                },
                { id : 5, imgUrl : user6, designation : "Creative Director", name : "Samuel P. Augustus", desc : "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
                socials : [
                        { id : 1, title : "Facebook", icon : "fab fa-facebook-f", link : "#" },
                        { id : 2, title : "Twitter", icon : "fab fa-twitter", link : "#" },
                        { id : 3, title : "mobile", icon : "fa fa-phone", link : "#" },
                        { id : 4, title : "skype", icon : "fab fa-skype", link : "#" },
                    ]
                },
                { id : 6, imgUrl : user7, designation : "Creative Director", name : "Joseph D. Starnes", desc : "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
                socials : [
                        { id : 1, title : "Facebook", icon : "fab fa-facebook-f", link : "#" },
                        { id : 2, title : "Twitter", icon : "fab fa-twitter", link : "#" },
                        { id : 3, title : "mobile", icon : "fa fa-phone", link : "#" },
                        { id : 4, title : "skype", icon : "fab fa-skype", link : "#" },
                    ]
                }
            ]
        }
    } 

    toggle() {this.setState({dropdownOpen: !this.state.dropdownOpen })}

    componentDidMount(){
        this.props.setBreadcrumbItems("Team Management", this.state.breadcrumbItems);
        const result = getLoggedInUser() 
        console.log("Logged In User");
        console.log(result.username)
        this.setState({username: result.username})
    }

    render() {
        return (
            <React.Fragment>


        <div class="manager-wrapper">
          <div class="manager-right">
            <div class="row row-sm">
            {
                    this.state.users.map((user, key) =>
                        <Col xl="2" md="6" key={key}>
                            <Card className="directory-card">
                                <div>
                                    <div className="directory-bg text-center">
                                        <div className="directory-overlay">
                                            <img className="rounded-circle avatar-lg img-thumbnail" src={user.imgUrl} alt="Generic placeholder"/>
                                        </div>
                                    </div>

                                    <div className="directory-content text-center p-4">
                                        <p className=" mt-4">{user.designation}</p>
                                        <h5 className="font-size-16">{user.name}</h5>

                                        <p className="text-muted">{user.desc}</p>

                                        <ul className="social-links list-inline mb-0 mt-4">
                                            {
                                                user.socials.map((social, key) =>
                                                    <li key={key} className="list-inline-item">
                                                        <Link title="" className="btn-primary tooltips" id={social.title+user.id} to={social.link}><i className={social.icon}></i></Link>
                                                        <UncontrolledTooltip placement="top" target={social.title+user.id}>
                                                            {social.title}
                                                        </UncontrolledTooltip>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        <br />
                                        <a href="#" class="btn btn-primary btn-block">Manage User</a>
                                    </div>
                                    {/* <a href="/application-plaid" class="btn btn-primary btn-block">Access Here</a> */}
                                </div>
                            </Card>

                        </Col>
                    )
                }  
                </div>
                <div class="row row-sm">
            {
                    this.state.users.map((user, key) =>
                        <Col xl="2" md="6" key={key}>
                            <Card className="directory-card">
                                <div>
                                    <div className="directory-bg text-center">
                                        <div className="directory-overlay">
                                            <img className="rounded-circle avatar-lg img-thumbnail" src={user.imgUrl} alt="Generic placeholder"/>
                                        </div>
                                    </div>

                                    <div className="directory-content text-center p-4">
                                        <p className=" mt-4">{user.designation}</p>
                                        <h5 className="font-size-16">{user.name}</h5>

                                        <p className="text-muted">{user.desc}</p>

                                        <ul className="social-links list-inline mb-0 mt-4">
                                            {
                                                user.socials.map((social, key) =>
                                                    <li key={key} className="list-inline-item">
                                                        <Link title="" className="btn-primary tooltips" id={social.title+user.id} to={social.link}><i className={social.icon}></i></Link>
                                                        <UncontrolledTooltip placement="top" target={social.title+user.id}>
                                                            {social.title}
                                                        </UncontrolledTooltip>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    )
                }  
                </div>

          </div>
          <div class="manager-left">
            <a href="" class="btn btn-contact-new">Add Team Member</a>
            <nav class="nav">
              <a href="" class="nav-link active">
                <span>All Team Members</span>
                <span>120</span>
              </a>
              <a href="" class="nav-link">
                <span>All Managers</span>
                <span>16</span>
              </a>
              <a href="" class="nav-link">
                <span>All Employees</span>
                <span>68</span>
              </a>
            </nav>

            {/* <label class="section-label-sm mg-t-25">Location</label>
            <nav class="nav">
              <a href="" class="nav-link">
                <span>San Francisco</span>
                <span>10</span>
              </a>
              <a href="" class="nav-link">
                <span>Los Angeles</span>
                <span>16</span>
              </a>
              <a href="" class="nav-link">
                <span>New York</span>
                <span>15</span>
              </a>
              <a href="" class="nav-link">
                <span>Las Vegas</span>
                <span>4</span>
              </a>
              <a href="" class="nav-link">
                <span>Sacramento</span>
                <span>4</span>
              </a>
            </nav> */}

          </div>
      </div>
            </React.Fragment>
        )
    }
}

export default connect(null, {setBreadcrumbItems})(Team)