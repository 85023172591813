import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Button,
    Card,
    CardBody, 
    Col,
    FormGroup, 
    Input,
    Row
} from 'reactstrap';

import { setBreadcrumbItems } from "../../store/actions";
import { getTest } from '../../services/testService'
import { getLoggedInUser } from '../../helpers/authUtils'
import myTeam from '../../pages/Demo/my-team';

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class TeamMemberDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Team Member Detail", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            username: ""
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Team Member Detail", this.state.breadcrumbItems);
        const result = getLoggedInUser() 
        console.log("Logged In User");
        console.log(result.username)
        this.setState({username: result.username})
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Team Member Detail</h4>
                                <p className="card-title-desc">
                                    Coming Soon!
                                </p>
                                {/* <Button onClick={async () => {await getTest()}}>Hello</Button>
                                <p>Username: {this.state.username}</p> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>                
            </React.Fragment>
        )
    }
}

export default connect(null, {setBreadcrumbItems})(TeamMemberDetail)