import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Card, CardBody, Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
import produce from 'immer'
import axios from 'axios'

//Import Images
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { config } from '../../config'
const API_URL = config.url.API_URL
const BASEAPI = `${API_URL}/api/v1`


class MyApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "My Application", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            transactions : [
                { imgUrl : user2, name : "Credit Union of Pluto", status : "Approved", amount : "25,000", date : "5/12/2016", color : "success" },
                { imgUrl : user3, name : "Credit Union of Mars", status : "Received", amount : "7,500", date : "10/11/2016", color : "warning" },
                { imgUrl : user4, name : "Credit Union of Venus	", status : "Approved", amount : "1,000", date : "8/11/2016", color : "success" },
                { imgUrl : user5, name : "Credit Union of Mars", status : "Rejected", amount : "45,000", date : "7/11/2016", color : "danger" },
                { imgUrl : user6, name : "Credit Union of Venus", status : "Approved", amount : "10,000", date : "1/11/2016", color : "success" },
            ],
            applications : [],
            lenderName: "",
            loanDesc: "",
            amount: "",
            applied: "",
            status: ""
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("My Application", this.state.breadcrumbItems);

        const applicationId = this.props.match.params.id

        axios.get(`${BASEAPI}/applications/${applicationId}`).then(results => {
            // let arr = []
            // results.data.apiData.forEach(record => {
            //     let doc = {}
            //     doc['_id'] = record._id
            //     doc['cbid'] = record.cbid
            //     doc['lenderName'] = record.lenderName
            //     doc['lenderId'] = record.lenderId
            //     doc['loanDesc'] = record.loanDesc
            //     doc['amount'] = record.amount
            //     doc['applied'] = record.applied
            //     doc['status'] = record.status

            //     arr.push(doc)
            // })

            // this.setState(produce(draft => {
            //     draft.applications = arr
            // }))
            this.setState({
                lenderName: results.data.apiData.lenderName,
                loanDesc: results.data.apiData.loanDesc,
                amount: results.data.apiData.amount,
                applied: results.data.apiData.applied,
                status: results.data.apiData.status
            })
            
        })
    }

    render() {

        
        return (
            <React.Fragment>

                    <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">My Application Details</h4>
                                    <Col xs="6">
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Lender Name</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.lenderName} placeholder="Lender Name" id="lenderName" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Loan Description</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.loanDesc} placeholder="Lender City" id="lenderCity" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Amount</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.amount} placeholder="Lender State" id="lenderState" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Membership</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.membership} placeholder="Membership" id="membership" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Date Applied</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.applied} placeholder="Membership Fee" id="membershipFee" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Status</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.status} placeholder="Phone No" id="phoneNo" disabled />
                                        </Col>
                                    </FormGroup>
                                </Col>

                                    
                                </CardBody>
                            </Card>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(MyApplication)


