import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, FormGroup, Col, Row, Card, Input, Button } from 'reactstrap';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class ApplicationHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Application History", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            // username: ""
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Application History", this.state.breadcrumbItems);
        // const result = getLoggedInUser() 
        // console.log("Logged In User");
        // console.log(result.username)
        // this.setState({username: result.username})
    }

    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Application History</h4>
                                <p className="card-title-desc">
                                    Coming Soon!
                                </p>
                                {/* <Button onClick={async () => {await getTest()}}>Hello</Button>
                                <p>Username: {this.state.username}</p> */}
                                </CardBody>
                                </Card></Col></Row>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(ApplicationHistory)