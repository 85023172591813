import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, FormGroup, Col, Row, Card, Input, Button } from 'reactstrap';

import axios from 'axios'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class ApplicationPlaid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Plaid Access", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            applicantId: '5f80eb7c63c2b899057cfef2',
            applicationId: '',
            displayJSON: {}
        }

        this.getTransactions = this.getTransactions.bind(this)
        this.getIdentity = this.getIdentity.bind(this)
        this.getCredit = this.getCredit.bind(this)
        this.getHoldings = this.getHoldings.bind(this)
        this.getIncome = this.getIncome.bind(this)
        this.getLiabilities = this.getLiabilities.bind(this)
        this.getBalance = this.getBalance.bind(this)
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Plaid Access", this.state.breadcrumbItems);
        // const result = getLoggedInUser() 
        // console.log("Logged In User");
        // console.log(result.username)
        // this.setState({username: result.username})
        const applicationId = this.props.match.params.applicationId

        axios.get(`${BASEAPI}/lenders-utils/applications/${applicationId}/applicant-id`).then(result => {
            console.log(result)
            this.setState({applicantId: result.data.apiData})
        })

        this.setState({ applicationId: this.props.match.params.applicationId })
    }

    getTransactions() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders-plaid/${this.state.applicantId}/transactions`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getIdentity() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders-plaid/${this.state.applicantId}/identity`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getCredit() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders-plaid/${this.state.applicantId}/credit`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getHoldings() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders-plaid/${this.state.applicantId}/holdings`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getIncome() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders-plaid/${this.state.applicantId}/income`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getLiabilities() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders-plaid/${this.state.applicantId}/liabilities`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getBalance() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders-plaid/${this.state.applicantId}/balance`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Plaid Access</h4>
                                <Button onClick={this.getBalance}>Get Balance</Button> {' '}
                                <Button onClick={this.getTransactions}>Get Transactions</Button> {' '}
                                <Button onClick={this.getIdentity}>Get Identity</Button> {' '}
                                <Button onClick={this.getCredit}>Get Credit</Button> {' '}
                                <Button onClick={this.getHoldings}>Get Holdings</Button> {' '}
                                <Button onClick={this.getIncome}>Get Income</Button> {' '}
                                <Button onClick={this.getLiabilities}>Get Liabilities</Button>
                                <br />
                                <br />
                                <div>
                                    <pre>
                                        { JSON.stringify(this.state.displayJSON, null, 2)}
                                    </pre>
                                </div>
                                </CardBody>
                                </Card></Col></Row>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(ApplicationPlaid)