import axios from 'axios'
import { config } from '../config'

const API_URL = config.url.API_URL
const BASEAPI = `${API_URL}/api/v1`

const _getTest = async () => {
    let result = await axios.get(`${BASEAPI}/test`)
    console.log(result.data)
    if (result.data.apiStatus == 'success') return true; else return false;
}

export const getTest = async () => {
    return await _getTest()
}
