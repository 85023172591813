const testData = {
    personal : {
        fullName: "John C. Smith",
        ssn: "123-45-6789",
        address: {
            line1: "24 General Lane",
            line2: "Apt 3C",
            line3: "",
            city: "Altoona",
            state: "AL",
            zipcode: "12345"
        },
        telephone: "938-555-1234",
        email: "john.c.smith@justanemail.com",
        dob: ""
    },
    business : {
        businessName: "The Little Widget Shop",
        ein: "12-3456789",
        address: {
            line1: "123 Jones Street",
            line2: "",
            line3: "",
            city: "Altoona",
            state: "AL",
            zipcode: "12345"
        },
        telephone: "938-555-4321",
        website: "littlewidgetshop.com",
        established: "2009-10-23"
    },
    pki : {
        creditScore: 721,
        debtToIncomeRation: 85
    },
    documents: {},
    applicationDetails: {
        dateApplied: "",
        loanDetails: {},
        loanPurpose: "",
        history: {}
    }
}
export default testData 