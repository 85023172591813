import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardTitle, CardBody, Button, Table, CardHeader, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';
import produce from 'immer'
import axios from 'axios'

import {AgGridReact} from 'ag-grid-react'
import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css'
import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css'

//Import Images
import user1 from "../../assets/images/users/user-1.jpg";
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";

import { data } from '../../pages/Search/data'

import { config } from '../../config'
const API_URL = config.url.API_URL
const BASEAPI = `${API_URL}/api/v1`

class SearchWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages : [
                { imgUrl : user1, title : "Misty", desc : "Hey! there I'm available...", time : "13:40 PM" },
                { imgUrl : user2, title : "Melissa", desc : "I've finished it! See you so...", time : "13:34 PM" },
                { imgUrl : user3, title : "Dwayne", desc : "This theme is awesome!", time : "13:17 PM" },
                { imgUrl : user4, title : "Martin", desc : "Nice to meet you", time : "12:20 PM" },
                { imgUrl : user5, title : "Vincent", desc : "Hey! there I'm available...", time : "11:47 AM" },
                { imgUrl : user6, title : "Robert Chappa", desc : "Hey! there I'm available...", time : "10:12 PM" },
            ],
            columnDefs: [
				{headerName: "ID", field: "_id", sortable: true, filter: true },
				{headerName: "CBID", field: "cbid", sortable: true, filter: true },
                {headerName: "Name", field: "name", sortable: true, filter: true },
                {headerName: "City", field: "city", sortable: true, filter: true },
                {headerName: "State", field: "state", sortable: true, filter: true },
                {headerName: "Membership", field: "membership", sortable: true, filter: true },
                {headerName: "View", field: 'url', cellRenderer: (params) => {
                    var link = document.createElement('a');
                    link.href = '/lender/' + params.value;
                    // link.innerText = params.value;
                    link.innerText = 'View Details';
                    link.addEventListener('click', (e) => {
                        e.preventDefault();
                        console.log(params.data.id);
                        this.props.history.push('/lender/' + params.value)
                    });
                    return link;
                }}

			],
			rowData: []
        }
    }

    componentDidMount(){
        //this.props.setBreadcrumbItems("Search", this.state.breadcrumbItems);

        axios.get(`${BASEAPI}/lenders`).then(results => {
            console.log(results.data.apiData)
            
            let arr = []
            results.data.apiData.forEach(record => {
                let doc = {}
                doc['_id'] = record._id
                doc['cbid'] = record.cbid
                doc['name'] = record.name
                doc['city'] = record.address.city
                doc['state'] = record.address.state
                doc['membership'] = record.membership.type
                doc['url'] = record._id

                arr.push(doc)
            })

            // this.setState(produce(draft => {
            //     draft.data.rows = arr
            // }))

            this.setState(produce(draft => {
                draft.rowData = arr
            }))

            //console.log(this.state.data.rowData)
        })
    }
    
    render() {

        var tableData = this.state.rowData.map(function(record) {
            console.log("processing record")
            return <tr key={record._id}>
               <td>
                   <p className="m-0 text-muted font-14"><Link to={"/lender/" + record._id}>{record.name}</Link></p>
               </td>
               <td>
                   <p className="m-0 text-muted font-14">{record.cbid}</p>
               </td>
               <td>
                   <p className="m-0 text-muted font-14">{record.city}</p>
               </td>
               <td>
                   <p className="m-0 text-muted font-14">{record.state}</p>
               </td>
               <td>
                   <p className="m-0 text-muted font-14">{record.membership}</p>
               </td>
               <td>
                    <Link to={"/lender/" + record._id}>
                        <Button color="secondary" size="sm" className="waves-effect waves-light">View</Button>
                    </Link>
               </td>
           </tr>
       })

       const fullWidth = { width : '100%' }

        return (
            <React.Fragment>
                            <Card>
                                <CardHeader><CardTitle><h4 className="card-title">Credit Union Search</h4></CardTitle></CardHeader>
                                <CardBody style={{ 'height': '485px', 'overflowX': 'scroll'}}>

                                <center>
                                    <form className="app-search d-none d-lg-inline-block" style={fullWidth}>
                                        <div className="position-relative">
                                            <input type="text" className="form-control" placeholder="Search..." onClick={() => alert('Coming Soon - Site search not yet implemented')}/>
                                            <span className="fa fa-search"></span>
                                        </div>
                                    </form>
                                </center>
                                      
                                    {/* <MDBDataTable
                                            responsive
                                            bordered
                                            striped
                                            hover
                                            data={data}
                                        /> */}

{/* <div
				className="ag-theme-balham"
				// style={{
				// 	height: '500px',
				// 	width: '600px'
                // }}
                style={{height: '300px'}}
			>
				<AgGridReact
                    pagination={true}
					columnDefs={this.state.columnDefs}
					rowData={this.state.rowData}>
				</AgGridReact>
			</div> */}

            <div className="table-responsive">
                    <Table className="table-centered table-vertical table-nowrap">
                        <tbody>
                            {tableData}
                        </tbody>
                    </Table>
                </div>
                <hr />
                <center>
                    <nav aria-label="Page navigation example">
                                        <Pagination>
                                            <PaginationItem>
                                                <PaginationLink href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">2</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                    </center>
                                </CardBody>
                            </Card>
            </React.Fragment>
        );
    }
}

//export default SearchWidget;
export default withRouter(connect(null, {})(SearchWidget));