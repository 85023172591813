import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, FormGroup, Col, Row, Card, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import {AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const steps = [
    'Personal Information',
    'Home Address',
    'Business Information',
    'Business Address',
    'Supporting Documentation',
    'Other Information'
]

const stepContent = [
    'Please enter your personal information below',
    'Please enter your home address',
    'Please enter your business information below',
    'Please enter your business address below',
    'Please upload your supporting documentation below',
    'Please provide the following information'
]


class TestBlank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "Testing Stuff", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            activeStep: 0,
            columnDefs: [
				      {headerName: "Make", field: "make", sortable: true, filter: true },
				      {headerName: "Model", field: "model", sortable: true, filter: true },
                {headerName: "Price", field: "price", sortable: true, filter: true },
                {headerName: "Link", field: 'link', cellRenderer: (params) => {
                    var link = document.createElement('a');
                    link.href = '#';
                    // link.innerText = params.value;
                    link.innerText = 'View';
                    link.addEventListener('click', (e) => {
                        e.preventDefault();
                        console.log(params.data.id);
                        this.props.history.push(params.value)
                    });
                    return link;
                }}

			      ],
            rowData: [],
            gridAPI: null,
            gridColumnAPI: null,
            testing: {
              myAlert: this.myAlert
            }
        }
    } 

    myAlert() {
      alert('this was called')
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Testing Stuff", this.state.breadcrumbItems);
        const data = [
            {make: "Toyota", model: "Celica", price: 35000, link: '/account'},
            {make: "Ford", model: "Mondeo", price: 32000, link: '/account'},
            {make: "Porsche", model: "Boxter", price: 72000, link: '/account'}
        ]
        this.setState({rowData: data})
    }

    handleNext = () => {
        const current = this.state.activeStep
        this.setState({activeStep: current + 1})
        //this.state.activeStep = this.state.activeStep + 1
        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
    handleBack = () => {
        const current = this.state.activeStep
        this.setState({activeStep: current - 1})
        //this.state.activeStep = this.sstateactiveStep - 1
        //setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

    handleReset = () => {
        this.setState({activeStep: 0})
    };

    onFilterTextBoxChanged() {
        this.state.gridAPI.setQuickFilter('Ford');
    }

    onGridReady = (params) => {
        this.setState({ gridAPI: params.api })
        this.setState({ gridColumnAPI: params.columnApi })
    }

    renderModal1() {
      return (
        <Modal>
          <ModalHeader></ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      )
    }

    render() {
        return (
            <React.Fragment>

                <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody> 
                                  <Button onClick={this.state.testing.myAlert}>Hello</Button>
                                  <Button onClick={this.state.testing.myAlert}>Modal</Button>
                                {/* <input type="text" id="filter-text-box" placeholder="Filter..." onChange={() => this.onFilterTextBoxChanged()}/>
                <div
				className="ag-theme-balham"
                style={{height: '300px'}}
			>
				<AgGridReact
                    onGridReady={this.onGridReady}
					columnDefs={this.state.columnDefs}
					rowData={this.state.rowData}>
				</AgGridReact>
			</div> */}
            </CardBody></Card></Col></Row>
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(TestBlank)