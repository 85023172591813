import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { CardBody, CardTitle, FormGroup, Col, Row, Card, Input, Button, CardHeader, CardFooter } from 'reactstrap';

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import uuid from "uuid/v4";

import axios from 'axios'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'

import { config } from '../../config'
import { CardContent } from '@material-ui/core';
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

const itemsFromBackend = [
    { id: uuid(), title: "Relative is Member", description: "The applicant has an immediate relative who is a current member of the Credit Union." },
    { id: uuid(), title: "Affiliate Member", description: "The applicant is part of an affiliated company, community organization, or government agency." },
    { id: uuid(), title: "Join Non-Profit", description: "The applicant must be willing to join a pre-approved or secified non-profit organization." },
    { id: uuid(), title: "Employment", description: "The applicant must be a current or former employee, or a relative of a current or former employee, of the Credit Union" },
    { id: uuid(), title: "City Residency", description: "The applicant must live, worship, or work in a specified city or cities." },
    { id: uuid(), title: "State Residency", description: "The applicant must live, worship, or work in a specified state or states." },
    { id: uuid(), title: "Employee of SEG", description: "The applicant must be a current or former member of a specified Select Employee Group (SEG)." }
  ];

class TestDrag extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Test Drag", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            profile: {},
            avatar: "",
            columns: {
                [uuid()]: {
                  name: "Available Conditions",
                  items: itemsFromBackend
                },
                [uuid()]: {
                  name: "Membership Requirements",
                  items: [{ id: uuid(), title: "Over 18", description: "The applicant must be 18 years or older at the time of the application." }]
                }
              }
            //col1 : itemsFromBackend,
            //col2 : []
            // username: ""
        }
    } 

    onDragEnd(result, c) {
        if (!result.destination) return;
        const { source, destination } = result;
    
        if (source.droppableId !== destination.droppableId) {

            const sourceColumn = this.state.columns[source.droppableId];
            const destColumn = this.state.columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            var _data = [this.state.columns][0]
            _data[source.droppableId].items = sourceItems
            _data[destination.droppableId].items = destItems
            this.setState({_data})

        } else {

            const column = this.state.columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);

            var _data = [this.state.columns][0]
            _data[source.droppableId].items = copiedItems
            this.setState({_data})

        }
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Test Drag", this.state.breadcrumbItems);
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId
        const userId = JSON.parse(localStorage.getItem('cb-lender-user'))._id

        // axios.get(`${BASEAPI}/lenders/${lenderId}/employees/${userId}`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
        //     console.log(result)
        //     //this.setState({ profile : result.data.apiData.profile })
        //     this.setState({ avatar : result.data.apiData.avatar })
        // })
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
                                    <DragDropContext onDragEnd={result => this.onDragEnd(result, this.state.columns)}>
                                        {Object.entries(this.state.columns).map(([columnId, column], index) => {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} key={columnId}>
                                                    <h2>{column.name}</h2>
                                                    <div style={{ margin: 8 }}>
                                                        <Droppable droppableId={columnId} key={columnId}>
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                        background: snapshot.isDraggingOver
                                                                            ? "lightblue"
                                                                            : "lightgrey",
                                                                        padding: 10,
                                                                        width: 500,
                                                                        minHeight: 500
                                                                        }}
                                                                    >
                                                                        {column.items.map((item, index) => {
                                                                            return (
                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                    {(provided, snapshot) => {
                                                                                        return (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={{
                                                                                                    userSelect: "none",
                                                                                                    padding: 0,
                                                                                                    margin: "0 0 8px 0",
                                                                                                    minHeight: "50px",
                                                                                                    backgroundColor: snapshot.isDragging ? "#263B4A" : "#456C86",
                                                                                                    color: "black",
                                                                                                    ...provided.draggableProps.style
                                                                                                }}
                                                                                            >
                                                                                                <Card>
                                                                                                    <CardHeader><CardTitle>{item.title}</CardTitle></CardHeader>
                                                                                                    <CardBody>
                                                                                                        <CardContent>{item.description}</CardContent>
                                                                                                    </CardBody>
                                                                                                    <CardFooter>
                                                                                                        <Button onClick={() => alert('configure me!')}>Configure</Button>
                                                                                                    </CardFooter>
                                                                                                </Card>
                                                                                            </div>
                                                                                        );
                                                                                    }}
                                                                                </Draggable>
                                                                            );
                                                                        })}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </DragDropContext>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>      
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(TestDrag)



