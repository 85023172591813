import React, { Component } from 'react';
import { Card } from "reactstrap";

//Import Images
import user2 from "../../assets/images/users/user-2.jpg";

class WidgetUser extends Component {

    render() {
        return (
            <React.Fragment>
                            <Card className="widget-user">
                                <div className="widget-user-desc p-4 text-center bg-primary position-relative">
                                    <i className="fas fa-quote-left h2 text-white-50"></i>
                                    <p className="text-white mb-0">
                                        Did you know? In the U.S. there are more than 11.6 million firms owned by women, employing nearly 9 million people, and generating 
                                        nearly $2 trillion in annual sales.
                                    </p>
                                </div>
                                <div className="p-4">
                                    <div className="float-left mt-2 mr-3">
                                        <img src={user2} alt="" className="rounded-circle avatar-sm"/>
                                    </div>
                                    <h6 className="mb-1 font-size-16 mt-2">Marie Minnick</h6>
                                    <p className="text-muted mb-0">Marketing Manager</p>
                                </div>
                            </Card>
            </React.Fragment>
        );
    }
}

export default WidgetUser;