import React, { Component } from 'react';
import { connect } from "react-redux";
import { AvForm, AvField, AvTextarea, AvInput } from "availity-reactstrap-validation";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card, CardHeader, CardTitle, CardBody, Row , Col, FormGroup, Input, Label, Button} from 'reactstrap';

import axios from 'axios'

import { config } from '../../config'
const API_URL = config.url.API_URL
const BASEAPI = `${API_URL}/api/v1`


class LoanApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "Loan Application", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            lenderId: "",
            loanId: "",
            loanName: "",
            loanTerms: "",
            lenderName: "",
            loanAmount: '',
            loanReason: '',
            membership: false,
            acceptTerms: false,
            signature: '',
            signatureDate: ''
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Loan Application", this.state.breadcrumbItems);

        const lenderId = this.props.match.params.lenderId
        const loanId = this.props.match.params.loanId

        console.log("lender id " + lenderId)
        console.log("loan id " + loanId)

        axios.get(`${BASEAPI}/lenders/${lenderId}`).then(results => {
            this.setState({lenderName : results.data.apiData.name})
            const loans = results.data.apiData.loans
            console.log(loans)
            const loan = loans.find(doc => doc._id == loanId )
            console.log(loan)
            this.setState({
                loanName: loan.loanName,
                loanTerms: loan.loanTerms
            })
        })
        this.setState({ lenderId: lenderId, loanId: loanId })
    }

    handleFormSubmit = () => {
        const payload = {
            'cbid' : 'ABC123',
            'lenderName': this.state.lenderName,
            'lenderId': this.state.lenderId,
            'loanDesc': this.state.loanTerms,
            'amount': this.state.loanAmount,
            'applied': new Date(),
            'status': 'submitted'
        }

        axios.post(`${BASEAPI}/applications`,payload).then(result => {
            console.log(result)
            alert('Thank you for submitting your application')
            this.props.history.push('/my-applications')
        })
        
        
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardHeader>
                        <CardTitle>Loan Application</CardTitle>

                    </CardHeader>
                    <CardBody>
                        <AvForm onValidSubmit={this.handleFormSubmit}>
                        <Row xs="12">
                            <Col xs="12">
                                <p>Please complete the following so that we can submit your loan application to the selected lender.</p>
                            </Col>
                        </Row>
                        <Row xs="12">
                            <Col xs="12">
                                
                                <FormGroup row>
                                    <Label for="example-text-input" className="col-sm-2 col-form-label">Loan</Label>
                                    <Col sm="10">
                                        <Input className="form-control" type="text" defaultValue={this.state.lenderName} placeholder="Lender Name" id="lenderName" disabled />
                                        <Input className="form-control" type="text" defaultValue={this.state.loanName} placeholder="Loan Name" id="loanName" disabled />
                                        <Input className="form-control" type="text" defaultValue={this.state.loanTerms} placeholder="Loan Terms" id="loanTerms"  disabled />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="example-text-input" className="col-sm-2 col-form-label">Loan Amount</Label>
                                    <Col sm="10">
                                        {/* <Input className="form-control" type="text" defaultValue="" placeholder="Loan Amount" id="loanAmount" onChange={e => this.setState({loanAmount: e.target.value})} /> */}
                                        <AvField type="text" errorMessage="Enter amount..." placeholder="Loan Amount" name="loanAmount" onChange={e => this.setState({loanAmount: e.target.value})} validate={{ required: { value: true }}} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="example-text-input" className="col-sm-2 col-form-label">Membership</Label>
                                    <Col sm="10">
                                        <p>You must either (a) be a member, or (b) be willing to become a member to qualify for this loan offer</p>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="radio1" checked={this.state.membership === true} onChange={e => this.setState({membership: true})} />
                                                I am a member of the Credit Union
                                            </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="radio1" checked={this.state.membership === false} onChange={e => this.setState({membership: false})} />
                                                I am willing to become a member of the Credit Union
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="example-text-input" className="col-sm-2 col-form-label">Reason for Loan</Label>
                                    <Col sm="10">
                                        {/* <textarea errorMessage="Enter reason ..." className="form-control" rows="5" type="text" defaultValue="" placeholder="Loan Reason" id="loanReason" name="loanReason" onChange={e => this.setState({loanReason: e.target.value})} validate={{ required: { value: true }}} /> */}
                                        <AvInput errorMessage="Enter reason ..." className="form-control" rows="5" type="textarea" defaultValue="" placeholder="Loan Reason" id="loanReason" name="loanReason" onChange={e => this.setState({loanReason: e.target.value})} validate={{ required: { value: true }}} />
                                    </Col>
                                </FormGroup>
                                <p>Terms and Conditions</p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sit amet neque nec tellus vulputate tempor a nec dui. Donec vel tellus odio. 
                                    In at tincidunt eros. Curabitur imperdiet, est non consequat gravida, diam velit vestibulum ipsum, eu accumsan turpis risus at nunc. In congue, 
                                    urna tristique pretium consectetur, risus dui varius sapien, et condimentum ante mi non nulla. In mattis, ipsum nec feugiat scelerisque, nibh dolor 
                                    ornare tortor, ac consectetur mi lectus condimentum eros. Sed in dignissim tellus. Mauris fermentum pulvinar sem eu rhoncus. Aliquam cursus tincidunt 
                                    purus non varius. Pellentesque et nulla aliquet, consequat ante vitae, semper ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent 
                                    risus ligula, ultrices ac libero nec, vestibulum eleifend metus. Integer hendrerit elit convallis, interdum nibh eu, interdum mi.
                                </p>
                                <FormGroup row>
                                    <Label for="example-text-input" className="col-sm-2 col-form-label">Accept Terms</Label>
                                    <Col sm="10">
                                        <AvInput errorMessage="Must accept ..." name="acceptTerms" type="checkbox" validate={{ required: { value: true }}} />
                                    </Col>
                                </FormGroup>
                                <p>
                                    Electronic Signature
                                </p>
                                <FormGroup row>
                                    <Label for="example-text-input" className="col-sm-2 col-form-label">Full Legal Name</Label>
                                    <Col sm="10">
                                        <AvInput errorMessage="Must enter name ..." className="form-control" type="text" defaultValue="" placeholder="Full Legal Name" id="acceptTerms" name="acceptTerms" onChange={e => this.setState({acceptTerms: e.target.value})} validate={{ required: { value: true }}} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="example-text-input" className="col-sm-2 col-form-label">Date</Label>
                                    <Col sm="10">
                                            <AvInput className="form-control" type="date" name="date" defaultValue="2011-08-19" id="example-date-input"/>
                                        </Col>
                                </FormGroup>
                                
                            </Col>
                        </Row>
                        <Row>
                                    <Col xs="12">
                                        <div className="form-group mb-0">
                                            <div>
                                                <Button type="submit" color="primary" className="waves-effect waves-light mr-1" >
                                                    Submit
                                                </Button>
                                                {/* <Button type="reset" color="secondary" className="waves-effect">
                                                    Cancel
                                                </Button> */}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>

                    </CardBody>
                </Card>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(LoanApplication)