import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CardBody, CardTitle, FormGroup, Col, Row, Card, Input, Button, CardHeader, UncontrolledTooltip } from 'reactstrap';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import user1 from "../../assets/images/users/user-1.jpg";
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";
import user7 from "../../assets/images/users/user-7.jpg";
import user8 from "../../assets/images/users/user-8.jpg";
import user9 from "../../assets/images/users/user-9.jpg";
import user10 from "../../assets/images/users/user-10.jpg";

import '../../assets/css/slim.css'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class MessagesOrig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Messages", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            user : 
              { id : 1, imgUrl : user2, designation : "Founder", name : "Joyce Chua", desc : "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
              socials : [
                      { id : 1, title : "Facebook", icon : "fab fa-facebook-f", link : "#" },
                      { id : 2, title : "Twitter", icon : "fab fa-twitter", link : "#" },
                      { id : 3, title : "mobile", icon : "fa fa-phone", link : "#" },
                      { id : 4, title : "skype", icon : "fab fa-skype", link : "#" },
                  ]
              },
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Messages", this.state.breadcrumbItems);
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="9">
                        <Card>
                          <CardHeader>
                            <CardTitle><h4 className="card-title">Messages - Chat with Loan Applicants or CommunityBacker Support</h4></CardTitle></CardHeader>
                            <CardBody>


<div className="container container-messages with-sidebar">
          <div className="messages-left">

            <div className="messages-list">
              <a href="" className="media">
                <div className="media-left">
                  <img src={user3} alt="" />
                  <span className="square-10 bg-success"></span>
                </div>
                <div className="media-body">
                  <div>
                    <h6>Phil Itumay</h6>
                    <p>Technical Support<br /><a href="#">CommunityBacker</a></p>
                  </div>
                  <div>
                    <span>Aug 22</span>
                  </div>
                </div>
              </a>
              <a href="" className="media unread">
                <div className="media-left">
                  <img src={user2} alt="" />
                  <span className="square-10 bg-success"></span>
                </div>
                <div className="media-body">
                  <div>
                    <h6>Joyce Chua</h6>
                    <p>Founder<br /><a href="#">Widgets, Inc.</a></p>
                  </div>
                  <div>
                    <span>Aug 22</span>
                    <span>1</span>
                  </div>
                </div>
              </a>
              <a href="" className="media">
                <div className="media-left">
                  <img src={user4} alt="" />
                  <span className="square-10 bg-success"></span>
                </div>
                <div className="media-body">
                  <div>
                    <h6>Bob Dilao</h6>
                    <p>Customer Support<br /><a href="#">CommunityBacker</a></p>
                  </div>
                  <div>
                    <span>Aug 20</span>
                  </div>
                </div>
              </a>
              <a href="" className="media">
                <div className="media-left">
                  <img src={user5} alt="" />
                  <span className="square-10 bg-gray-600"></span>
                </div>
                <div className="media-body">
                  <div>
                    <h6>Trevor Aceron</h6>
                    <p>President<br /><a href="#">Websites 'r Us</a></p>
                  </div>
                  <div>
                    <span>Aug 18</span>
                  </div>
                </div>
              </a>
              <a href="" className="media">
                <div className="media-left">
                  <img src={user6} alt="" />
                  <span className="square-10 bg-gray-600"></span>
                </div>
                <div className="media-body">
                  <div>
                    <h6>Chuck Galeon</h6>
                    <p>Founder<br /><a href="@">Chilluminati Gelatos</a></p>
                  </div>
                  <div>
                    <span>Aug 16</span>
                  </div>
                </div>
              </a>
              <a href="" className="media">
                <div className="media-left">
                  <img src={user7} alt="" />
                  <span className="square-10 bg-success"></span>
                </div>
                <div className="media-body">
                  <div>
                    <h6>Bradley Bongcaras</h6>
                    <p>Chief Stylist<br /><a href="#">Bongcaras Wigs</a></p>
                  </div>
                  <div>
                    <span>Aug 15</span>
                  </div>
                </div>
              </a>
              <a href="" className="media">
                <div className="media-left">
                  <img src={user1} alt="" />
                  <span className="square-10 bg-success"></span>
                </div>
                <div className="media-body">
                  <div>
                    <h6>Marie Villalon</h6>
                    <p>Master Baker<br /><a href="#">Villalon Bakes</a></p>
                  </div>
                  <div>
                    <span>Aug 14</span>
                  </div>
                </div>
              </a>
              <a href="" className="media">
                <div className="media-left">
                  <img src={user8} alt="" />
                  <span className="square-10 bg-success"></span>
                </div>
                <div className="media-body">
                  <div>
                    <h6>Margaritte Rejas</h6>
                    <p>President<br /><a href="#">Rejas Personal Branding</a></p>
                  </div>
                  <div>
                    <span>Aug 11</span>
                  </div>
                </div>
              </a>
            </div>

            <div className="messages-left-footer">
              <button className="btn btn-slim btn-uppercase-sm btn-block">Load Older Messages</button>
            </div>
          </div>

          <div className="messages-right">
            <div className="message-header">
              <a href="" className="message-back"><i className="fa fa-angle-left"></i></a>
              <div className="media">
                <img src={user2} alt="" />
                <div className="media-body">
                  <h6>Joyce Chua (Founder - <a href="#">Widgets, Inc.</a>)</h6>
                  <p>Last seen: 2 hours ago</p>
                </div>
              </div>
              <div className="message-option">
                <div className="d-none d-sm-flex">
                  <a href=""><i className="icon ion-ios-telephone-outline"></i></a>
                  <a href=""><i className="icon ion-ios-videocam-outline"></i></a>
                  <a href=""><i className="icon ion-ios-gear-outline"></i></a>
                  <a href=""><i className="icon ion-ios-information-outline"></i></a>
                </div>
                <div className="d-sm-none">
                  <a href=""><i className="icon ion-more"></i></a>
                </div>
              </div>
            </div>
            <div className="message-body">
              <div className="media-list">
                <div className="media">
                  <img src={user10} alt="" />
                  <div className="media-body">
                    <div className="msg">
                      <p>Hi, there.</p>
                    </div>
                    <div className="msg">
                      <p>Thank you for your loan application. I have a couple of questions for you.</p>
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body reverse">
                    <div className="msg">
                      <p>Hi, what do you want to know?</p>
                    </div>
                  </div>
                  <img src={user2} className="wd-50 rounded-circle" alt="" />
                </div>
                <div className="media">
                  <img src={user10} alt="" />
                  <div className="media-body">
                    <div className="msg">
                      <p>How many employees do you have and do you just have the one retail location?.</p>
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body reverse">
                    <div className="msg">
                      <p>Right now we have 12 employees at the one location, but are looking to open a second location, hence the loan application.</p>
                    </div>
                    <div className="msg">
                      <p>We anticipate hiring another 6 employees for the new location.</p>
                    </div>
                  </div>
                  <img src={user2} className="wd-50 rounded-circle" alt="" />
                </div>
                <div className="media">
                  <img src={user10} alt="" />
                  <div className="media-body">
                    <div className="msg">
                      <p>Thanks for the clarification. I'll update your application with this information and hopefully we will able to have an answer on to you shortly.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="message-footer">
              <div className="row row-sm">
                <div className="col-9 col-sm-8 col-xl-9">
                  <input type="text" className="form-control" placeholder="Type something here..." onClick={() => alert('Coming Soon - Message support not yet implemented')} />
                </div>
                <div className="col-3 col-sm-4 col-xl-3 tx-right">
                  <div className="d-none d-sm-block">
                    <a href=""><i className="icon ion-happy-outline"></i></a>
                    <a href=""><i className="icon ion-ios-game-controller-b-outline"></i></a>
                    <a href=""><i className="icon ion-ios-camera-outline"></i></a>
                    <a href=""><i className="icon ion-ios-mic-outline"></i></a>
                  </div>
                  <div className="d-sm-none">
                    <a href=""><i className="icon ion-more"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



</CardBody></Card></Col>


<Col xs="3">
                            <Card className="directory-card">
                                <div>
                                    <div className="directory-bg text-center">
                                        <div className="directory-overlay">
                                            <img className="rounded-circle avatar-lg img-thumbnail" src={this.state.user.imgUrl} alt="Generic placeholder"/>
                                        </div>
                                    </div>

                                    <div className="directory-content text-center p-4">
                                        <p className=" mt-4">{this.state.user.designation}<br /><a href="#">Widgets, Inc.</a></p>
                                        <h5 className="font-size-16">{this.state.user.name}</h5>

                                        <p className="text-muted">{this.state.user.desc}</p>

                                        <Link to="/view-application">
                                          <Button>View Current Loan Application</Button>
                                        </Link>

                                        <ul className="social-links list-inline mb-0 mt-4">
                                            {
                                                this.state.user.socials.map((social, key) =>
                                                    <li key={key} className="list-inline-item">
                                                        <Link title="" className="btn-primary tooltips" id={social.title+this.state.user.id} to={social.link}><i className={social.icon}></i></Link>
                                                        <UncontrolledTooltip placement="top" target={social.title+this.state.user.id}>
                                                            {social.title}
                                                        </UncontrolledTooltip>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </Card>
                        </Col>

</Row>


                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(MessagesOrig)