import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Card,
    CardBody, 
    Col,
    FormGroup, 
    Input,
    Row,
    Table
} from 'reactstrap'
import { Link } from 'react-router-dom'

import axios from 'axios'

import { setBreadcrumbItems } from '../../store/actions'
import { getTest } from '../../services/testService'
import { getLoggedInUser } from '../../helpers/authUtils'
import myTeam from '../../pages/Demo/my-team';

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Notifications", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            username: "",
            notifications: []
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Notifications", this.state.breadcrumbItems);
        const result = getLoggedInUser()
        const token = result.token 
        const lenderId = result.lenderId
        const userId = result._id

        console.log("Logged In User");
        console.log(result.username)
        this.setState({username: result.username})

        axios.get(`${BASEAPI}/lenders/${lenderId}/employees/${userId}/notifications`, { headers: {"Authorization" : `Bearer ${token}` }}).then(result => {
            console.log(result)
            this.setState({notifications: result.data.apiData})
        })
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Notifications</h4>
                                <p className="card-title-desc">
                                    Coming Soon!
                                </p>
                                {/* <Button onClick={async () => {await getTest()}}>Hello</Button>
                                <p>Username: {this.state.username}</p> */}
                                <div className="table-responsive">
                                    <Table>
                                        <thead>
                                            <th>#</th>
                                            <th>Type</th>
                                            <th>Name</th>
                                            <th>Status</th>
                                        </thead>
                                        <tbody>
                                            {this.state.notifications.map(row => {
                                                return (
                                                    <tr>
                                                        <td></td>
                                                        <td>{row.type}</td>
                                                        <td>{row.name}</td>
                                                        <td>{row.status}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>   
                                </div>
                                <br /><br />
                                <Link to="/notification-detail">Notification Detail</Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>                
            </React.Fragment>
        )
    }
}

export default connect(null, {setBreadcrumbItems})(Notifications)