import React from "react";
import { Redirect } from "react-router-dom";

import Pageslogin from '../backablPages/Authentication/Login';
import MFACode from '../backablPages/Authentication/MFACode';
import Logout from '../backablPages/Authentication/Logout';
import Pagesregister from '../backablPages/Authentication/Register';
import ForgetPassword from '../backablPages/Authentication/ForgetPassword';
import LockScreen from "../backablPages/Authentication/pages-lock-screen";

//Dashboard
import Dashboard from '../pages/Dashboard/dashboard';

//Calender
import Calendar from "../pages/Calendar/calendar";

//Forms
import FormElements from "../pages/Forms/form-elements";
import FormValidations from "../pages/Forms/form-validation";
import FormAdvanced from "../pages/Forms/form-advanced";
import FormEditors from "../pages/Forms/form-editors";
import FormUpload from "../pages/Forms/form-uploads";
import FormXeditable from "../pages/Forms/form-xeditable";

//Charts
import ChartsAppex from "../pages/Charts/charts-appex";
import ChartsChartist from "../pages/Charts/charts-chartist";
import ChartsJs from "../pages/Charts/charts-chartjs";
import ChartsKnob from "../pages/Charts/charts-other";
import ChartsC3 from "../pages/Charts/charts-c3";
import ChartsSparkLine from "../pages/Charts/charts-sparkline";

//Tables
import BasicTables from "../pages/Tables/tables-basic";
import DatatableTables from "../pages/Tables/tables-datatable";
import ResponsiveTables from "../pages/Tables/tables-responsive";
import EditableTables from "../pages/Tables/tables-editable";

//Icons
import IconDripicons from "../pages/Icons/icons-dripicons";
import IconIon from "../pages/Icons/icons-ion";
import IconMaterial from "../pages/Icons/icons-material";
import IconFontawesome from "../pages/Icons/icons-fontawesome";
import IconThemify from "../pages/Icons/icons-themify";
import IconTypicons from "../pages/Icons/icons-typicons";

// Maps
import MapsGoogle from "../pages/Maps/maps-google";
import MapsVector from "../pages/Maps/maps-vector";

//Extra Pages
import PagesTimeline from "../pages/Extra Pages/pages-timeline";
import PagesInvoice from "../pages/Extra Pages/pages-invoice";
import PagesDirectory from "../pages/Extra Pages/pages-directory";
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";



// Lender Portal Pages
import Account              from '../cbpages/Account/account-page';
import Activity             from '../cbpages/Activity/activity-page';
import ApplicantDetail      from '../cbpages/Loan/applicant-detail-page'   
import Application          from '../cbpages/Application/application-page'
import ApplicationHistory   from '../cbpages/Application/application-history-page'
import ApplicationList      from '../cbpages/Application/application-list-page'
import ApplicationNotes     from '../cbpages/Application/application-notes-page'
import ApplicationPlaid     from '../cbpages/Application/application-plaid-page'
import CompanyProfile       from '../cbpages/CompanyProfile/company-profile-page';
import Help                 from '../cbpages/Help/help-page';
import Home                 from '../cbpages/Home/home-page';
import Loan                 from '../cbpages/Loan/loan-page'
import LoanDetail           from '../cbpages/Loan/loan-detail-page'  
import Membership           from '../cbpages/Membership/membership-page'  
import Messages             from '../cbpages/Messages/messages-page';
import MessagesOrig         from '../cbpages/Messages/messages-orig-page';
import Notifications        from '../cbpages/Notifications/notification-page';
import NotificationDetail   from '../cbpages/Notifications/notification-detail-page';
import Pipeline             from '../cbpages/Pipeline/pipeline-page';
import Profile              from '../cbpages/Profile/profile-page';
import RecommendationExplanation from '../cbpages/Application/recommendation-explanation-page.js'
import Settings             from '../cbpages/Settings/settings-page';
import Team                 from '../cbpages/Team/team-page'
import TeamMemberDetail     from '../cbpages/Team/team-member-detail-page'
import Welcome              from '../cbpages/Welcome/welcome-page';



import Search from '../pages/Search/search';
import MyData from '../pages/MyData/my-data';
import Grants from '../pages/Grants/grants';
import MyApplications from '../pages/MyApplications/my-applications';
import MyApplication from '../pages/MyApplications/my-application';




import Billing from '../pages/Billing/billing';

import Lender from '../pages/Lender/lender';
import LoanApplication from '../pages/LoanApplication/loan-application';

import ActivityLogs from '../pages/Demo/activity-logs'
import AllLoans from '../pages/Demo/all-loans'
import ArchivedApplications from '../pages/Demo/archived-applications'
import ClosedApplications from '../pages/Demo/closed-applications'
import EditActivity from '../pages/Demo/edit-activity'
import LoanAdmin from '../pages/Demo/loan-admin'
import MyTasks from '../pages/Demo/my-tasks'
import MyTeam from '../pages/Demo/my-team'
import NewApplications from '../pages/Demo/new-applications'
import OpenApplications from '../pages/Demo/open-applications'
import Applications from '../pages/Demo/applications'
import ViewApplication from '../pages/Demo/view-application'
import TopLoans from '../pages/Demo/top-loans'

import TestGrid from '../cbpages/Test/test-grid';
import TestDrag from '../cbpages/Test/test-drag';
import TestBlank from '../cbpages/Test/test-blank';
import TestChat from '../cbpages/Test/test-chat';
import TestPlaid from '../cbpages/Test/test-plaid';

import PublicHome from '../pages/Public/public-home';

/*
    The following are the protexcted routes
*/
const authProtectedRoutes = [

    // Routes for the LENDER PORTAL APPLICATION - START

    { path: "/account",                     component: Account },
    { path: "/activity",                    component: Activity },
    { path: "/application/:id",             component: Application },
    { path: "/application-history",         component: ApplicationHistory },
    { path: "/application-list",            component: ApplicationList },
    { path: "/application-notes/:id",       component: ApplicationNotes },
    { path: "/application-plaid/:applicationId",           component: ApplicationPlaid },
    { path: "/company-profile",             component: CompanyProfile },
    { path: "/help",                        component: Help },
    { path: "/home",                        component: Home },
    { path: "/loan",                        component: Loan },
    { path: "/loan-detail",                 component: LoanDetail },
    { path: "/applicant-detail",            component: ApplicantDetail },
    { path: "/membership",                  component: Membership },
    { path: "/messages-orig",               component: MessagesOrig },
    { path: "/messages",                    component: Messages },
    { path: "/notifications",               component: Notifications },
    { path: "/notification-detail",         component: NotificationDetail },
    { path: "/pipeline",                    component: Pipeline },
    { path: "/profile",                     component: Profile },
    { path: "/recommendation-explanation",  component: RecommendationExplanation },
    { path: "/settings",                    component: Settings },
    { path: "/team",                        component: Team },
    { path: "/team-member-detail",          component: TeamMemberDetail },
    { path: "/welcome",                     component: Welcome },

    // Routes for the LENDER PORTAL APPLICATION - END

  // DashBoard
  { path: "/dashboard", component: Dashboard },

  //Calendar
  { path: "/calendar", component: Calendar },

  //Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-xeditable", component: FormXeditable },

  //Charts
  { path: "/charts-appex", component: ChartsAppex },
  { path: "/charts-chartist", component: ChartsChartist },
  { path: "/charts-chartjs", component: ChartsJs },
  { path: "/charts-other", component: ChartsKnob },
  { path: "/charts-c3", component: ChartsC3 },
  { path: "/charts-sparkline", component: ChartsSparkLine },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Icons
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-ion", component: IconIon },
  { path: "/icons-material", component: IconMaterial },
  { path: "/icons-fontawesome", component: IconFontawesome },
  { path: "/icons-themify", component: IconThemify },
  { path: "/icons-typicons", component: IconTypicons },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },

  //Extra Pages
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-invoice", component: PagesInvoice },
  { path: "/pages-directory", component: PagesDirectory },
  { path: "/pages-blank", component: PagesBlank },

  
  
  { path: "/search", component: Search },
  { path: "/my-data", component: MyData },
  { path: "/grants", component: Grants },
  { path: "/loan-application/:lenderId/loans/:loanId", component: LoanApplication },
  { path: "/my-applications", component: MyApplications },
  { path: "/my-application/:id", component: MyApplication },
  
  
  //{ path: "/profile", component: MyProfile },
  
  { path: "/billing", component: Billing },
  

  { path: "/test-grid", component: TestGrid },
  { path: "/test-blank", component: TestBlank },
  { path: "/test-chat", component: TestChat },
  { path: "/test-drag", component: TestDrag },
  { path: "/test-plaid", component: TestPlaid },
  { path: "/lender/:id", component: Lender },

  { path: "/activity-logs", component: ActivityLogs },
  { path: "/all-loans", component: AllLoans },
  { path: "/archived-applications", component: ArchivedApplications },
  { path: "/closed-applications", component: ClosedApplications },
  { path: "/edit-activity", component: EditActivity },
  { path: "/loan-admin", component: LoanAdmin },
  { path: "/my-tasks", component: MyTasks },
  { path: "/my-team", component: MyTeam },
  { path: "/new-applications", component: NewApplications },
  { path: "/open-applications", component: OpenApplications },
  { path: "/view-application", component: ViewApplication },
  { path: "/top-loans", component: TopLoans },

  { path: "/", exact: true, component: () => <Redirect to="/home" /> }
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Pageslogin },
  { path: "/mfa-code", component: MFACode },
  { path: "/register", component: Pagesregister },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/pages-lock-screen', component: LockScreen },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  { path: "/public/home", component: PublicHome },
];

export { authProtectedRoutes, publicRoutes };
