const prod = {
    url: {
        API_URL : 'https://api.backabl.com',
        CHAT_URL : 'http://cb-chat.us-east-1.elasticbeanstalk.com/'
    },
    company: {
        name: 'backabl'
    }
}

const dev = {
    url: {
        API_URL : 'http://localhost:9000',
        CHAT_URL : 'http://localhost:500'
    },
    company: {
        name: 'backabl'
    }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod
