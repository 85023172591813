import React, { Component } from 'react';

import C3Chart from 'react-c3js';
import 'c3/c3.css';

class PieChart extends Component {

    render() {
        const data = {
            columns: [
                [">$25k",40],[">$50k",20],[">$100k",80],["+$100k",10]
            ],
            type:"pie",
          };
          const color = {pattern:["#41B883","#E46651","#00D8FF","#DD1B16"]}
          const pie = {
              label:{show:!1}
            }

        return (
            <React.Fragment>
               <C3Chart data={data} pie={pie} color={color}/>
            </React.Fragment>
        );
    }
}

export default PieChart;
