import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { CardBody, FormGroup, Col, Row, Card, Input, Button, UncontrolledTooltip } from 'reactstrap';

import axios from 'axios'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'

import '../../assets/css/slim.css'

import plaidlogo from '../../assets/images/plaid-logo-icon.svg'
import user2 from "../../assets/images/users/user-2.jpg";

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class Application extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Application", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            user : 
                { id : 1, imgUrl : user2, designation : "Founder", name : "Joyce Chua", desc : "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
                socials : [
                    { id : 1, title : "Facebook", icon : "fab fa-facebook-f", link : "#" },
                    { id : 2, title : "Twitter", icon : "fab fa-twitter", link : "#" },
                    { id : 3, title : "mobile", icon : "fa fa-phone", link : "#" },
                    { id : 4, title : "skype", icon : "fab fa-skype", link : "#" },
                ]
            },
            applicationId: '',
            loanName: '',
            loanAmount: '',
            loanDateApplied: '',
            loanPurposeOfLoan: '',
            personalFirstName: '',
            personalLastName: '',
            personalEmailAddress: '',
            personalAddress: '',
            personalGender: '',
            personalDateOfBirth: '',
            personalSSN: '',
            businessName: '',
            businessWebsite: '',
            businessAddress: '',
            businessDomain: '',
            businessDateOfIncorporation: '',
            businessEID: '',
            history: [],

            noteContent: "",
            applicationStatus: "",
            applicationReason: "",

            status: ""

            // username: ""
        }
    } 

    daysAgo(gd) {
        const today = new Date()
        const givenDate = new Date(gd)
        const diff = today.getTime() - givenDate.getTime()
        const days = Math.floor(diff / (1000 * 3600 * 24))
        
        const monthName = givenDate.toLocaleString('default', {month: 'long'})
        const givenDateFormatted = monthName + " " + givenDate.getDate() + ", " + givenDate.getFullYear()

        return givenDateFormatted + " (" + days + " days ago)"
    }

    formatDateWithPast(gd) {
        if (gd === "") return ""
        const today = new Date()
        const givenDate = new Date(gd)
        const diffYears = today.getFullYear() - givenDate.getFullYear()
        const diffMonths = today.getMonth() - givenDate.getMonth()
        const monthName = givenDate.toLocaleString('default', {month: 'long'})

        const givenDateFormatted = monthName + " " + givenDate.getDate() + ", " + givenDate.getFullYear()
        let pastString = ""
        if (diffYears != 0 && diffMonths != 0) {
            pastString = "(" + diffYears + " years, " + diffMonths + " months)"  
        } else if (diffYears != 0 && diffMonths == 0) {
            pastString = "(" + diffYears + " years)"
        } else if (diffYears == 0 && diffMonths != 0) {
            pastString = "(" + diffMonths + " months)"
        }

        return givenDateFormatted + " " + pastString
    }

    formatAge(dob) {
        const today = new Date()
        var birthDate = new Date(dob);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        {
            age--;
        }
        const monthName = birthDate.toLocaleString('default', {month: 'long'})
        const dateFormatted = monthName + " " + birthDate.getDate() + ", " + birthDate.getFullYear()
        return dateFormatted + " (" + age + " years old)"
    }

    formatAddress(address) {
      return address.line1 + ", " + address.city + ", " + address.state + " " + address.zipcode
    }

    submitApplicationStatus() {
      const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
      const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId
      const empId = JSON.parse(localStorage.getItem('cb-lender-user'))._id
      const firstName = JSON.parse(localStorage.getItem('cb-lender-user')).firstName
      const lastName = JSON.parse(localStorage.getItem('cb-lender-user')).lastName
      const empName = firstName + " " + lastName

      const payload = {
        applicationStatus : this.state.applicationStatus,
        applicationReason : this.state.applicationReason,
        employeeId : empId,
        employeeName : empName
      }

      axios.patch(`${BASEAPI}/lenders/${lenderId}/applications/${this.state.applicationId}`, payload, { headers: {"Authorization" : `Bearer ${token}`} }).then( result => {
          console.log(result)
          this.props.history.push('/application-list')
      })
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Profile", this.state.breadcrumbItems);
        // const result = getLoggedInUser() 
        // console.log("Logged In User");
        // console.log(result.username)
        // this.setState({username: result.username})

        const applicationId = this.props.match.params.id



        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders/${lenderId}/applications/${applicationId}`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            this.setState({applicationId : applicationId})
            this.setState({loanName : result.data.apiData.loanName})
            this.setState({loanAmount : result.data.apiData.loanAmount})
            this.setState({loanDateApplied : result.data.apiData.loanDateApplied})
            this.setState({loanPurposeOfLoan : result.data.apiData.loanPurposeOfLoan})
            this.setState({personalFirstName : result.data.apiData.personalFirstName})
            this.setState({personalLastName : result.data.apiData.personalLastName})
            this.setState({personalEmailAddress : result.data.apiData.personalEmailAddress})
            this.setState({personalAddress : result.data.apiData.personalAddress})
            this.setState({personalGender : result.data.apiData.personalGender})
            this.setState({personalDateOfBirth : result.data.apiData.personalDateOfBirth})
            this.setState({personalSSN : result.data.apiData.personalSSN})
            this.setState({businessName : result.data.apiData.businessName})
            this.setState({businessWebsite : result.data.apiData.businessWebsite})
            this.setState({businessAddress : result.data.apiData.businessAddress})
            this.setState({businessDomain : result.data.apiData.businessDomain})
            this.setState({businessDateOfIncorporation : result.data.apiData.businessDateOfIncorporation})
            this.setState({businessEID : result.data.apiData.businessEID})
            this.setState({status : result.data.apiData.status})
        })
    }

    sendNote() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId
        const authorId = JSON.parse(localStorage.getItem('cb-lender-user'))._id
        const firstName = JSON.parse(localStorage.getItem('cb-lender-user')).firstName
        const lastName = JSON.parse(localStorage.getItem('cb-lender-user')).lastName
        const authorName = firstName + " " + lastName

        const payload = {
            authorId: authorId,
            authorName: authorName,
            noteContent: this.state.noteContent 
        }

        axios.post(`${BASEAPI}/lenders/${lenderId}/applications/${this.state.applicationId}/notes`, payload, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            alert("Your note was submitted")
            this.setState({noteContent:""})
        })
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="9">

                    <div class="section-wrapper">
          <label class="section-title">Loan Information</label>
          {/* <p class="mg-b-20 mg-sm-b-40">A bordered form group wrapper with a label on top of each form control.</p> */}

          <div class="form-layout form-layout-2">
            <div class="row no-gutters">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label">Loan: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="firstname" value={this.state.loanName} placeholder="Enter Loan" disabled />
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label">Amount: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="lastname" value={this.state.loanAmount} placeholder="Enter Loan Amount" disabled />
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label">Date Applied: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="email" value={this.daysAgo(this.state.loanDateApplied)} placeholder="Enter Date Applied" disabled />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group bd-t-0-force">
                  <label class="form-control-label">Purpose of Loan: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="address" value={this.state.loanPurposeOfLoan} placeholder="Enter Purpose of Loan" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>



        




        <div class="section-wrapper mg-t-20">
          <label class="section-title">Personal Information</label>
          {/* <p class="mg-b-20 mg-sm-b-40">A bordered form group wrapper with a label on top of each form control.</p> */}

          <div class="form-layout form-layout-2">
            <div class="row no-gutters">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label">Firstname: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="firstname" value={this.state.personalFirstName} placeholder="Enter firstname" disabled />
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label">Lastname: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="lastname" value={this.state.personalLastName} placeholder="Enter lastname" disabled />
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label">Email address: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="email" value={this.state.personalEmailAddress} placeholder="Enter email address" disabled />
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group bd-t-0-force">
                  <label class="form-control-label">Address: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="address" value={this.formatAddress(this.state.personalAddress)} placeholder="Enter address" disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mg-md-l--1 bd-t-0-force">
                  <label class="form-control-label mg-b-0-force">Country: <span class="tx-danger">*</span></label>
                  <select id="select2-a" class="form-control" data-placeholder="Choose country" disabled>
                    <option label="Choose country"></option>
                    <option value="USA" selected>United States of America</option>
                    <option value="UK">United Kingdom</option>
                    <option value="China">China</option>
                    <option value="Japan">Japan</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label">Gender: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="firstname" value={this.state.personalGender} placeholder="Enter Gender" disabled />
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group">
                  <label class="form-control-label">Date of Birth: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="firstname" value={this.formatAge(this.state.personalDateOfBirth)} placeholder="Enter Date of Birth" disabled />
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group">
                  <label class="form-control-label">Social Security Number (SSN): <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="firstname" value={this.state.personalSSN} placeholder="Enter Social Security Number" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-wrapper mg-t-20">
          <label class="section-title">Business Information</label>
          {/* <p class="mg-b-20 mg-sm-b-40">A bordered form group wrapper with a label on top of each form control.</p> */}

          <div class="form-layout form-layout-2">
            <div class="row no-gutters">
              <div class="col-md-8">
                <div class="form-group">
                  <label class="form-control-label">Business Name: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="firstname" value={this.state.businessName} placeholder="Enter Business Name" disabled />
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label">Website: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="email" value={this.state.businessWebsite} placeholder="Enter web address" disabled />
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group bd-t-0-force">
                  <label class="form-control-label">Address: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="address" value={this.formatAddress(this.state.businessAddress)} placeholder="Enter address" disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mg-md-l--1 bd-t-0-force">
                  <label class="form-control-label mg-b-0-force">Country: <span class="tx-danger">*</span></label>
                  <select id="select2-a" class="form-control" data-placeholder="Choose country" disabled>
                    <option label="Choose country"></option>
                    <option value="USA" selected>United States of America</option>
                    <option value="UK">United Kingdom</option>
                    <option value="China">China</option>
                    <option value="Japan">Japan</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label">Business Domain: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="firstname" value={this.state.businessDomain} placeholder="Enter Business Domain" disabled />
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group">
                  <label class="form-control-label">Date of Incorporation: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="firstname" value={this.formatDateWithPast(this.state.businessDateOfIncorporation)} placeholder="Enter Date of Incorporation" disabled />
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group">
                  <label class="form-control-label">Employer Identification Number (EIN): <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="firstname" value={this.state.businessEID} placeholder="Enter Employer Identification Number" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>





        <div class="section-wrapper mg-t-20">
          <label class="section-title">Supporting Documentation</label>
          {/* <p class="mg-b-20 mg-sm-b-40">A bordered form group wrapper with a label on top of each form control.</p> */}

          <div class="form-layout form-layout-2">
            <div class="row no-gutters">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label">Government ID: <span class="tx-danger">*</span></label>
                  {/* <input class="form-control" type="text" name="firstname" value={this.state.personalFirstName} placeholder="Enter firstname" disabled /> */}
                  <a href="#">Link</a>
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label">Personal Taxes: <span class="tx-danger">*</span></label>
                  {/* <input class="form-control" type="text" name="lastname" value={this.state.personalLastName} placeholder="Enter lastname" disabled /> */}
                  <a href="#">Link</a>
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group mg-md-l--1">
                  <label class="form-control-label">Business Taxes: <span class="tx-danger">*</span></label>
                  {/* <input class="form-control" type="text" name="email" value={this.state.personalEmailAddress} placeholder="Enter email address" disabled /> */}
                  <a href="#">Link</a>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label">SBA Form 1: <span class="tx-danger">*</span></label>
                  {/* <input class="form-control" type="text" name="firstname" value={this.state.personalGender} placeholder="Enter Gender" disabled /> */}
                  <a href="#">Link</a>
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group">
                  <label class="form-control-label">SBA Form 2: <span class="tx-danger">*</span></label>
                  {/* <input class="form-control" type="text" name="firstname" value={this.state.personalDateOfBirth} placeholder="Enter Date of Birth" disabled /> */}
                  <a href="#">Link</a>
                </div>
              </div>
              <div class="col-md-4 mg-t--1 mg-md-t-0">
                <div class="form-group">
                  <label class="form-control-label">SBA Form 3: <span class="tx-danger">*</span></label>
                  {/* <input class="form-control" type="text" name="firstname" value={this.state.personalSSN} placeholder="Enter Social Security Number" disabled /> */}
                  <a href="#">Link</a>
                </div>
              </div>
            </div>
            <br />
            <a href="#">Download Zip</a>
          </div>
        </div>

        <div class="section-wrapper mg-t-20">
          <label class="section-title">Approve or Deny Loan Application</label>
          {/* <p class="mg-b-20 mg-sm-b-40">A bordered form group wrapper with a label on top of each form control.</p> */}

          <div class="form-layout form-layout-2">
            <div class="row no-gutters">
              <div class="col-md-8">
                <div class="form-group">
                  <label class="form-control-label">Reason: <span class="tx-danger">*</span></label>
                  <input class="form-control" type="text" name="reason" placeholder="Enter Reason for Accepting or Rejecting Loan Application" onChange={(e) => this.setState({applicationReason : e.target.value})} />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mg-md-l--1 bd-t-0-force">
                  <label class="form-control-label mg-b-0-force">Result: <span class="tx-danger">*</span></label>
                  <select id="select2-a" class="form-control" data-placeholder="Choose Loan" onChange={(e) => this.setState({applicationStatus : e.target.value})}>
                    <option label="Choose Result"></option>
                    <option value="APPROVE">Approve Loan</option>
                    <option value="REJECT">Deny Loan</option>
                  </select>
                </div>
              </div>
              </div>
              <div class="form-layout-footer">
              <button class="btn btn-primary bd-0" onClick={() => this.submitApplicationStatus()} disabled={this.state.status=="CLOSED"}>Submit</button>
              <button class="btn btn-secondary bd-0">Cancel</button>
            </div>
          </div>
        </div>
        <br /><br />

            </Col>
            <Col xs="3">


            <Card className="directory-card">
                            <div>
                                <div className="directory-bg text-center">
                                    <div className="directory-overlay">
                                        <img className="rounded-circle avatar-lg img-thumbnail" src={user2} alt="Generic placeholder"/>
                                    </div>
                                </div>
                                <div className="directory-content text-center p-4">
                                    <p className=" mt-4">Title<br /><a href="#">Employer</a></p>
                                    <h5 className="font-size-16">My Name</h5>
                                    <p className="text-muted">Some Description</p>
                                    <Link to="/view-application">
                                        <Button>Chat with me!</Button>
                                    </Link>
                                    <ul className="social-links list-inline mb-0 mt-4">
                                        {
                                            this.state.user.socials.map((social, key) =>
                                                <li key={key} className="list-inline-item">
                                                    <Link title="" className="btn-primary tooltips" id={social.title+this.state.user.id} to={social.link}><i className={social.icon}></i></Link>
                                                    <UncontrolledTooltip placement="top" target={social.title+this.state.user.id}>
                                                        {social.title}
                                                    </UncontrolledTooltip>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Card>

            
                            <Card className="text-white bg-success">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Personal Credit Score - Coming Soon</p>
                                        <footer className="blockquote-footer text-white font-12">
                                            Courtesy of <cite title="Source Title">TransUnion</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </Card>

                            <Card className="text-white bg-warning">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Business Credit Score - Coming Soon</p>
                                        <footer className="blockquote-footer text-white font-12">
                                            Courtesy of <cite title="Source Title">Dun & Bradstreet</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </Card>
                       



            {/* <div class="row row-sm">
          <div class="col-lg-4"> */}

            {/* <div class="card card-sales">
              <h6 class="slim-card-title ">Credit Reporting</h6>
              <div class="row">
                <div class="col">
                  <label class="tx-12">Backabl</label>
                  <p>720</p>
                </div>
                <div class="col">
                  <label class="tx-12">Personal</label>
                  <p>710</p>
                </div>
                <div class="col">
                  <label class="tx-12">Business</label>
                  <p>800</p>
                </div>
              </div>

              <h6 class="slim-card-title ">Approval Recommendation</h6>
              <div class="progress mg-b-5">
                <div class="progress-bar bg-primary wd-75p" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">75%</div>
              </div>
              <p class="tx-12 mg-b-0">Overall good rating.</p>
              <br />
              <a href="/recommendation-explanation">See explanation</a>
          </div> */}


          <div class="card tx-center">
            <div class="card-body pd-40">
              <div class="d-flex justify-content-center mg-b-30">
                <img src={plaidlogo} class="wd-100" alt="" />
              </div>
              <h6 class="tx-md-20 tx-inverse mg-b-20">Financial Transaction Access</h6>
              <p>We use Plaid to provide secure and anonymized access to borrower's bank accounts. Click below to review this data.</p>
              <a href={`/application-plaid/${this.state.applicationId}`} class="btn btn-primary btn-block">Access Here</a>
            </div>
          </div>



          {/* <div class="card pd-20 mg-t-20">
              <label class="slim-card-title">Application History</label>
              <div class="post-group">
                <div class="post-item">
                  <span class="post-date">Today, 3:30pm</span>
                  <p class="post-title"><a href="">Application Review</a></p>
                  <p class="tx-12 mg-b-0"><a href="">Some Name</a> Some FCU</p>
                </div>
                <div class="post-item">
                  <span class="post-date">Yesterday, 10:00am</span>
                  <p class="post-title"><a href="">Application Review - Note Added</a></p>
                  <p class="tx-12 mg-b-10"><a href="">Some Name</a> Some FCU</p>
                  <p class="tx-12 mg-b-0">Need to reach out to the applicant for additional documentation...</p>
                </div>
                <div class="post-item">
                  <span class="post-date">Dec 22, 2017 10:00am</span>
                  <p class="post-title"><a href="">Application Submitted</a></p>
                  <p class="tx-12 mg-b-10"><a href="">Some Borrower Name</a> Some Company Name</p>
                  <p class="tx-12 mg-b-15">Application for small business loan to...</p>
                </div>
              </div>
              <br />
              <a href="/application-history">See full history</a>
            </div> */}

            <div class="card card-quick-post">
            <h6 class="slim-card-title">Add a Note</h6>
            <div class="form-group">
              <textarea class="form-control" rows="3" value={ this.state.noteContent } onChange={e => this.setState({noteContent: e.target.value})} placeholder="Enter a quick note"></textarea>
            </div>
            <div class="card-footer">
              <button class="btn btn-primary bd-0" onClick={() => this.sendNote()}>Save</button>
              <nav>
                {/* <a href=""><i class="icon ion-images"></i></a>
                <a href=""><i class="icon ion-aperture"></i></a> */}
              </nav>
              <a href={`/application-notes/${this.state.applicationId}`}>See all notes</a>
            </div>
          </div>
          {/* </div> */}








            </Col>
        </Row>

           </React.Fragment>
        )
    }
}

export default connect(null, { setBreadcrumbItems })(Application)