import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Progress } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import axios from 'axios'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

//Import Components
import MiniCard from "../../pages/Dashboard/mini-card";
import MonthlyEarnings from "../../pages/Dashboard/montly-earnings";
import EmailSent from "../../pages/Dashboard/email-sent";
import MonthlyEarnings2 from "../../pages/Dashboard/montly-earnings2";
import Inbox from "../../pages/Dashboard/inbox";
import RecentActivity from "../../pages/Dashboard/recent-activity";
import WidgetUser from "../../pages/Dashboard/widget-user";
import YearlySales from "../../pages/Dashboard/yearly-sales";
import LatestTransactions from "../../pages/Dashboard/latest-transactions";
import LatestOrders from "../../pages/Dashboard/latest-orders";

import SearchWidget from './search-widget'

import PieChart from "../../pages/AllCharts/C3charts/piechart";

import '../../assets/css/slim.css'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

// import { data } from '../Search/data'

class Home extends Component {

  comingSoon() {
    alert('Feature is currently under development.\nPlease check back soon!')
  }

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Home", link : "#" },
                // { title : "Dashboard", link : "#" },
            ],
            reports : [
                { title : "Orders", icon : "mdi-cube-outline", result : "+11%", value : "1,587", desc : "From previous period", color : "info" },
                { title : "Revenue", icon : "mdi-buffer", result : "-29%", value : "$46,782", desc : "From previous period", color : "danger" },
                { title : "Average Price", icon : "mdi-tag-text-outline", result : "0%", value : "$15.9", desc : "From previous period", color : "warning" },
                { title : "Product Sold", icon : "mdi-briefcase-check", result : "+89%", value : "1890", desc : "From previous period", color : "info" },
            ],
            firstName: "",
            openApplications: 0,
            newApplications: 0
        }
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Home", this.state.breadcrumbItems);

        // const username = JSON.parse(localStorage.getItem('cb-lender-user')).username
        // const tempName = username.substr(0,username.indexOf('@'))
        // const displayName = tempName.charAt(0).toUpperCase() + tempName.slice(1)
        // this.setState({name: displayName})
        const firstName = JSON.parse(localStorage.getItem('cb-lender-user')).firstName
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        this.setState({firstName: firstName})

        axios.get(`${BASEAPI}/lenders-utils/application-count/${lenderId}`).then(result => {
          result.data.apiData.map(response => {
            if (response._id === "OPEN") this.setState({openApplications : response.count})
            else if (response._id === "NEW") this.setState({newApplications : response.count})
          })
        })
    }

    formattedDate() {
      const today = new Date()
      const day = today.toLocaleString('default', { weekday: 'long' })
      const month = today.toLocaleString('default', { month: 'long' })
      const date  = today.getDate()
      const year  = today.getFullYear()

      return `${day} - ${month} ${date}, ${year}`
    }

    render() {
        return (
            <>
                <div className="dash-headline-two">
                    <div>
                    <h4 className="tx-inverse mg-b-5">Hello, {this.state.firstName}!</h4>
                      <p className="mg-b-0">Today is {this.formattedDate()}</p>
                    </div>
                    <div className="d-h-t-right">
                      
                        <div className="summary-item">
                          {/* <Link to=""> */}
                            <h1><Link to="/application-list">{this.state.newApplications}</Link></h1>
                            <span>New<br />Applications</span>
                            {/* </Link> */}
                        </div>
                        
                        <div className="summary-item">
                            <h1><Link to="/application-list">{this.state.openApplications}</Link></h1>
                            <span>Open<br />Applications</span>
                        </div>
                    </div>
                </div>
    
              <div className="nav-statistics-wrapper">
                <nav className="nav">
                  <a href="" className="nav-link active">Loans Awarded</a>
                  {/* <a href="" className="nav-link" onClick={() => alert('Metrics on adherence to customer SLAs coming soon!')}>Processing Times</a> */}
                  {/* <a href="" className="nav-link">Tab3</a>
                  <a href="" className="nav-link">Tab4</a> */}
                </nav>
                <nav className="nav">
                  {/* <a href="" className="nav-link">Today</a>
                  <a href="" className="nav-link active">This Week</a>
                  <a href="" className="nav-link">This Month</a> */}
                </nav>
              </div>
    
              <div className="row row-statistics mg-b-30">
                <div className="col-md-5">
                  <h1 className="tx-inverse tx-56 tx-lato tx-bold">$374,100</h1>
                  <h6 className="tx-15 tx-inverse tx-bold mg-b-20">Total loans awarded (7 days)</h6>
                  <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem</p>
                  <p className="tx-12">
                    {/* <a href="">View Details</a>
                    <a href="">Edit Settings</a> */}
                  </p>
                </div>
                <div className="col-md-7 mg-t-20 mg-md-t-0">
                  {/* <canvas id="chartBar1" height="280"></canvas> */}
                  {/* <CCharts
                type="doughnut"
                height={200}
                datasets={[
                  {
                    backgroundColor: [
                      '#41B883',
                      '#E46651',
                      '#00D8FF',
                      '#DD1B16'
                    ],
                    data: [40, 20, 80, 10]
                  }
                ]}
                labels={['>$25k', '>$50k', '>$100k', '+$100k']}
                options={{
                  tooltips: {
                    enabled: true
                  }
                }}
              /> */}
              {/* <Card  className="m-b-20">
                                <CardBody> */}

                                    {/* <h4  className="card-title mb-4">Pie Chart</h4> */}

                                    {/* <div  className="row text-center mt-4">
                                        <div  className="col-sm-4">
                                            <h5  className="mb-0 font-size-20">86541</h5>
                                            <p  className="text-muted">Activated</p>
                                        </div>
                                        <div  className="col-sm-4">
                                            <h5  className="mb-0 font-size-20">2541</h5>
                                            <p  className="text-muted">Pending</p>
                                        </div>
                                        <div  className="col-sm-4">
                                            <h5  className="mb-0 font-size-20">102030</h5>
                                            <p  className="text-muted">Deactivated</p>
                                        </div>
                                    </div> */}

                                    <div id="pie-chart" dir="ltr">
                                        <PieChart/>
                                    </div>

                                {/* </CardBody>
                            </Card> */}
                </div>
              </div>
    
              <hr />
    
              <div className="report-summary-header">
                <div>
                  <h4 className="tx-inverse mg-b-3">Overall Report Summary</h4>
                  <p className="mg-b-0"><i className="icon ion-calendar mg-r-3"></i> May 01, 2020 - May 31, 2020</p>
                </div>
                <div>
                  {/* <a href="#" className="btn btn-secondary" onClick={this.comingSoon}><i className="icon ion-ios-clock-outline tx-22"></i> Activity Logs</a> */}
                  {/* <a href="#" className="btn btn-secondary" onClick={this.comingSoon}><i className="icon ion-ios-gear-outline tx-24"></i> Edit Settings</a> */}
                </div>
              </div>
    
              <div className="row no-gutters dashboard-chart-one">
                <div className="col-md-4 col-lg">
                  <div className="card card-total">
                    <div>
                      <h1>420</h1>
                      <p>Total Applications</p>
                    </div>
                    <div>
                      <div className="tx-24 mg-b-10 tx-center op-5">
                        <i className="icon ion-man tx-gray-600"></i>
                        <i className="icon ion-man tx-gray-600"></i>
                        <i className="icon ion-man tx-gray-600"></i>
                        <i className="icon ion-man tx-gray-600"></i>
                        <i className="icon ion-man tx-gray-600"></i>
                        <i className="icon ion-man tx-gray-600"></i>
                        <i className="icon ion-man tx-gray-400"></i>
                        <i className="icon ion-man tx-gray-400"></i>
                        <i className="icon ion-man tx-gray-400"></i>
                        <i className="icon ion-man tx-gray-400"></i>
                      </div>
                      <label>Accepted (66%)</label>
                      <div className="mg-b-10">
                        {/* <div className="progress-bar bg-primary progress-bar-xs wd-65p" role="progressbar" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div> */}
                        <Progress color="primary" value={66}></Progress>
                      </div>
    
                      <label>Rejected (34%)</label>
                      <div>
                        {/* <div className="progress-bar bg-danger progress-bar-xs wd-35p" role="progressbar" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div> */}
                        <Progress color="danger" value={34}></Progress>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg">
                  <div className="card card-total">
                    <div>
                      <h1>277</h1>
                      <p>Approved Loans</p>
                    </div>
                    <div>
                      <div className="tx-16 mg-b-15 tx-center op-5">
                        <i className="icon ion-cube tx-gray-600"></i>
                        <i className="icon ion-cube tx-gray-600"></i>
                        <i className="icon ion-cube tx-gray-600"></i>
                        <i className="icon ion-cube tx-gray-600"></i>
                        <i className="icon ion-cube tx-gray-600"></i>
                        <i className="icon ion-cube tx-gray-600"></i>
                        <i className="icon ion-cube tx-gray-600"></i>
                        <i className="icon ion-cube tx-gray-600"></i>
                        <i className="icon ion-cube tx-gray-400"></i>
                        <i className="icon ion-cube tx-gray-400"></i>
                      </div>
                      <label>Loan Value Range #1 (85%)</label>
                      <div className="mg-b-10">
                        {/* <div className="progress-bar bg-success progress-bar-xs wd-85p" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div> */}
                        <Progress color="success" value={85}></Progress>
                      </div>
    
                      <label>Loan Value Range #2 (15%)</label>
                      <div>
                        {/* <div className="progress-bar bg-warning progress-bar-xs wd-15p" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div> */}
                        <Progress color="warning" value={15}></Progress>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg">
                  <div className="card card-total">
                    <div>
                      <h1>143</h1>
                      <p>Rejected Loans</p>
                    </div>
                    <div>
                      <div className="tx-22 mg-b-10 tx-center op-5">
                        <i className="icon ion-location tx-gray-600"></i>
                        <i className="icon ion-location tx-gray-600"></i>
                        <i className="icon ion-location tx-gray-600"></i>
                        <i className="icon ion-location tx-gray-600"></i>
                        <i className="icon ion-location tx-gray-600"></i>
                        <i className="icon ion-location tx-gray-600"></i>
                        <i className="icon ion-location tx-gray-600"></i>
                        <i className="icon ion-location tx-gray-400"></i>
                        <i className="icon ion-location tx-gray-400"></i>
                        <i className="icon ion-location tx-gray-400"></i>
                      </div>
                      <label>Rejection Reason #1 (75%)</label>
                      <div className="mg-b-10">
                        {/* <div className="progress-bar bg-purple progress-bar-xs wd-75p" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div> */}
                        <Progress color="primary" value={75}></Progress>
                      </div>
    
                      <label>Rejection Reason #2 (25%)</label>
                      <div>
                        {/* <div className="progress-bar bg-pink progress-bar-xs wd-25p" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div> */}
                        <Progress color="danger" value={25}></Progress>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md col-lg-4">
                  <div className="card card-revenue">
                    <h6>Monthly Total Loan Value</h6>
                    <p>Calculated end of the month (May 2020)</p>
                    <h1>$7,484,400 <span className="tx-success">1.4% up</span></h1>
                    <div id="rs3" className="ht-50 ht-sm-70 mg-r--1"></div>
                    <label>Last month (April 2020): <span>$7,122,554</span></label>
                  </div>
                </div>
              </div>
    
              <hr />
    
              <div className="report-summary-header">
                <div>
                  <h4 className="tx-inverse mg-b-3">Most Popular Loan Products</h4>
                  <p className="mg-b-0"><i className="icon ion-calendar mg-r-3"></i> May 01, 2020 - May 31, 2020</p>
                </div>
                <div>
                  <a href="#" className="btn btn-secondary" onClick={this.comingSoon}>Top Rated Loan Products</a>
                  <a href="#" className="btn btn-secondary" onClick={this.comingSoon}>View All Loan Products</a>
                </div>
              </div>
    
              <div className="row row-sm">
                <div className="col-md-6 col-lg-4">
                  <div className="card card-popular-product">
                    <label className="prod-id">Loan ID: #FUCU-1001</label>
                    <h5 className="prod-name"><a href="">Small Biz Loan #1</a></h5>
                    <p className="prod-by"></p>
                    {/* <p className="prod-by">By: <a href="">ThmPxls Security</a></p> */}
                    <div className="row">
                      <div className="col-5">
                        <h1>1,885</h1>
                        <p>Applications</p>
                      </div>
                      <div className="col-7">
                        <h1>$12,000,000</h1>
                        <p>Total Loaned</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mg-t-20 mg-md-t-0">
                  <div className="card card-popular-product">
                    <label className="prod-id">Loan ID: #FUCU-1002</label>
                    <h5 className="prod-name"><a href="">Small Biz Loan #2</a></h5>
                    <p className="prod-by"></p>
                    {/* <p className="prod-by">By: <a href="">ThmPxls Security</a></p> */}
                    <div className="row">
                      <div className="col-5">
                        <h1>362</h1>
                        <p>Applications</p>
                      </div>
                      <div className="col-7">
                        <h1>$1,350,000</h1>
                        <p>Total Loaned</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mg-t-20 mg-lg-t-0">
                  <div className="card card-popular-product">
                    <label className="prod-id">Loan ID: #FUCU-1003</label>
                    <h5 className="prod-name"><a href="">Small Biz Loan #3</a></h5>
                    <p className="prod-by"></p>
                    {/* <p className="prod-by">By: <a href="">ThmPxls Digital</a></p> */}
                    <div className="row">
                      <div className="col-5">
                        <h1>120</h1>
                        <p>Applications</p>
                      </div>
                      <div className="col-7">
                        <h1>$750,000</h1>
                        <p>Total Loaned</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
            {/* </div> */}
    
            {/* <div className="slim-footer">
              <div className="container">
                <p>Copyright 2018 &copy; All Rights Reserved. Slim Dashboard Template</p>
                <p>Designed by: <a href="">ThemePixels</a></p>
              </div>
            </div> */}
          {/* </div> */}
    
    
    
    
    
    
            </>
        )
    }
}

export default connect(null, { setBreadcrumbItems })(Home);