import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { MDBDataTable } from 'mdbreact';

//Import datatable css
import "./datatables.scss";

class NewApplications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "New Applications", link : "#" },
                { title : "", link : "#" },
            ],
        }
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("New Applications", this.state.breadcrumbItems);
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Applicant Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Company Name',
                    field: 'company',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'City',
                    field: 'city',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'State',
                    field: 'state',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Application date',
                    field: 'date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Loan Amount',
                    field: 'amount',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: [
                {
                    name: 'Tiger Nixon',
                    company: 'ChiliDog Takeout',
                    city: 'Newark',
                    state: 'NJ',
                    date: '2020/09/01',
                    amount: '$35,000'
                },
                {
                    name: 'Garrett Winters',
                    company: 'Winters Accounting',
                    city: 'Jersey City',
                    state: 'NJ',
                    date: '2020/09/01',
                    amount: '$100,000'
                },
                {
                    name: 'Ashton Cox',
                    company: 'Cox Writing School',
                    city: 'Brooklyn',
                    state: 'NY',
                    date: '2020/09/01',
                    amount: '$85,000'
                },
                {
                    name: 'Cedric Kelly',
                    company: 'Kelly Dance School',
                    city: 'Newark',
                    state: 'NJ',
                    date: '2020/08/28',
                    amount: '$125,000'
                },
                {
                    name: 'Airi Satou',
                    company: 'Satou Accountants',
                    city: 'Summit',
                    state: 'NJ',
                    date: '2020/08/28',
                    amount: '$25,000'
                },
                {
                    name: 'Brielle Williamson',
                    company: 'Brielle Artisanal Food Specialist',
                    city: 'Hudson',
                    state: 'NY',
                    date: '2020/08/28',
                    amount: '$100,000'
                },
                {
                    name: 'Herrod Chandler',
                    company: 'Chandler Personal Shopper Service',
                    city: 'Allentown',
                    state: 'NJ',
                    date: '2020/08/27',
                    amount: '$20,000'
                },
                {
                    name: 'Rhona Davidson',
                    company: 'Software Integration Specialists',
                    city: 'Somerville',
                    state: 'NJ',
                    date: '2020/08/27',
                    amount: '$30,000'
                },
                {
                    name: 'Colleen Hurst',
                    company: 'Happy Website Company',
                    city: 'Rahway',
                    state: 'NJ',
                    date: '2020/08/26',
                    amount: '$20,000'
                },
                {
                    name: 'Sonya Frost',
                    company: 'Frost Frozen Foods',
                    city: 'Plainfield',
                    state: 'NJ',
                    date: '2020/08/23',
                    amount: '$100,000'
                },
                {
                    name: 'Jena Gaines',
                    company: 'Gaines Home Cleaners',
                    city: 'Madison',
                    state: 'NJ',
                    date: '2020/08/23',
                    amount: '$40,000'
                },
                {
                    name: 'Quinn Flynn',
                    company: 'Flynn Accountants',
                    city: 'Oceanside',
                    state: 'NY',
                    date: '2020/08/22',
                    amount: '$325,000'
                },
                {
                    name: 'Charde Marshall',
                    company: 'DP Water Tours',
                    city: 'Deer Park',
                    state: 'NY',
                    date: '2020/08/22',
                    amount: '$475,000'
                },
                {
                    name: 'Haley Kennedy',
                    company: 'Haley Marketing Company',
                    city: 'Clinton',
                    state: 'NJ',
                    date: '2020/08/22',
                    amount: '$100,000'
                },
                {
                    name: 'Tatyana Fitzpatrick',
                    company: 'Russian Language School',
                    city: 'Bath',
                    state: 'NJ',
                    date: '2020/08/21',
                    amount: '$385,000'
                },
                {
                    name: 'Michael Silva',
                    company: 'Silva Pizza Parlor',
                    city: 'Long Beach',
                    state: 'NY',
                    date: '2020/08/21',
                    amount: '$75,000'
                },
                {
                    name: 'Paul Byrd',
                    company: 'Personal Chef',
                    city: 'Sayville',
                    state: 'NY',
                    date: '2020/08/20',
                    amount: '$75,000'
                },
                {
                    name: 'Gloria Little',
                    company: 'Little Sandwich Shop',
                    city: 'Freeport',
                    state: 'NY',
                    date: '2020/08/20',
                    amount: '$237'
                },
                {
                    name: 'Bradley Greer',
                    company: 'Soap Works',
                    city: 'Oceanside',
                    state: 'NY',
                    date: '2020/08/19',
                    amount: '$25,000'
                },
                {
                    name: 'Dai Rios',
                    company: 'Personnel Shopper',
                    city: 'New York',
                    state: 'NY',
                    date: '2020/08/19',
                    amount: '$25,000'
                },
                {
                    name: 'Jenette Caldwell',
                    company: 'Caldwell Landscape Gardening',
                    city: 'Nazareth',
                    state: 'NJ',
                    date: '2020/08/18',
                    amount: '$35,000'
                },
                {
                    name: 'Yuri Berry',
                    company: 'Yuri Software Consulting Company',
                    city: 'New York',
                    state: 'NY',
                    date: '2020/08/18',
                    amount: '$50,000'
                },
                {
                    name: 'Caesar Vance',
                    company: 'Vance Haircuts',
                    city: 'New York',
                    state: 'NY',
                    date: '2020/08/17',
                    amount: '$100,000'
                },
                {
                    name: 'Doris Wilder',
                    company: 'Wilder Water Park',
                    city: 'Shirley',
                    state: 'NY',
                    date: '2020/08/17',
                    amount: '$500,000'
                },
                {
                    name: 'Angelica Ramos',
                    company: 'Ramos Taqueria',
                    city: 'West Islip',
                    state: 'NY',
                    date: '2020/08/16',
                    amount: '$100,000'
                },
                {
                    name: 'Gavin Joyce',
                    company: 'Real Estate Developer',
                    city: 'Allentown',
                    state: 'NJ',
                    date: '2020/08/12',
                    amount: '$90,000'
                },
                {
                    name: 'Jennifer Chang',
                    company: 'Chang Takeout',
                    city: 'Easton',
                    state: 'NJ',
                    date: '2020/08/12',
                    amount: '$35,000'
                },
                {
                    name: 'Brenden Wagner',
                    company: 'Wagner German Beerhall',
                    city: 'Newark',
                    state: 'NJ',
                    date: '2020/08/12',
                    amount: '$200,000'
                },
                {
                    name: 'Fiona Green',
                    company: 'Recycled Soap Factory',
                    city: 'Brooklyn',
                    state: 'NY',
                    date: '2020/08/12',
                    amount: '$50,000'
                },
                {
                    name: 'Shou Itou',
                    company: 'Shou Online Marketing',
                    city: 'Newark',
                    state: 'NJ',
                    date: '2020/08/12',
                    amount: '$40,000'
                },
                {
                    name: 'Michelle House',
                    company: 'Software Integration Specialist',
                    city: 'Jersey City',
                    state: 'NJ',
                    date: '2020/08/12',
                    amount: '$95,000'
                },
                {
                    name: 'Suki Burks',
                    company: 'Freelance Website Developer',
                    city: 'Budd Lake',
                    state: 'NJ',
                    date: '2020/08/10',
                    amount: '$15,000'
                },
                // {
                //     name: 'Prescott Bartlett',
                //     position: 'Technical Author',
                //     office: 'London',
                //     age: '27',
                //     date: '2011/05/07',
                //     salary: '$145'
                // },
                // {
                //     name: 'Gavin Cortez',
                //     position: 'Team Leader',
                //     office: 'San Francisco',
                //     age: '22',
                //     date: '2008/10/26',
                //     salary: '$235'
                // },
                // {
                //     name: 'Martena Mccray',
                //     position: 'Post-Sales support',
                //     office: 'Edinburgh',
                //     age: '46',
                //     date: '2011/03/09',
                //     salary: '$324'
                // },
                // {
                //     name: 'Unity Butler',
                //     position: 'Marketing Designer',
                //     office: 'San Francisco',
                //     age: '47',
                //     date: '2009/12/09',
                //     salary: '$85'
                // },
                // {
                //     name: 'Howard Hatfield',
                //     position: 'Office Manager',
                //     office: 'San Francisco',
                //     age: '51',
                //     date: '2008/12/16',
                //     salary: '$164'
                // },
                // {
                //     name: 'Hope Fuentes',
                //     position: 'Secretary',
                //     office: 'San Francisco',
                //     age: '41',
                //     date: '2010/02/12',
                //     salary: '$109'
                // },
                // {
                //     name: 'Vivian Harrell',
                //     position: 'Financial Controller',
                //     office: 'San Francisco',
                //     age: '62',
                //     date: '2009/02/14',
                //     salary: '$452'
                // },
                // {
                //     name: 'Timothy Mooney',
                //     position: 'Office Manager',
                //     office: 'London',
                //     age: '37',
                //     date: '2008/12/11',
                //     salary: '$136'
                // },
                // {
                //     name: 'Jackson Bradshaw',
                //     position: 'Director',
                //     office: 'New York',
                //     age: '65',
                //     date: '2008/09/26',
                //     salary: '$645'
                // },
                // {
                //     name: 'Olivia Liang',
                //     position: 'Support Engineer',
                //     office: 'Singapore',
                //     age: '64',
                //     date: '2011/02/03',
                //     salary: '$234'
                // },
                // {
                //     name: 'Bruno Nash',
                //     position: 'Software Engineer',
                //     office: 'London',
                //     age: '38',
                //     date: '2011/05/03',
                //     salary: '$163'
                // },
                // {
                //     name: 'Sakura Yamamoto',
                //     position: 'Support Engineer',
                //     office: 'Tokyo',
                //     age: '37',
                //     date: '2009/08/19',
                //     salary: '$139'
                // },
                // {
                //     name: 'Thor Walton',
                //     position: 'Developer',
                //     office: 'New York',
                //     age: '61',
                //     date: '2013/08/11',
                //     salary: '$98'
                // },
                // {
                //     name: 'Finn Camacho',
                //     position: 'Support Engineer',
                //     office: 'San Francisco',
                //     age: '47',
                //     date: '2009/07/07',
                //     salary: '$87'
                // },
                // {
                //     name: 'Serge Baldwin',
                //     position: 'Data Coordinator',
                //     office: 'Singapore',
                //     age: '64',
                //     date: '2012/04/09',
                //     salary: '$138'
                // },
                // {
                //     name: 'Zenaida Frank',
                //     position: 'Software Engineer',
                //     office: 'New York',
                //     age: '63',
                //     date: '2010/01/04',
                //     salary: '$125'
                // },
                // {
                //     name: 'Zorita Serrano',
                //     position: 'Software Engineer',
                //     office: 'San Francisco',
                //     age: '56',
                //     date: '2012/06/01',
                //     salary: '$115'
                // },
                // {
                //     name: 'Jennifer Acosta',
                //     position: 'Junior Javascript Developer',
                //     office: 'Edinburgh',
                //     age: '43',
                //     date: '2013/02/01',
                //     salary: '$75'
                // },
                // {
                //     name: 'Cara Stevens',
                //     position: 'Sales Assistant',
                //     office: 'New York',
                //     age: '46',
                //     date: '2011/12/06',
                //     salary: '$145'
                // },
                // {
                //     name: 'Hermione Butler',
                //     position: 'Regional Director',
                //     office: 'London',
                //     age: '47',
                //     date: '2011/03/21',
                //     salary: '$356'
                // },
                // {
                //     name: 'Lael Greer',
                //     position: 'Systems Administrator',
                //     office: 'London',
                //     age: '21',
                //     date: '2009/02/27',
                //     salary: '$103'
                // },
                // {
                //     name: 'Jonas Alexander',
                //     position: 'Developer',
                //     office: 'San Francisco',
                //     age: '30',
                //     date: '2010/07/14',
                //     salary: '$86'
                // },
                // {
                //     name: 'Shad Decker',
                //     position: 'Regional Director',
                //     office: 'Edinburgh',
                //     age: '51',
                //     date: '2008/11/13',
                //     salary: '$183'
                // },
                // {
                //     name: 'Michael Bruce',
                //     position: 'Javascript Developer',
                //     office: 'Singapore',
                //     age: '29',
                //     date: '2011/06/27',
                //     salary: '$183'
                // },
                // {
                //     name: 'Donna Snider',
                //     position: 'Customer Support',
                //     office: 'New York',
                //     age: '27',
                //     date: '2011/01/25',
                //     salary: '$112'
                // }
            ]
        };
        return (
            <React.Fragment>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">New Applications</h4>
                                    {/* <p className="card-title-desc">mdbreact DataTables has most features enabled by default, so
                                        all you need to do to use it with your own tables is to call
                                        the construction function:{" "}
                                        <code>&lt;MDBDataTable striped /&gt;</code>.
                                    </p> */}
                                    <MDBDataTable
                                        responsive
                                        bordered
                                        striped
                                        data={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(NewApplications);