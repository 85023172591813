import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Button, Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Input } from 'reactstrap'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import infologo from '../../assets/images/info-icon.svg';
import plaidlogo from '../../assets/images/plaid-logo-icon.svg';
import docslogo from '../../assets/images/documents-icon.svg';
import banklogo from '../../assets/images/bank-icon.svg';
// import { Checkbox } from '@material-ui/core';

import './welcome-page.css'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Welcome", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Welcome", this.state.breadcrumbItems);
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs='12'>
                        <Card>
                            <CardHeader>
                                <CardTitle>Welcome to backabl</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs='12'>
                                        <p className="card-title-desc">
                                            We’re excited you’ve discovered the universe of funding options in CommunityBacker! To get the most from our site, 
                                            follow these setup steps below. If you have any questions, you can reach out to our team anytime through the “messages” tab. 
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs='4'><center><img src={infologo} width="150px" className="icon" /></center></Col>
                                    <Col xs='4'><center><img src={plaidlogo} width="150px" /></center></Col>
                                    <Col xs='4'><center><img src={docslogo} width="150px" /></center></Col>
                                    {/* <Col xs='3'><center><img src={banklogo} width="150px" /></center></Col> */}
                                </Row>
                                <Row>
                                    <Col xs='4'><center><p className="card-title-desc"><br />Step 1<br />General Info</p></center></Col>
                                    <Col xs='4'><center><p className="card-title-desc"><br />Step 2<br />Connect Plaid</p></center></Col>
                                    <Col xs='4'><center><p className="card-title-desc"><br />Step 3<br />Upload Docs</p></center></Col>
                                    {/* <Col xs='3'><center><p className="card-title-desc"><br />Step 4<br />Credit Union Info</p></center></Col> */}
                                </Row>
                                <Row>
                                    <Col xs='4'>
                                        <p className="card-title-desc">
                                            Fill out a short 3-min application that includes basic information like your name, business name, and other related info.
                                        </p>
                                    </Col>
                                    <Col xs='4'>
                                        <p className="card-title-desc">
                                            Securely connect your bank account. We use this data to help build out your loan/grant application, and we never share this 
                                            data with outside parties.
                                        </p>
                                    </Col>
                                    <Col xs='4'>
                                        <p className="card-title-desc">
                                            Upload a few remaining documents including financials, your government ID, and other business records.
                                        </p>
                                    </Col>
                                    {/* <Col xs='3'>
                                        <p className="card-title-desc">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                        </p>
                                    </Col> */}
                                </Row>
                                <Row>
                                    <Col xs='4'><center><Button>Step 1</Button></center></Col>
                                    <Col xs='4'><center><Button>Step 2</Button></center></Col>
                                    <Col xs='4'><center><Button>Step 3</Button></center></Col>
                                    {/* <Col xs='3'><center><Button>Step 4</Button></center></Col> */}
                                </Row>
                                <Row>
                                    <Col xs='12'>
                                        <center>
                                            <br />
                                            <FormGroup check>
        <Label check>
          <Input type="checkbox" />{' '}
          Don't show me this again
        </Label>
      </FormGroup>
                                        </center>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>            
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(Welcome)