import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, CardHeader, CardTitle, FormGroup, Col, Row, Card, Input, Button } from 'reactstrap';
import { Link, withRouter } from "react-router-dom";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'


class MyTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "My Tasks", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            // username: ""
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("My Tasks", this.state.breadcrumbItems);
        // const result = getLoggedInUser() 
        // console.log("Logged In User");
        // console.log(result.username)
        // this.setState({username: result.username})
    }

    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <CardTitle>My Tasks</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/* <h4 className="card-title">My Tasks</h4>
                                <p className="card-title-desc">
                                    Coming Soon!
        </p>*/}
                        <ol className="activity-feed mb-0">
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">September 18, 2020</span>
                                    <span className="activity-text">Deadline to review application from <Link to="/lender/5f2de87e35579ba58cbf7a97">Susan Boyce of Widgets.com</Link></span>
                                </div>
                            </li>
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">September 19, 2020</span>
                                    <span className="activity-text">Deadline to review application from <Link to="/lender/5f2de87e35579ba58cbf7a99">Andrews Smith of Smith Accountants</Link></span>
                                </div>
                            </li>
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">September 19, 2020</span>
                                    <span className="activity-text">Follow up on additional documentation request from <Link to="/lender/5f2de87e35579ba58cbf7a99">Barbara Sinclair of Sinclair Haircare Products</Link></span>
                                </div>
                            </li>
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">September 20, 2020</span>
                                    <span className="activity-text">Deadline for decision on application from <Link to="/lender/5f2de87e35579ba58cbf7a99">Joe Smith of Joe's Software Consulting Company</Link></span>
                                </div>
                            </li>
                        </ol>
                                </CardBody>
                                </Card></Col></Row>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(MyTasks)