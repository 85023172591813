import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    CardFooter,
    CardBody, 
    Col,
    FormGroup, 
    Label,
    Input,
    Row,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormText
} from 'reactstrap'
import { Link } from 'react-router-dom'
import uuid from "uuid/v4";
import Select from "react-select";

import Board from 'react-trello'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import axios from 'axios'


import { setBreadcrumbItems } from '../../store/actions'
import { getTest } from '../../services/testService'
import { getLoggedInUser } from '../../helpers/authUtils'
import myTeam from '../../pages/Demo/my-team';

import { config } from '../../config'

import './styles.css'

const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

const usStates = [
    { label: "Alabama", value: "US-AL" },
    { label: "Alaska", value: "US-AK" },
    { label: "Arizona", value: "US-AZ" },
    { label: "Arkansas", value: "US-AR" },
    { label: "California", value: "US-CA" },
    { label: "Colorado", value: "US-CO" },
    { label: "Connecticut", value: "US-CT" },
    { label: "Delaware", value: "US-DE" },
    { label: "District of Columbia", value: "US-DC" },
    { label: "Florida", value: "US-FL" },
    { label: "Georgia", value: "US-GA" },
    { label: "Hawaii", value: "US-HI" },
    { label: "Idaho", value: "US-ID" },
    { label: "Illinois", value: "US-IL" },
    { label: "Indiana", value: "US-IN" },
    { label: "Iowa", value: "US-IA" },
    { label: "Kansas", value: "US-KS" },
    { label: "Kentucky", value: "US-KY" },
    { label: "Louisiana", value: "US-LA" },
    { label: "Maine", value: "US-ME" },
    { label: "Maryland", value: "US-MD" },
    { label: "Massachusetts", value: "US-MA" },
    { label: "Michigan", value: "US-MI" },
    { label: "Minnesota", value: "US-MN" },
    { label: "Mississippi", value: "US-MS" },
    { label: "Missouri", value: "US-MO" },
    { label: "Montana", value: "US-MT" },
    { label: "Nebraska", value: "US-NE" },
    { label: "Nevada", value: "US-NV" },
    { label: "New Hampshire", value: "US-NH" },
    { label: "New Jersey", value: "US-NJ" },
    { label: "New Mexico", value: "US-NM" },
    { label: "New York", value: "US-NY" },
    { label: "North Carolina", value: "US-NC" },
    { label: "North Dakota", value: "US-ND" },
    { label: "Ohio", value: "US-OH" },
    { label: "Oklahoma", value: "US-OK" },
    { label: "Oregon", value: "US-OR" },
    { label: "Pennsylvania", value: "US-PA" },
    { label: "Rhode Island", value: "US-RI" },
    { label: "South Carolina", value: "US-SC" },
    { label: "South Dakota", value: "US-SD" },
    { label: "Tennessee", value: "US-TN" },
    { label: "Texas", value: "US-TX" },
    { label: "Utah", value: "US-UT" },
    { label: "Vermont", value: "US-VT" },
    { label: "Virginia", value: "US-VA" },
    { label: "Washington", value: "US-WA" },
    { label: "West Virginia", value: "US-WV" },
    { label: "Wisconsin", value: "US-WI" },
    { label: "Wyoming", value: "US-WY" }                                                
]
const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" }
      ]
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" }
      ]
    }
  ];

const itemsFromBackend = [
    { id: uuid(), condType: "001", title: "Relative is Member", description: "The applicant has an immediate relative who is a current member of the Credit Union." },
    { id: uuid(), condType: "003", title: "Affiliate Member", description: "The applicant is part of an affiliated company, community organization, or government agency." },
    { id: uuid(), condType: "004", title: "Join Non-Profit", description: "The applicant must be willing to join a pre-approved or secified non-profit organization." },
    { id: uuid(), condType: "005", title: "Employment", description: "The applicant must be a current or former employee, or a relative of a current or former employee, of the Credit Union" },
    { id: uuid(), condType: "006", title: "City Residency", description: "The applicant must live, worship, or work in a specified city or cities." },
    { id: uuid(), condType: "007", title: "State Residency", description: "The applicant must live, worship, or work in a specified state or states." },
    { id: uuid(), condType: "008", title: "Employee of SEG", description: "The applicant must be a current or former member of a specified Select Employee Group (SEG)." }
  ];

class Membership extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Membership", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            username: "",
            // columns: {
            //     [uuid()]: {
            //       name: "Available Conditions",
            //       items: itemsFromBackend
            //     },
            //     [uuid()]: {
            //       name: "Membership Requirements",
            //       items: [{ id: uuid(), condType: "002", title: "Over 18", description: "The applicant must be 18 years or older at the time of the application." }]
            //     }
            //   },
            columns: {
                ['available']: {
                  name: "Available Conditions",
                  items: itemsFromBackend
                },
                ['selected']: {
                  name: "Membership Requirements",
                  items: [{ id: uuid(), condType: "002", title: "Over 18", description: "The applicant must be 18 years or older at the time of the application." }]
                }
              },

            over18ConditionModal: false,
            relativeIsMemberConditionModal: false,
            affiliateMemberConditionModal: false,
            joinNonProfitConditionModal: false,
            employmentConditionModal: false,
            cityResidencyConditionModal: false,
            stateResidencyConditionModal: false,
            employeeOfSEGConditionModal: false,

            over18Settings: {},
            relativeIsMemberSettings: {},
            affiliateMemberSettings: {},
            joinNonProfitSettings: {},
            employmentSettings: {},
            cityResidencySettings: {},
            stateResidencySettings: {},
            employeeOfSEGSettings: {},

            nonProfitTextBox: "",
            employeeSEGTextBox: "",
            cityResidencyTextBox: "",
            affiliateTextBox: "",

            selectedMulti: null,
        }

        this.toggleOver18ConditionModal             = this.toggleOver18ConditionModal.bind(this)
        this.toggleRelativeIsMemberConditionModal   = this.toggleRelativeIsMemberConditionModal.bind(this)
        this.toggleAffiliateMemberConditionModal    = this.toggleAffiliateMemberConditionModal.bind(this)
        this.toggleJoinNonProfitConditionModal      = this.toggleJoinNonProfitConditionModal.bind(this)
        this.toggleEmploymentConditionModal         = this.toggleEmploymentConditionModal.bind(this)
        this.toggleCityResidencyConditionModal      = this.toggleCityResidencyConditionModal.bind(this)
        this.toggleStateResidencyConditionModal     = this.toggleStateResidencyConditionModal.bind(this)
        this.toggleEmployeeOfSEGConditionModal      = this.toggleEmployeeOfSEGConditionModal.bind(this)

        this.submitOver18Condition                  = this.submitOver18Condition.bind(this)
        this.submitRelativeIsMemberCondition        = this.submitRelativeIsMemberCondition.bind(this)
        this.submitAffiliateMemberCondition         = this.submitAffiliateMemberCondition.bind(this)
        this.submitJoinNonProfitCondition           = this.submitJoinNonProfitCondition.bind(this)
        this.submitEmploymentCondition              = this.submitEmploymentCondition.bind(this)
        this.submitCityResidencyCondition           = this.submitCityResidencyCondition.bind(this)
        this.submitStateResidencyCondition          = this.submitStateResidencyCondition.bind(this)
        this.submitEmployeeOfSEGCondition           = this.submitEmployeeOfSEGCondition.bind(this)
    } 

    toggleOver18ConditionModal() {
        this.setState(prevState => ({
            over18ConditionModal : !prevState.over18ConditionModal
        }))
    }

    toggleRelativeIsMemberConditionModal() {
        this.setState(prevState => ({
            relativeIsMemberConditionModal : !prevState.relativeIsMemberConditionModal
        }))
    }

    toggleAffiliateMemberConditionModal() {
        this.setState(prevState => ({
            affiliateMemberConditionModal : !prevState.affiliateMemberConditionModal
        }))
    }

    toggleJoinNonProfitConditionModal() {
        this.setState(prevState => ({
            joinNonProfitConditionModal : !prevState.joinNonProfitConditionModal
        }))
    }

    toggleEmploymentConditionModal() {
        this.setState(prevState => ({
            employmentConditionModal : !prevState.employmentConditionModal
        }))
    }

    toggleCityResidencyConditionModal() {
        this.setState(prevState => ({
            cityResidencyConditionModal : !prevState.cityResidencyConditionModal
        }))
    }

    toggleStateResidencyConditionModal() {
        this.setState(prevState => ({
            stateResidencyConditionModal : !prevState.stateResidencyConditionModal
        }))
    }

    toggleEmployeeOfSEGConditionModal() {
        this.setState(prevState => ({
            employeeOfSEGConditionModal : !prevState.employeeOfSEGConditionModal
        }))
    }

    renderOver18ConditionModal() {
        return(
            <Modal isOpen={this.state.over18ConditionModal} toggle={this.toggleOver18ConditionModal} autoFocus={true} size="lg" backdrop={'static'}>
                <ModalHeader toggle={this.toggleOver18ConditionModal}>Over 18</ModalHeader>
                <ModalBody>
                    <div style={{minWidth: 500 }}>
                        <p>There are no rule attributes to configure.</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleOver18ConditionModal}>Close</Button>
                    <Button type="submit" color="primary" className="waves-effect waves-light" onClick={this.submitOver18Condition}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderRelativeIsMemberConditionModal() {
        return(
            <Modal isOpen={this.state.relativeIsMemberConditionModal} toggle={this.toggleRelativeIsMemberConditionModal} autoFocus={true} size="lg" backdrop={'static'}>
                <ModalHeader toggle={this.toggleRelativeIsMemberConditionModal}>Relative is Member</ModalHeader>
                <ModalBody>
                    <div style={{minWidth: 500 }}>
                        <p>There are no rule attributes to configure.</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleRelativeIsMemberConditionModal}>Close</Button>
                    <Button type="submit" color="primary" className="waves-effect waves-light" onClick={this.submitRelativeIsMemberCondition}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderAffiliateMemberConditionModal() {
        return(
            <Modal isOpen={this.state.affiliateMemberConditionModal} toggle={this.toggleAffiliateMemberConditionModal} autoFocus={true} size="lg" backdrop={'static'}>
                <ModalHeader toggle={this.toggleAffiliateMemberConditionModal}>Affiliate Member</ModalHeader>
                <ModalBody>
                    <div style={{minWidth: 500 }}>
                        <p>Enter the name of each affiliate on a separate line.</p>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" rows="6" />
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleAffiliateMemberConditionModal}>Close</Button>
                    <Button type="submit" color="primary" className="waves-effect waves-light" onClick={this.submitAffiliateMemberCondition}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderJoinNonProfitConditionModal() {
        return(
            <Modal isOpen={this.state.joinNonProfitConditionModal} toggle={this.toggleJoinNonProfitConditionModal} autoFocus={true} size="lg" backdrop={'static'}>
                <ModalHeader toggle={this.toggleJoinNonProfitConditionModal}>Join Non-Profit</ModalHeader>
                <ModalBody>
                    <div style={{minWidth: 500 }}>
                        <p>Enter the name of each non-profit on a separate line. </p>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" rows="6" />
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleJoinNonProfitConditionModal}>Close</Button>
                    <Button type="submit" color="primary" className="waves-effect waves-light" onClick={this.submitJoinNonProfitCondition}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderEmploymentConditionModal() {
        return(
            <Modal isOpen={this.state.employmentConditionModal} toggle={this.toggleEmploymentConditionModal} autoFocus={true} size="lg" backdrop={'static'}>
                <ModalHeader toggle={this.toggleEmploymentConditionModal}>Employment</ModalHeader>
                <ModalBody>
                    <div style={{minWidth: 500 }}>
                        <p>There are no rule attributes to configure.</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleEmploymentConditionModal}>Close</Button>
                    <Button type="submit" color="primary" className="waves-effect waves-light" onClick={this.submitEmploymentCondition}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderCityResidencyConditionModal() {
        return(
            <Modal isOpen={this.state.cityResidencyConditionModal} toggle={this.toggleCityResidencyConditionModal} autoFocus={true} size="lg" backdrop={'static'}>
                <ModalHeader toggle={this.toggleCityResidencyConditionModal}>City Residency</ModalHeader>
                <ModalBody>
                    <div style={{minWidth: 500 }}>
                        <p>Enter the name of each city and state on a separate line, e.g. Newark, NJ </p>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" rows="6" />
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleCityResidencyConditionModal}>Close</Button>
                    <Button type="submit" color="primary" className="waves-effect waves-light" onClick={this.submitCityResidencyCondition}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderStateResidencyConditionModal() {
        return(
            <Modal isOpen={this.state.stateResidencyConditionModal} toggle={this.toggleStateResidencyConditionModal} autoFocus={true} size="lg" backdrop={'static'}>
                <ModalHeader toggle={this.toggleStateResidencyConditionModal}>State Residency</ModalHeader>
                <ModalBody>
                    <div style={{minWidth: 500 }}>
                        
                        <FormGroup className="mb-0">
                            <Label className="control-label">Select States</Label>
                            <Select
                                value={this.selectedMulti}
                                isMulti={true}
                                onChange={this.handleMulti}
                                options={usStates}
                                className="select2 select2-multiple"
                            />

                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleStateResidencyConditionModal}>Close</Button>
                    <Button type="submit" color="primary" className="waves-effect waves-light" onClick={this.submitStateResidencyCondition}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderEmployeeOfSEGConditionModal() {
        return(
            <Modal isOpen={this.state.employeeOfSEGConditionModal} toggle={this.toggleEmployeeOfSEGConditionModal} autoFocus={true} size="lg" backdrop={'static'}>
                <ModalHeader toggle={this.toggleEmployeeOfSEGConditionModal}>Employee of SEG</ModalHeader>
                <ModalBody>
                    <p>Enter the name of each SEG on a separate line. </p>
                        <FormGroup>
                            <Input type="textarea" name="text" id="exampleText" rows="6" />
                        </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleEmployeeOfSEGConditionModal}>Close</Button>
                    <Button type="submit" color="primary" className="waves-effect waves-light" onClick={this.submitEmployeeOfSEGCondition}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderOver18ConditionCard() {
        return(
            <Card>
                <CardBody>
                    <h5>Over 18</h5>
                    The applicant must be 18 years or older at the time of the application.
                </CardBody>
                <CardFooter>
                    <Button type="button" color="primary" className="waves-effect waves-light" onClick={this.toggleOver18ConditionModal}>Configure</Button>
                </CardFooter>
            </Card>
        )
    }

    renderRelativeIsMemberConditionCard() {
        return(
            <Card>
                <CardBody>
                    <h5>Relative is Member</h5>
                    The applicant has an immediate relative who is a current member of the Credit Union.
                </CardBody>
                <CardFooter>
                    <Button type="button" color="primary" className="waves-effect waves-light" onClick={this.toggleRelativeIsMemberConditionModal}>Configure</Button>
                </CardFooter>
            </Card>
        )
    }

    renderAffiliateMemberConditionCard() {
        return(
            <Card>
                <CardBody>
                    <h5>Affiliate Member</h5>
                    Applicant is part of an affiated company, community organization, or government agency.
                </CardBody>
                <CardFooter>
                    <Button type="button" color="primary" className="waves-effect waves-light" onClick={this.toggleAffiliateMemberConditionModal}>Configure</Button>
                </CardFooter>
            </Card>
        )
    }

    renderJoinNonProfitConditionCard() {
        return(
            <Card>
                <CardBody>
                    <h5>Join Non-Profit</h5>
                    The applicant must be willing to join a pre-approved or specified non-profit organization.
                </CardBody>
                <CardFooter>
                    <Button type="button" color="primary" className="waves-effect waves-light" onClick={this.toggleJoinNonProfitConditionModal}>Configure</Button>
                </CardFooter>
            </Card>
        )
    }

    renderEmploymentConditionCard() {
        return(
            <Card>
                <CardBody>
                    <h5>Employment</h5>
                    The applicant must be a current or former employee, or a relative of a current of former employee, of the Credit Union.
                </CardBody>
                <CardFooter>
                    <Button type="button" color="primary" className="waves-effect waves-light" onClick={this.toggleEmploymentConditionModal}>Configure</Button>
                </CardFooter>
            </Card>
        )
    }

    renderCityResidencyConditionCard() {
        return(
            <Card>
                <CardBody>
                    <h5>City Residency</h5>
                    The applicant must live, worship, or work in a specified city or cities.
                </CardBody>
                <CardFooter>
                    <Button type="button" color="primary" className="waves-effect waves-light" onClick={this.toggleCityResidencyConditionModal}>Configure</Button>
                </CardFooter>
            </Card>
        )
    }

    renderStateResidencyConditionCard() {
        return(
            <Card>
                <CardBody>
                    <h5>State Residency</h5>
                    The applicant must live, worship, or work in a specified state or states.
                </CardBody>
                <CardFooter>
                    <Button type="button" color="primary" className="waves-effect waves-light" onClick={this.toggleStateResidencyConditionModal}>Configure</Button>
                </CardFooter>
            </Card>
        )
    }

    renderEmployeeOfSEGConditionCard() {
        return(
            <Card>
                <CardBody>
                    <h5>Employee of SEG</h5>
                    The applicant must be a current or former member of a specified Select Employee Group (SEG).
                </CardBody>
                <CardFooter>
                    <Button type="button" color="primary" className="waves-effect waves-light" onClick={this.toggleEmployeeOfSEGConditionModal}>Configure</Button>
                </CardFooter>
            </Card>
        )
    }

    submitOver18Condition() {
        console.log("Submitting Updates to Over18 Condition")
        this.toggleOver18ConditionModal()
    }
    submitRelativeIsMemberCondition() {
        console.log("Submitting Updates to Relative is Member Condition")
        this.toggleRelativeIsMemberConditionModal()
    }
    submitAffiliateMemberCondition() {
        console.log("Submitting Update to Affiliate Member Condition")
        this.toggleAffiliateMemberConditionModal()
    }
    submitJoinNonProfitCondition() { 
        console.log("Submitting Updates to Join Non-Profit Condition")
        this.toggleJoinNonProfitConditionModal()
    }
    submitEmploymentCondition() {
        console.log("Submitting Updates to Employment Condition")
        this.toggleEmploymentConditionModal()
    }
    submitCityResidencyCondition() {
        console.log("Submitting Updates to City Residency Condition")
        this.toggleCityResidencyConditionModal()
    }
    submitStateResidencyCondition() {
        console.log("Submitting Updates to State Residency Condition")
        this.toggleStateResidencyConditionModal()
    }
    submitEmployeeOfSEGCondition() {
        console.log("Submitting Updates to Employee of SEG Condition")
        this.toggleEmployeeOfSEGConditionModal()
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Membership", this.state.breadcrumbItems);
        const result = getLoggedInUser() 

        const token = result.token;
        const lenderId = result.lenderId

        console.log("Logged In User");
        console.log(result.username)
        this.setState({username: result.username})

        axios.get(`${BASEAPI}/lenders/${lenderId}/membership-rules`, { headers: {"Authorization" : `Bearer ${token}`} }).then(results => {
            console.log(results)
            if (Object.keys(results.data.apiData.criteria).length === 0) {
                // Setup defaults
                // or just do nothing...
                //this.setState({})
                console.log("EMPTY CRITERIA - USE DEFAULT")
            } else {
                // Set with returned vals
                console.log("USE SAVED CRITERIA")
                const criteria  = results.data.apiData.criteria
                const available = criteria.available
                const selected  = criteria.selected

                var _data = [this.state.columns][0]
                _data['selected'].items = selected
                _data['available'].items = available

                this.setState({ _data })
                this.setState({ over18Settings: criteria.over18Settings })
                this.setState({ relativeIsMemberSettings: criteria.relativeIsMemberSettings })
                this.setState({ affiliateMemberSettings: criteria.affiliateMemberSettings })
                this.setState({ joinNonProfitSettings: criteria.joinNonProfitSettings })
                this.setState({ employmentSettings: criteria.employmentSettings })
                this.setState({ cityResidencySettings: criteria.cityResidencySettings })
                this.setState({ stateResidencySettings: criteria.stateResidencySettings })
                this.setState({ employeeOfSEGSettings: criteria.employeeOfSEGSettings })

                // Available

                // Selected

                // Updated State
            }
        })
    }

    onDragEnd(result, c) {
        if (!result.destination) return;
        const { source, destination } = result;
    
        if (source.droppableId !== destination.droppableId) {

            const sourceColumn = this.state.columns[source.droppableId];
            const destColumn = this.state.columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            var _data = [this.state.columns][0]
            _data[source.droppableId].items = sourceItems
            _data[destination.droppableId].items = destItems
            this.setState({_data})

        } else {

            const column = this.state.columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);

            var _data = [this.state.columns][0]
            _data[source.droppableId].items = copiedItems
            this.setState({_data})

        }

        // 
        
        const payload = {
            available: this.state.columns['available'].items,
            selected: this.state.columns['selected'].items,
            over18Settings: this.state.over18Settings,
            relativeIsMemberSettings: this.state.relativeIsMemberSettings,
            affiliateMemberSettings: this.state.affiliateMemberSettings,
            joinNonProfitSettings: this.state.joinNonProfitSettings,
            employmentSettings: this.state.employmentSettings,
            cityResidencySettings: this.state.cityResidencySettings,
            stateResidencySettings: this.state.stateResidencySettings,
            employeeOfSEGSettings: this.state.employeeOfSEGSettings
        }

        // Axios - update state online
        const userResult = getLoggedInUser() 

        const token = userResult.token
        const lenderId = userResult.lenderId

        axios.put(`${BASEAPI}/lenders/${lenderId}/membership-rules`, payload, { headers: {"Authorization" : `Bearer ${token}`} }).then(results => {
            console.log("I updated the server!")
        })
    }

    publishMembershipConditions() {
        console.table(this.state.columns['selected'].items)

        const userResult = getLoggedInUser() 

        const token = userResult.token;
        const lenderId = userResult.lenderId
        const payload = { action: 'publish' }

        // axios.put(`${BASEAPI}/lenders/${lenderId}/membership-rules`, payload, { headers: {"Authorization" : `Bearer ${token}`} }).then(results => {
        //     console.log("I updated the server!")
        // })
    }

    render() {

        const theCard = (condType) => {
            switch(condType) {
                case "001" : return this.renderRelativeIsMemberConditionCard()
                case "002" : return this.renderOver18ConditionCard()
                case "003" : return this.renderAffiliateMemberConditionCard()
                case "004" : return this.renderJoinNonProfitConditionCard()
                case "005" : return this.renderEmploymentConditionCard()
                case "006" : return this.renderCityResidencyConditionCard()
                case "007" : return this.renderStateResidencyConditionCard()
                case "008" : return this.renderEmployeeOfSEGConditionCard()
            }
        }

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Membership</h4>
                                <p className="card-title-desc">
                                    Define your membership criteria by dragging criteria from the available conditions column to the requirements columns where, if applicable, you can customize and specialize the rule
                                    to satisfy your specific Credit Union requirements. Completing this will better aid us in pre-screening potential applicants to ensure they satisfy your particular membership criteria.
                                </p>

                                <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
                                    <DragDropContext onDragEnd={result => this.onDragEnd(result, this.state.columns)}>
                                        {Object.entries(this.state.columns).map(([columnId, column], index) => {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} key={columnId}>
                                                    <h2>{column.name}</h2>
                                                    <div style={{ margin: 8 }}>
                                                        <Droppable droppableId={columnId} key={columnId}>
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                        background: snapshot.isDraggingOver
                                                                            ? "lightblue"
                                                                            : "lightgrey",
                                                                        padding: 10,
                                                                        width: 500,
                                                                        minHeight: 500
                                                                        }}
                                                                    >
                                                                        {column.items.map((item, index) => {
                                                                            return (
                                                                                <div>
                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                    {(provided, snapshot) => {
                                                                                        return (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={{
                                                                                                    userSelect: "none",
                                                                                                    padding: 0,
                                                                                                    margin: "0 0 8px 0",
                                                                                                    minHeight: "50px",
                                                                                                    backgroundColor: snapshot.isDragging ? "#263B4A" : "#456C86",
                                                                                                    color: "black",
                                                                                                    ...provided.draggableProps.style
                                                                                                }}
                                                                                            >
                                                                                                {theCard(item.condType)}
                                                                                                
                                                                                            </div>
                                                                                        );
                                                                                    }}
                                                                                </Draggable>
                                                                                </div>
                                                                                
                                                                            );
                                                                        })}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </DragDropContext>
                                </div>
                                <Button type="button" color="primary" size="lg" block className="waves-effect waves-light" onClick={() => this.publishMembershipConditions()}>Publish</Button>

                                {this.renderOver18ConditionModal()}
                                {this.renderRelativeIsMemberConditionModal()}
                                {this.renderAffiliateMemberConditionModal()}
                                {this.renderJoinNonProfitConditionModal()}
                                {this.renderEmploymentConditionModal()}
                                {this.renderCityResidencyConditionModal()}
                                {this.renderStateResidencyConditionModal()}
                                {this.renderEmployeeOfSEGConditionModal()}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>                
            </React.Fragment>
        )
    }
}

export default connect(null, {setBreadcrumbItems})(Membership)