import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, FormGroup, Col, Row, Card, Input, Button } from 'reactstrap';

import axios from 'axios'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class CompanyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Company Profile", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            lenderName: "",
            lenderAddress: {}

            // username: ""
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Account", this.state.breadcrumbItems);
        // const result = getLoggedInUser() 
        // console.log("Logged In User");
        // console.log(result.username)
        // this.setState({username: result.username})

        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders/${lenderId}`, { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(`Result: ${result}`)
            console.log(result)
            this.setState({lenderName : result.data.apiData.orgName})
            this.setState({lenderAddress : result.data.apiData.address})
        })
    }

    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Company Profile</h4>
                                <p className="card-title-desc">
                                    Coming Soon!
                                </p>
                                {/* <Button onClick={async () => {await getTest()}}>Hello</Button>
                                <p>Username: {this.state.username}</p> */}
                                <p>Company Name: <b>{this.state.lenderName}</b></p>
                                <p>Address</p>
                                <p><b>{this.state.lenderAddress.line1}</b></p>
                                <p><b>{this.state.lenderAddress.city}, {this.state.lenderAddress.state} {this.state.lenderAddress.zipcode}</b></p>
                                <p>This page will display the company/organization details, including branch information. Need to determine who has permission to update.</p>
                                <p>From here you should be able to manage branches and loans too.</p>
                                </CardBody>
                                </Card></Col></Row>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(CompanyProfile)