import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Card, CardBody, Button, CardHeader, CardTitle } from "reactstrap";
import produce from 'immer'
import axios from 'axios'

//Import Images
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { config } from '../../config'
const API_URL = config.url.API_URL
const BASEAPI = `${API_URL}/api/v1`


class MyApplications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "My Applications", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            transactions : [
                { imgUrl : user2, name : "Credit Union of Pluto", status : "Approved", amount : "25,000", date : "5/12/2016", color : "success" },
                { imgUrl : user3, name : "Credit Union of Mars", status : "Received", amount : "7,500", date : "10/11/2016", color : "warning" },
                { imgUrl : user4, name : "Credit Union of Venus	", status : "Approved", amount : "1,000", date : "8/11/2016", color : "success" },
                { imgUrl : user5, name : "Credit Union of Mars", status : "Rejected", amount : "45,000", date : "7/11/2016", color : "danger" },
                { imgUrl : user6, name : "Credit Union of Venus", status : "Approved", amount : "10,000", date : "1/11/2016", color : "success" },
            ],
            applications : []
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("My Applications", this.state.breadcrumbItems);

        axios.get(`${BASEAPI}/applications`).then(results => {
            let arr = []
            results.data.apiData.forEach(record => {
                let doc = {}
                doc['_id'] = record._id
                doc['cbid'] = record.cbid
                doc['lenderName'] = record.lenderName
                doc['lenderId'] = record.lenderId
                doc['loanDesc'] = record.loanDesc
                doc['amount'] = record.amount
                doc['applied'] = record.applied
                doc['status'] = record.status

                arr.push(doc)
            })

            this.setState(produce(draft => {
                draft.applications = arr
            }))
            
        })
    }

    render() {

        var tableData = this.state.applications.map(function(record) {
             return <tr key={record._id}>
                <td>
                    <p className="m-0 text-muted font-14"><Link to={"/lender/" + record.lenderId}>{record.lenderName}</Link></p>
                </td>
                <td>
                    <p className="m-0 text-muted font-14">{record.loanDesc}</p>
                </td>
                <td>
                    <p className="m-0 text-muted font-14">{record.amount}</p>
                </td>
                <td>
                    <p className="m-0 text-muted font-14">{record.applied}</p>
                </td>
                <td>
                    <p className="m-0 text-muted font-14">{record.status}</p>
                </td>
                <td>
                    <Link to={"/my-application/" + record._id}>
                        <Button color="secondary" size="sm" className="waves-effect waves-light">View</Button>
                    </Link>
                </td>
            </tr>

        })

        const fullWidth = { width : '100%' }

        return (
            <React.Fragment>

                    <Card>
                        <CardHeader><CardTitle><h4 className="card-title">My Applications</h4></CardTitle></CardHeader>
                                <CardBody>
                                    <p>Below are your past loan applications.  </p>
                                    <center>
                                    <form className="app-search d-none d-lg-inline-block" style={fullWidth}>
                                        <div className="position-relative">
                                            <input type="text" className="form-control" placeholder="Search..." onClick={() => alert('Coming Soon - Site search not yet implemented')}/>
                                            <span className="fa fa-search"></span>
                                        </div>
                                    </form>
                                    </center>
                                    {/* <div className="table-responsive">
                                        <Table className="table-centered table-vertical table-nowrap">
                                            <tbody>
                                                {
                                                    this.state.transactions.map((transaction, key) =>
                                                        <tr key={key}>
                                                            <td>
                                                                <img src={transaction.imgUrl} alt="user" className="avatar-xs rounded-circle mr-2" /> {transaction.name}
                                                            </td>
                                                            <td>
                                                                <p className="m-0 text-muted font-14">SBA Starter Loan 3.5%/pa </p>
                                                            </td>
                                                            <td><i className={"mdi mdi-checkbox-blank-circle  text-" + transaction.color}></i> {transaction.status}</td>
                                                            <td>
                                                            <p className="m-0 text-muted font-14">Loan:</p>
                                                                ${transaction.amount}
                                                            </td>
                                                            <td>
                                                                <p className="m-0 text-muted font-14">Applied: </p>
                                                                {transaction.date}
                                                            </td>
                                                            <td>
                                                                <Button color="secondary" size="sm" className="waves-effect waves-light">View</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div> */}
                                    {/* <h4 className="card-title mb-4">Real Data Below</h4> */}
                                    <div className="table-responsive">
                                        <Table className="table-centered table-vertical table-nowrap">
                                            <tbody>
                                                {tableData}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <hr />
                                </CardBody>
                            </Card>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(MyApplications)


