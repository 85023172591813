import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, FormGroup, Col, Row, Card, Input, Button } from 'reactstrap';

import axios from 'axios'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'

import '../../assets/css/slim.css'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class ApplicationNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Application Notes", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            notes: []
            // username: ""
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Application Notes", this.state.breadcrumbItems);
        // const result = getLoggedInUser() 
        // console.log("Logged In User");
        // console.log(result.username)
        // this.setState({username: result.username})

        const applicationId = this.props.match.params.id

        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get(`${BASEAPI}/lenders/${lenderId}/applications/${applicationId}/notes`, { headers: {"Authorization" : `Bearer ${token}`} }).then(results => {
            console.log(results)
            const notes = results.data.apiData
            this.setState({notes: notes.reverse()})
        })
    }

    formatTime(gd){
        const date = new Date(gd)
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const isPM = (hours >= 12)

        return (isPM ? hours-12 : hours) + ":" + minutes + " " + (isPM ? "pm" : "am")
    }

    render() {
        return (
            <React.Fragment>

                {/* <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Application Notes</h4>
                                <p className="card-title-desc">
                                    Coming Soon!
                                </p>
                            
                                <ul>
                                {
                                    this.state.notes.map(note => (
                                        <li>
                                            {note.noteContent}
                                        </li>
                                    ))
                                }
                                </ul>
                                </CardBody>
                                </Card></Col></Row> */}

           
               
               <div class="row row-sm row-timeline">
          <div class="col-lg-12">

            <div class="card pd-30">
              <div class="timeline-group">
                <div class="timeline-item timeline-day">
                  {/* <div class="timeline-time">&nbsp;</div> */}
                  <div class="timeline-body">
                    <p class="timeline-date">Today</p>
                  </div>
                </div>

                {
                    this.state.notes.map(note => (

                        <div class="timeline-item timeline-day">
                            {/* <div class="timeline-time">{this.formatTime(note.dateCreated)}</div> */}
                            <div class="timeline-body">
                                <p class="timeline-title">Note</p>
                                <p class="timeline-author"><a href="">{note.authorName}</a></p>
                                <p class="timeline-text">{note.noteContent}</p>
                                {/* <Button color="primary" className="w-md waves-effect waves-light" type="submit">View Application</Button> */}
                            </div>
                        </div>
                    ))
                
                }
              </div>
            </div>

          </div>
          </div>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(ApplicationNotes)