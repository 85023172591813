import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, Media } from 'reactstrap';

import { getLoggedInUser } from '../../../helpers/authUtils'
import axios from 'axios'

//Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { config } from '../../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class NotificationMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications : [
                { id : 1, icon : "mdi-cart-outline", title : "New Loan Application", desc : "A new loan application has been assigned to you." },
                { id : 2, icon : "mdi-message", title : "New Message Received", desc : "You have 18 unread messages." },
                { id : 3, icon : "mdi-glass-cocktail", title : "Monthly Reports Available", desc : "Reports for September 2020 are available." },
                { id : 4, icon : "mdi-cart-outline", title : "New Loan Application", desc : "A new loan application has been assigned to you." },
                { id : 5, icon : "mdi-message", title : "New Message Received", desc : "You have 17 unread messages." },
            ],
            numNotifications: 25,
            menu: false,
            pollingCount: 0,
            interval: 10000
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.interval = setInterval(this.tick, this.state.interval)
        const result = getLoggedInUser()
        const token = result.token 
        const lenderId = result.lenderId
        const userId = result._id

        axios.get(`${BASEAPI}/lenders/${lenderId}/employees/${userId}/notifications`, { headers: {"Authorization" : `Bearer ${token}` }}).then(result => {
            console.log('Notifications Menu: ' + result)
            this.setState({numNotifications : result.data.apiData.length})
            //this.setState({notifications: result.data.apiData})
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.interval != this.state.interval) {
            clearInterval(this.interval)
            this.interval = setInterval(this.tick, this.state.interval )
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    tick = () => {
        this.setState({pollingCount: this.state.pollingCount + 1})
        //console.log(`Tick! ${this.state.pollingCount}`)
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    render() {
        return (
            <React.Fragment>
                    <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block ml-1">
                        <DropdownToggle
                            className="btn header-item noti-icon waves-effect"
                            tag="button"
                            id="page-header-notifications-dropdown"
                        >
                            <i className="ti-bell"></i>
                            <span className="badge badge-danger badge-pill">{this.numNotification}</span>
                        </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-lg p-0">
                                <div className="p-3">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h5 className="m-0"> Notifications ({this.state.numNotifications}) </h5>
                                        </Col>
                                    </Row>
                                </div>
                                <PerfectScrollbar style={{height : "230px"}}>
                                    {
                                        this.state.notifications.map((notification, key) =>
                                            <Link key={key} to="/notification-detail" className="text-reset notification-item">
                                                <Media>
                                                    <div className="avatar-xs mr-3">
                                                        <span className="avatar-title border-success rounded-circle ">
                                                            <i className={"mdi " + notification.icon}></i>
                                                        </span>
                                                    </div>
                                                    <Media body>
                                                        <h6 className="mt-0 mb-1">{notification.title}</h6>
                                                        <div className="text-muted">
                                                            <p className="mb-1">{notification.desc}</p>
                                                        </div>
                                                    </Media>
                                                </Media>
                                            </Link>
                                        )
                                    } 
                            </PerfectScrollbar>
                            <div className="p-2 border-top">
                                <Link className="btn btn-sm btn-link font-size-14 btn-block text-center" to="/notifications">
                                    View all
                                </Link>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
            </React.Fragment>
        );
    }
}

export default NotificationMenu;