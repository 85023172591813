import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, CardHeader, CardTitle, FormGroup, Col, Row, Card, Input, Button, Table, DropdownButton, DropdownToggle, DropdownItem, ButtonDropdown, DropdownMenu } from 'reactstrap';

import axios from 'axios'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class ApplicationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Application List", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            dropdownOpen: false,
            openApplications: [],
            closedApplications: []
            // username: ""
        }
    } 

    toggle(){ 
        const current = this.state.dropdownOpen
        this.setState({dropdownOpen : !current}) 
    }
    
    daysAgo(gd) {
        const today = new Date()
        const givenDate = new Date(gd)
        const diff = today.getTime() - givenDate.getTime()
        const days = Math.floor(diff / (1000 * 3600 * 24))
        
        const monthName = givenDate.toLocaleString('default', {month: 'long'})
        const givenDateFormatted = monthName + " " + givenDate.getDate() + ", " + givenDate.getFullYear()

        return givenDateFormatted + " (" + days + " days ago)"
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Application List", this.state.breadcrumbItems);
        // const result = getLoggedInUser() 
        // console.log("Logged In User");
        // console.log(result.username)
        // this.setState({username: result.username})

        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId
        axios.get(`${BASEAPI}/lenders/${lenderId}/applications`, { headers: {"Authorization" : `Bearer ${token}`} }).then(results => {
            console.log(results)
            this.setState({closedApplications: results.data.apiData.filter(application => application.status == "CLOSED")})
            this.setState({openApplications: results.data.apiData.filter(application => application.status != "CLOSED")})
        })
    }

    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader><CardTitle>Open Applications</CardTitle></CardHeader>
                            <CardBody>

                                    <div className="table-responsive">
                                        <Table className="table mb-0">

                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Company</th>
                                                    <th>Location</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Assignment</th>
                                                    <th>View</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.openApplications.map(row => {
                                                    return(
                                                        <tr>
                                                            <td></td>
                                                            <td>{`${row.personalFirstName} ${row.personalLastName}`}</td>
                                                            <td>{row.businessName}</td>
                                                            <td>{`${row.businessAddress.city}, ${row.businessAddress.state}`}</td>
                                                            <td>{this.daysAgo(row.loanDateApplied)}</td>
                                                            <td>{row.status}</td>
                                                            <td>{`unassigned`}</td>
                                                            <td><a href={`/application/${row._id}`}>View Application</a></td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>                                               
                                        </Table>
                                    </div>
                                </CardBody>
                                </Card></Col></Row>

                                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader><CardTitle>Recently Closed Applications</CardTitle></CardHeader>
                            <CardBody>

                                    <div className="table-responsive">
                                        <Table className="table mb-0">

                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Company</th>
                                                    <th>Location</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Assignment</th>
                                                    <th>View</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.closedApplications.map(row => {
                                                    return(
                                                        <tr>
                                                            <td></td>
                                                            <td>{`${row.personalFirstName} ${row.personalLastName}`}</td>
                                                            <td>{row.businessName}</td>
                                                            <td>{`${row.businessAddress.city}, ${row.businessAddress.state}`}</td>
                                                            <td>{this.daysAgo(row.loanDateApplied)}</td>
                                                            <td>{row.status}</td>
                                                            <td>{`unassigned`}</td>
                                                            <td><a href={`/application/${row._id}`}>View Application</a></td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>                                               
                                        </Table>
                                    </div>
                                </CardBody>
                                </Card></Col></Row>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(ApplicationList)