import React, { Component, useEffect, useState } from 'react';
import { connect } from "react-redux";
import axios from 'axios'

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Row, Col, Card, CardBody, UncontrolledAlert, FormGroup, Input, Label, Button, CardHeader, CardTitle } from 'reactstrap'
import { AvForm, AvField, AvTextarea, AvInput } from "availity-reactstrap-validation";

import CBPlaidLink from '../../component/CBPlaidLink'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { config } from '../../config'

const steps = [
    'Personal Information',
    'Home Address',
    'Business Information',
    'Business Address',
    'Supporting Documentation',
    'Other Information'
]

const stepContent = [
    'Please enter your personal information below',
    'Please enter your home address',
    'Please enter your business information below',
    'Please enter your business address below',
    'Please upload your supporting documentation below',
    'Please provide the following information'
]

const API_URL = config.url.API_URL
const BASEAPI = `${API_URL}/api/v1`

class MyData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "My Data", link : "#" },
                // { title : "Blank page", link : "#" },
            ],

            activeStep: 0,

            borrowerId: "",
            myDataStatus: "NEW",
            myDataLastUpdated: "",
            firstName: "",
            middleName: "",
            lastName: "",
            perAddrLine1: "",
            perAddrLine2: "",
            perAddrCity: "",
            perAddrState: "",
            perAddrZipcode: "",
            perEmailAddr: "",
            perPhoneNo: "",
            dob: "",
            ssn: "",
            govID: "",
            perTaxes1: "",
            perTaxes2: "",
            bizName: "",
            bizAddrLine1: "",
            bizAddrLine2: "",
            bizAddrCity: "",
            bizAddrState: "",
            bizAddrZipcode: "",
            bizPhoneNo: "",
            dateEstablished: "",
            ein: "",
            duns: "",
            website: "",
            plaidStatus: "",
            plaidDateConnected: "",
            articlesOfInc: "",
            bizTaxes1: "",
            bizTaxes2: "",
            lastUpdated: ""
        }
    } 

    componentDidMount() {
        this.props.setBreadcrumbItems("My Data", this.state.breadcrumbItems);
        const myId = JSON.parse(localStorage.getItem('user'))._id
        this.setState({ borrowerId: myId })
        console.log(myId)
        axios.get(`${BASEAPI}/data/${myId}`).then(result => {
            const apiData = result.data.apiData
            if (apiData == null) {
                console.log("No data found - setting myDataStatus to NEW")
                this.setState({ myDataStatus: "NEW" })
            } else {
                console.log("Data found - setting myDataStatus to UPDATE")
                this.setState({ myDataStatus: "UPDATE" })
                this.setState({ 
                    firstName: apiData.personal.firstName,
                    middleName: apiData.personal.middleName,
                    lastName: apiData.personal.lastName,
                    ssn: apiData.personal.ssn,
                    dob: apiData.personal.dob,
                    perAddrLine1: apiData.personal.address.line1,
                    perAddrLine2: apiData.personal.address.line2,
                    perAddrLine3: apiData.personal.address.line3,
                    perAddrCity: apiData.personal.address.city,
                    perAddrState: apiData.personal.address.state,
                    perAddrZipcode: apiData.personal.address.zipcode,
                    perAddrCountry: apiData.personal.address.country,
                    perPhoneNo: apiData.personal.contact.phoneNo,
                    perEmailAddr: apiData.personal.contact.email,

                    bizName: apiData.business.businessName,
                    bizAddrLine1: apiData.business.address.line1,
                    bizAddrLine2: apiData.business.address.line2,
                    bizAddrLine3: apiData.business.address.line3,
                    bizAddrCity: apiData.business.address.city,
                    bizAddrState: apiData.business.address.state,
                    bizAddrZipcode: apiData.business.address.zipcode,
                    bizAddrCountry: apiData.business.address.country,
                    bizPhoneNo: apiData.business.contact.phoneNo,
                    ein: apiData.business.ein, 
                })
            }
            // set state and that there was already data
        })
        .catch(error => {
            
        })
    }

    handleNext = () => {
        const current = this.state.activeStep
        this.setState({activeStep: current + 1})
        //this.state.activeStep = this.state.activeStep + 1
        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
    handleBack = () => {
        const current = this.state.activeStep
        this.setState({activeStep: current - 1})
        //this.state.activeStep = this.sstateactiveStep - 1
        //setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

    handleReset = () => {
        this.setState({activeStep: 0})
    };

    handleFormSubmit = () => {
        alert('Data submitted' + this.state.firstName)
        const payload = {
            'borrowerId': this.state.borrowerId,
            'personal': {
                'firstName': this.state.firstName,
                'middleName': this.state.middleName,
                'lastName': this.state.lastName,
                'dob': this.state.dob,
                'ssn': this.state.ssn,
                'address': {
                    'line1': this.state.perAddrLine1,
                    'line2': this.state.perAddrLine2,
                    'line3': "",
                    'city': this.state.perAddrCity,
                    'state': this.state.perAddrState,
                    'zipcode': this.state.perAddrZipcode,
                    'country': ""
                },
                'contact': {
                    'phoneNo': this.state.perPhoneNo,
                    'mobileNo': "",
                    'email': this.state.perEmailAddr
                }
            },
            'business': {
                'businessName': this.state.bizName,
                'ein': this.state.ein,
                'address': {
                    'line1': this.state.bizAddrLine1,
                    'line2': this.state.bizAddrLine2,
                    'line3': "",
                    'city': this.state.bizAddrCity,
                    'state': this.state.bizAddrState,
                    'zipcode': this.state.bizAddrZipcode,
                    'country': ""
                },
                'contact': {
                    'phoneNo': this.state.bizPhoneNo,
                    'mobileNo': "",
                    'email': ""
                },
                'website': "",
                'dateEstablished': this.state.dateEstablished
            },
            'uploads': {}
        }

        if (this.state.myDataStatus == 'NEW') {
            axios.post(`${BASEAPI}/data`,payload).then(result => {
                console.log(result)
                alert('Thank you for submitting your application')
            })
            .catch(error => {
                alert('There was a problem submitting your updates')
            })
        } else {
            alert('Updates not implemented')
        } 
    }

    render() {
        return (
            <React.Fragment>
                { (this.state.myDataStatus == "NEW") ? (
                <Row>
                    <Col xs="12">
                        <UncontrolledAlert color="success">
                            <strong>Welcome!</strong> Please complete the forms below so that we can help you apply for loans.
                        </UncontrolledAlert>
                    </Col>
                </Row>
                ) : ( <Row></Row>) }
                {/* <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <AvForm onValidSubmit={this.handleFormSubmit}>
                                <h4 className="card-title">My Info</h4>
                                <p className="card-title-desc">
                                    Below is the data that you have provided that we use when you apply for loans. Please provide as much information as you can
                                    as this will improve the turnaround time in getting a decision on your application.
                                </p>
                                <hr />
                                <Row xs="12">
                                    <Col xs="6">
                                        <h4 className="card-title">Personal Data</h4>
                                        <p className="card-title-desc">Lorem ipsum...</p>
                                    </Col>
                                    <Col xs="6">
                                        <h4 className="card-title">Business Data</h4>
                                        <p className="card-title-desc">Lorem ipsum...</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Full Name</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter First Name" 
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.firstName} 
                                                    value={this.state.firstName}
                                                    placeholder="First Name" 
                                                    id="firstName"
                                                    name="firstName" 
                                                    onChange={e => this.setState({firstName: e.target.value})} 
                                                    validate={{ required: { value: true }}} 
                                                />
                                                <AvField 
                                                    errorMessage="Enter Middle Name" 
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.middleName} 
                                                    value={this.state.middleName} 
                                                    placeholder="Middle Name" 
                                                    id="middleName" 
                                                    name="middleName" 
                                                    onChange={e => this.setState({middleName: e.target.value})} 
                                                    validate={{ required: { value: false }}} 
                                                />
                                                <AvField 
                                                    errorMessage="Enter Last Name"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.lastName} 
                                                    value={this.state.lastName} 
                                                    placeholder="Last Name" 
                                                    id="lastName" 
                                                    name="lastName" 
                                                    onChange={e => this.setState({lastName: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Business Name</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter Business Name"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizName} 
                                                    value={this.state.bizName} 
                                                    placeholder="Business Name" 
                                                    id="bizName" 
                                                    name="bizName" 
                                                    onChange={e => this.setState({bizName: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Address</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter Address"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.perAddrLine1} 
                                                    value={this.state.perAddrLine1} 
                                                    placeholder="Address" 
                                                    id="perAddrLine1" 
                                                    name="perAddrLine1" 
                                                    onChange={e => this.setState({perAddrLine1: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                                <AvField 
                                                    errorMessage="Enter Address"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.perAddrLine2} 
                                                    value={this.state.perAddrLine2} 
                                                    placeholder="Apt No." 
                                                    id="perAddrLine2"
                                                    name="perAddrLine2" 
                                                    onChange={e => this.setState({perAddrLine2: e.target.value})} 
                                                    validate={{ required: { value: false }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">City</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter City"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.perAddrCity}
                                                    value={this.state.perAddrCity} 
                                                    placeholder="City" 
                                                    id="perAddrCity"
                                                    name="perAddrCity" 
                                                    onChange={e => this.setState({perAddrCity: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">State</Label>
                                            <Col sm="9">
                                                <AvField
                                                    errorMessage="Enter State" 
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.perAddrState} 
                                                    value={this.state.perAddrState} 
                                                    placeholder="State" 
                                                    id="perAddrState" 
                                                    name="perAddrState" 
                                                    onChange={e => this.setState({perAddrState: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Zipcode</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter Zipcode"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.perAddrZipcode}
                                                    value={this.state.perAddrZipcode} 
                                                    placeholder="Zipcode" 
                                                    id="perAddrZipcode" 
                                                    name="perAddrZipcode" 
                                                    onChange={e => this.setState({perAddrZipcode: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                                <hr />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Address</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter Address"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrLine1}
                                                    value={this.state.bizAddrLine1} 
                                                    placeholder="Address" 
                                                    id="bizAddrLine1" 
                                                    name="bizAddrLine1" 
                                                    onChange={e => this.setState({bizAddrLine1: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                                <AvField 
                                                    errorMessage="Enter Address"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrLine2}
                                                    value={this.state.bizAddrLine2} 
                                                    placeholder="Suite No." 
                                                    id="bizAddrLine2"
                                                    name="bizAddrLine2"
                                                    onChange={e => this.setState({bizAddrLine2: e.target.value})} 
                                                    validate={{ required: { value: false }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">City</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter City"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrCity} 
                                                    value={this.state.bizAddrCity} 
                                                    placeholder="City" 
                                                    id="bizAddrCity"
                                                    name="bizAddrCity" 
                                                    onChange={e => this.setState({bizAddrCity: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">State</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter State"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrState}
                                                    value={this.state.bizAddrState} 
                                                    placeholder="State" 
                                                    id="bizAddrState"
                                                    name="bizAddrState" 
                                                    onChange={e => this.setState({bizAddrState: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Zipcode</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter Zipcode"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrZipcode} 
                                                    value={this.state.bizAddrZipcode} 
                                                    placeholder="Zipcode" 
                                                    id="bizAddrZipcode"
                                                    name="bizAddrZipcode" 
                                                    onChange={e => this.setState({bizAddrZipcode: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                                <hr />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Contact Info</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter Phone No"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.perPhoneNo} 
                                                    value={this.state.perPhoneNo} 
                                                    placeholder="Phone No" 
                                                    id="perPhoneNo" 
                                                    name="perPhoneNo" 
                                                    onChange={e => this.setState({perPhoneNo: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                                <AvField 
                                                    errorMessage="Enter Email"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.perEmailAddr}
                                                    value={this.state.perEmailAddr} 
                                                    placeholder="Email Address" 
                                                    id="perEmailAddr"
                                                    name="perEmailAddr"  
                                                    onChange={e => this.setState({perEmailAddr: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />   
                                                <hr />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Contact Info</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter Phone No"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizPhoneNo}
                                                    value={this.state.bizPhoneNo} 
                                                    placeholder="Phone No" 
                                                    id="bizPhoneNo"
                                                    name="bizPhoneNo" 
                                                    onChange={e => this.setState({bizPhoneNo: e.target.value})}
                                                    validate={{ required: { value: true }}} 
                                                />
                                                <hr />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Date of Birth</Label>
                                            <Col sm="9">
                                            <Input className="form-control" type="date" defaultValue={this.state.dob} placeholder="Date of Birth" id="dob" onChange={e => this.setState({dob: e.target.value})} />
                                                <hr />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Date Established</Label>
                                            <Col sm="9">
                                            <Input className="form-control" type="date" defaultValue={this.state.dateEstablished} placeholder="Date Established" id="dateEstablished" onChange={e => this.setState({dateEstablished: e.target.value})} />
                                                <hr />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">SSN</Label>
                                            <Col sm="9">
                                                <Input className="form-control" type="text" defaultValue={this.state.ssn} placeholder="SSN" id="ssn" onChange={e => this.setState({ssn: e.target.value})} />
                                                <hr />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">EIN</Label>
                                            <Col sm="9">
                                                <Input className="form-control" type="text" defaultValue={this.state.ein} placeholder="EIN" id="ein" onChange={e => this.setState({ein: e.target.value})} />
                                                <hr />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">

                                    </Col>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Plaid</Label>
                                            <Col sm="9">
                                                <Button type="submit" color="primary" className="waves-effect waves-light mr-1">
                                                    Link Plaid
                                                </Button>
                                                <CBPlaidLink />
                                            </Col>
                                        </FormGroup>     
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Goverment ID</Label>
                                            <Col sm="9">
                                                <input type="file" className="filestyle" data-buttonname="btn-secondary" id="govId" onChange={e => this.setState({govId: e.target.value})} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Taxes (most recent)</Label>
                                            <Col sm="4">
                                                <input type="file" className="filestyle" data-buttonname="btn-secondary" id="perTaxes1" onChange={e => this.setState({perTaxes1: e.target.value})} />
                                            </Col>
                                            <Col sm="5">
                                                <input type="file" className="filestyle" data-buttonname="btn-secondary" id="perTaxes2" onChange={e => this.setState({perTaxes2: e.target.value})} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Articles of Incorporation</Label>
                                            <Col sm="9">
                                                <input type="file" className="filestyle" data-buttonname="btn-secondary" id="articlesOfInc" onChange={e => this.setState({articlesOfInc: e.target.value})} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Taxes (most recent)</Label>
                                            <Col sm="4">
                                                <input type="file" className="filestyle" data-buttonname="btn-secondary" id="bizTaxes1" onChange={e => this.setState({bizTaxes1: e.target.value})} />
                                            </Col>
                                            <Col sm="5">
                                                <input type="file" className="filestyle" data-buttonname="btn-secondary" id="bizTaxes2" onChange={e => this.setState({bizTaxess2: e.target.value})} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs="12">
                                        <div className="form-group mb-0">
                                            <div>
                                                <Button type="submit" color="primary" className="waves-effect waves-light mr-1" >
                                                    Submit
                                                </Button>
                                                <Button type="reset" color="secondary" className="waves-effect">
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <br /><p className="card-title-desc"><b>Last Updated:</b> {this.state.lastUpdated == "" ? "Not yet saved!" : this.state.lastUpdated }</p>
                                    </Col>
                                </Row>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
 */}

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <CardTitle>My Information</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <AvForm>
                                    <Stepper activeStep={this.state.activeStep} orientation='vertical'>
                                        <Step key='s1'>
                                            <StepLabel>Personal Information</StepLabel>
                                            <StepContent>
                                                <div>
                                                    <div>
                                                        <Col xs="6">
                                                            <FormGroup row>
                                                                <Label for="example-text-input" className="col-sm-3 col-form-label">Full Name</Label>
                                                                <Col sm="9">
                                                                    <AvField 
                                                                        errorMessage="Enter First Name" 
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.firstName} 
                                                                        value={this.state.firstName}
                                                                        placeholder="First Name" 
                                                                        id="firstName"
                                                                        name="firstName" 
                                                                        onChange={e => this.setState({firstName: e.target.value})} 
                                                                        validate={{ required: { value: true }}} 
                                                                    />
                                                                    <AvField 
                                                                        errorMessage="Enter Middle Name" 
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.middleName} 
                                                                        value={this.state.middleName} 
                                                                        placeholder="Middle Name" 
                                                                        id="middleName" 
                                                                        name="middleName" 
                                                                        onChange={e => this.setState({middleName: e.target.value})} 
                                                                        validate={{ required: { value: false }}} 
                                                                    />
                                                                    <AvField 
                                                                        errorMessage="Enter Last Name"
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.lastName} 
                                                                        value={this.state.lastName} 
                                                                        placeholder="Last Name" 
                                                                        id="lastName" 
                                                                        name="lastName" 
                                                                        onChange={e => this.setState({lastName: e.target.value})} 
                                                                        validate={{ required: { value: true }}}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label for="example-text-input" className="col-sm-3 col-form-label">Date of Birth</Label>
                                                                <Col sm="9">
                                                                <Input className="form-control" type="date" defaultValue={this.state.dob} placeholder="Date of Birth" id="dob" onChange={e => this.setState({dob: e.target.value})} />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label for="example-text-input" className="col-sm-3 col-form-label">SSN</Label>
                                                                <Col sm="9">
                                                                    <Input className="form-control" type="text" defaultValue={this.state.ssn} placeholder="SSN" id="ssn" onChange={e => this.setState({ssn: e.target.value})} />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label for="example-text-input" className="col-sm-3 col-form-label">Contact Info</Label>
                                                                <Col sm="9">
                                                                    <AvField 
                                                                        errorMessage="Enter Phone No"
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.perPhoneNo} 
                                                                        value={this.state.perPhoneNo} 
                                                                        placeholder="Phone No" 
                                                                        id="perPhoneNo" 
                                                                        name="perPhoneNo" 
                                                                        onChange={e => this.setState({perPhoneNo: e.target.value})} 
                                                                        validate={{ required: { value: true }}}
                                                                    />
                                                                    <AvField 
                                                                        errorMessage="Enter Email"
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.perEmailAddr}
                                                                        value={this.state.perEmailAddr} 
                                                                        placeholder="Email Address" 
                                                                        id="perEmailAddr"
                                                                        name="perEmailAddr"  
                                                                        onChange={e => this.setState({perEmailAddr: e.target.value})} 
                                                                        validate={{ required: { value: true }}}
                                                                    />   
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </div>
                                                    <div>
                                                        <Button disabled={this.setState.activeStep === 0} onClick={this.handleBack}>Back</Button>
                                                        <Button variant="contained" color="primary" onClick={this.handleNext}>Next</Button>
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>
                                        <Step key='s2'>
                                            <StepLabel>Personal Information (cont.)</StepLabel>
                                            <StepContent>
                                                <div>
                                                    <div>
                                                        <Col xs="6">
                                                            <FormGroup row>
                                                                <Label for="example-text-input" className="col-sm-3 col-form-label">Address</Label>
                                                                <Col sm="9">
                                                                    <AvField 
                                                                        errorMessage="Enter Address"
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.perAddrLine1} 
                                                                        value={this.state.perAddrLine1} 
                                                                        placeholder="Address" 
                                                                        id="perAddrLine1" 
                                                                        name="perAddrLine1" 
                                                                        onChange={e => this.setState({perAddrLine1: e.target.value})} 
                                                                        validate={{ required: { value: true }}}
                                                                    />
                                                                    <AvField 
                                                                        errorMessage="Enter Address"
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.perAddrLine2} 
                                                                        value={this.state.perAddrLine2} 
                                                                        placeholder="Apt No." 
                                                                        id="perAddrLine2"
                                                                        name="perAddrLine2" 
                                                                        onChange={e => this.setState({perAddrLine2: e.target.value})} 
                                                                        validate={{ required: { value: false }}}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label for="example-text-input" className="col-sm-3 col-form-label">City</Label>
                                                                <Col sm="9">
                                                                    <AvField 
                                                                        errorMessage="Enter City"
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.perAddrCity}
                                                                        value={this.state.perAddrCity} 
                                                                        placeholder="City" 
                                                                        id="perAddrCity"
                                                                        name="perAddrCity" 
                                                                        onChange={e => this.setState({perAddrCity: e.target.value})} 
                                                                        validate={{ required: { value: true }}}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label for="example-text-input" className="col-sm-3 col-form-label">State</Label>
                                                                <Col sm="9">
                                                                    <AvField
                                                                        errorMessage="Enter State" 
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.perAddrState} 
                                                                        value={this.state.perAddrState} 
                                                                        placeholder="State" 
                                                                        id="perAddrState" 
                                                                        name="perAddrState" 
                                                                        onChange={e => this.setState({perAddrState: e.target.value})} 
                                                                        validate={{ required: { value: true }}}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label for="example-text-input" className="col-sm-3 col-form-label">Zipcode</Label>
                                                                <Col sm="9">
                                                                    <AvField 
                                                                        errorMessage="Enter Zipcode"
                                                                        className="form-control" 
                                                                        type="text" 
                                                                        defaultValue={this.state.perAddrZipcode}
                                                                        value={this.state.perAddrZipcode} 
                                                                        placeholder="Zipcode" 
                                                                        id="perAddrZipcode" 
                                                                        name="perAddrZipcode" 
                                                                        onChange={e => this.setState({perAddrZipcode: e.target.value})} 
                                                                        validate={{ required: { value: true }}}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </div>
                                                    <div>
                                                        <Button disabled={this.setState.activeStep === 0} onClick={this.handleBack}>Back</Button>
                                                        <Button variant="contained" color="primary" onClick={this.handleNext}>Next</Button>
                                                    </div>
                                                </div>
                                            </StepContent>
                                        </Step>
                                        <Step key='s3'>
                                            <StepLabel>Business Information</StepLabel>
                                            <StepContent>
                                            <div>
                                                <div>
                                                    <Col xs='6'>
                                                        <FormGroup row>
                                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Business Name</Label>
                                                            <Col sm="9">
                                                                <AvField 
                                                                    errorMessage="Enter Business Name"
                                                                    className="form-control" 
                                                                    type="text" 
                                                                    defaultValue={this.state.bizName} 
                                                                    value={this.state.bizName} 
                                                                    placeholder="Business Name" 
                                                                    id="bizName" 
                                                                    name="bizName" 
                                                                    onChange={e => this.setState({bizName: e.target.value})} 
                                                                    validate={{ required: { value: true }}}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Date Established</Label>
                                                            <Col sm="9">
                                                            <Input className="form-control" type="date" defaultValue={this.state.dateEstablished} placeholder="Date Established" id="dateEstablished" onChange={e => this.setState({dateEstablished: e.target.value})} />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="example-text-input" className="col-sm-3 col-form-label">EIN</Label>
                                                            <Col sm="9">
                                                                <Input className="form-control" type="text" defaultValue={this.state.ein} placeholder="EIN" id="ein" onChange={e => this.setState({ein: e.target.value})} />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="example-text-input" className="col-sm-3 col-form-label">DUNS Number</Label>
                                                            <Col sm="9">
                                                                <Input className="form-control" type="text" defaultValue={this.state.duns} placeholder="DUNS Number" id="duns" onChange={e => this.setState({duns: e.target.value})} />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Contact Info</Label>
                                                            <Col sm="9">
                                                                <AvField 
                                                                    errorMessage="Enter Phone No"
                                                                    className="form-control" 
                                                                    type="text" 
                                                                    defaultValue={this.state.bizPhoneNo}
                                                                    value={this.state.bizPhoneNo} 
                                                                    placeholder="Phone No" 
                                                                    id="bizPhoneNo"
                                                                    name="bizPhoneNo" 
                                                                    onChange={e => this.setState({bizPhoneNo: e.target.value})}
                                                                    validate={{ required: { value: true }}} 
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Website</Label>
                                                            <Col sm="9">
                                                                <Input className="form-control" type="text" defaultValue={this.state.website} placeholder="Website URL" id="website" onChange={e => this.setState({website: e.target.value})} />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                                    <div>
                                                    <Button
                                                        disabled={this.setState.activeStep === 0}
                                                        onClick={this.handleBack}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleNext}
                                                    >
                                                        {/* {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'} */}
                                                        Next
                                                    </Button>
                                                    </div>
                                                </div>
                                            </StepContent>

                                        </Step>
                                        <Step key='s4'>
                                            <StepLabel>Business Information (cont.)</StepLabel>
                                            <StepContent>
                                            <div>
                                                <div>
                                                <Col xs="6">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Address</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter Address"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrLine1}
                                                    value={this.state.bizAddrLine1} 
                                                    placeholder="Address" 
                                                    id="bizAddrLine1" 
                                                    name="bizAddrLine1" 
                                                    onChange={e => this.setState({bizAddrLine1: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                                <AvField 
                                                    errorMessage="Enter Address"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrLine2}
                                                    value={this.state.bizAddrLine2} 
                                                    placeholder="Suite No." 
                                                    id="bizAddrLine2"
                                                    name="bizAddrLine2"
                                                    onChange={e => this.setState({bizAddrLine2: e.target.value})} 
                                                    validate={{ required: { value: false }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">City</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter City"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrCity} 
                                                    value={this.state.bizAddrCity} 
                                                    placeholder="City" 
                                                    id="bizAddrCity"
                                                    name="bizAddrCity" 
                                                    onChange={e => this.setState({bizAddrCity: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">State</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter State"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrState}
                                                    value={this.state.bizAddrState} 
                                                    placeholder="State" 
                                                    id="bizAddrState"
                                                    name="bizAddrState" 
                                                    onChange={e => this.setState({bizAddrState: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-sm-3 col-form-label">Zipcode</Label>
                                            <Col sm="9">
                                                <AvField 
                                                    errorMessage="Enter Zipcode"
                                                    className="form-control" 
                                                    type="text" 
                                                    defaultValue={this.state.bizAddrZipcode} 
                                                    value={this.state.bizAddrZipcode} 
                                                    placeholder="Zipcode" 
                                                    id="bizAddrZipcode"
                                                    name="bizAddrZipcode" 
                                                    onChange={e => this.setState({bizAddrZipcode: e.target.value})} 
                                                    validate={{ required: { value: true }}}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                                </div>
                                                    <div>
                                                    <Button
                                                        disabled={this.setState.activeStep === 0}
                                                        onClick={this.handleBack}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleNext}
                                                    >
                                                        {/* {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'} */}
                                                        Next
                                                    </Button>
                                                    </div>
                                                </div>
                                            </StepContent>

                                        </Step>
                                        <Step key='s5'>
                                            <StepLabel>Other...</StepLabel>
                                            <StepContent>
                                            <Typography>Intended for Document Uploads and Any Other Information</Typography>
                                            <div>
                                                    <div>
                                                    <Button
                                                        disabled={this.setState.activeStep === 0}
                                                        onClick={this.handleBack}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleNext}
                                                    >
                                                        {/* {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'} */}
                                                        Finish
                                                    </Button>
                                                    </div>
                                                </div>
                                            </StepContent>

                                        </Step>
                                    </Stepper>
                                    {this.state.activeStep === 5 && (
                                    <Paper square elevation={0}>
                                    <Typography>Congratulations! All steps are completed - you&apos;re finished</Typography>
                                    <Button onClick={this.handleReset}>
                                    Reset
                                    </Button>
                                    </Paper>
                                    )}
                                    

                                </AvForm>
                                

                            </CardBody>
                        </Card>
                    </Col>
                    
                </Row>

            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(MyData)