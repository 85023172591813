import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    CardFooter,
    CardBody, 
    Col,
    FormGroup, 
    Input,
    Row,
    Table
} from 'reactstrap'
import { Link } from 'react-router-dom'
import uuid from "uuid/v4";

import Board from 'react-trello'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


import { setBreadcrumbItems } from '../../store/actions'
import { getTest } from '../../services/testService'
import { getLoggedInUser } from '../../helpers/authUtils'
import myTeam from '../../pages/Demo/my-team';

import user2 from "../../assets/images/users/user-2.jpg";

import { config } from '../../config'

const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

const itemsFromBackend = [
    { id: uuid(), title: "Application #1", description: "The applicant wants money ..." },
    { id: uuid(), title: "Application #2", description: "The applicant wants money ..." },
    { id: uuid(), title: "Application #3", description: "The applicant wants money ..." },
    { id: uuid(), title: "Application #4", description: "The applicant wants money ..." },
    { id: uuid(), title: "Application #5", description: "The applicant wants money ..." },
    { id: uuid(), title: "Application #6", description: "The applicant wants money ..." },
    { id: uuid(), title: "Application #7", description: "The applicant wants money ..." }
  ];

class Pipeline extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Pipeline Management", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            username: "",
            // columns: {
            //     [uuid()]: {
            //       name: "New Applications",
            //       items: itemsFromBackend
            //     },
            //     [uuid()]: {
            //       name: "Joe",
            //       items: []
            //     },
            //     [uuid()]: {
            //         name: "Bob",
            //         items: []
            //     },
            //     [uuid()]: {
            //     name: "Sally",
            //     items: []
            //     }
            //   },
              columns: {
                ['new']: {
                  name: "New Applications",
                  items: itemsFromBackend
                },
                ['Joe']: {
                  name: "Joe",
                  items: []
                },
                ['Bob']: {
                    name: "Bob",
                    items: []
                },
                ['Sally']: {
                name: "Sally",
                items: []
                }
              },
            boardData: {
                "lanes": [
                    {
                      "id": "CONDITIONS",
                      "title": "Available Membership Conditions",
                      "label": "",
                      "cards": [
                        {
                          "id": "COND-002",
                          "title": "Relative is Member",
                          "label": "",
                          "body": "hello there!",
                          "description": "The applicant has an immediate relative who is a current member of the Credit Union."
                        },
                        {
                          "id": "COND-003",
                          "title": "Affiliate Member",
                          "label": "",
                          "description": "Applicant is part of an affiated company, community organization, or government agency."
                        },
                        {
                          "id": "COND-004",
                          "title": "Join Non-Profit",
                          "label": "",
                          "description": "The applicant must be willing to join a pre-approved or specified non-profit organization."
                        },
                        {
                            "id": "COND-005",
                            "title": "Employment",
                            "label": "",
                            "description": "The applicant must be a current or former employee, or a relative of a current of former employee, of the Credit Union."
                        },
                        {
                            "id": "COND-006",
                            "title": "City Residency",
                            "label": "",
                            "description": "The applicant must live, worship, or work in a specified city or cities."
                        },
                        {
                            "id": "COND-007",
                            "title": "State Residency",
                            "label": "",
                            "description": "The applicant must live, worship, or work in a specified state or states."
                        },
                        {
                            "id": "COND-008",
                            "title": "Employee of SEG",
                            "label": "",
                            "description": "The applicant must be a current or former member of a specified Select Employee Group (SEG)."
                        }
                      ]
                    },
                    {
                      "id": "SELECTED",
                      "title": "Membership Requirements",
                      "label": "",
                      "cards": [
                        {
                            "id": "COND-001",
                            "title": "Over 18",
                            "label": "",
                            "description": "The applicant must be 18 years or older at the time of the application."
                          }
                      ]
                    },
                  ]
            },
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Pipeline Management", this.state.breadcrumbItems);
        const result = getLoggedInUser() 
        console.log("Logged In User");
        console.log(result.username)
        this.setState({username: result.username})
    }

    onDragEnd(result, c) {
        if (!result.destination) return;
        const { source, destination } = result;
    
        if (source.droppableId !== destination.droppableId) {

            const sourceColumn = this.state.columns[source.droppableId];
            const destColumn = this.state.columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            var _data = [this.state.columns][0]
            _data[source.droppableId].items = sourceItems
            _data[destination.droppableId].items = destItems
            this.setState({_data})

        } else {

            const column = this.state.columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);

            var _data = [this.state.columns][0]
            _data[source.droppableId].items = copiedItems
            this.setState({_data})

        }

        // Axios - update state online
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Pipeline Management</h4>
                                <p className="card-title-desc">
                                    Manage your pipeline of new loan applications by assigning them to your team. Simply <b>drag</b> the application from the 'New Applications' column to
                                    team member of choice, where you can also re-order the list of applications.
                                </p>
                                {/* <Button onClick={async () => {await getTest()}}>Hello</Button>
                                <p>Username: {this.state.username}</p> */}
                                {/* <Link to="/notification-detail">Notification Detail</Link> */}

                                <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
                                {/* <div style={{ display: "flex", height: "100%" }}> */}
                                    <DragDropContext onDragEnd={result => this.onDragEnd(result, this.state.columns)}>
                                        {Object.entries(this.state.columns).map(([columnId, column], index) => {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} key={columnId}>
                                                    {/* {
                                                        column.name !== "New Applications" ? <img className="rounded-circle avatar-lg img-thumbnail" src={user2} alt="Generic placeholder"/> : ""
                                                    } */}
                                                    <h2>{column.name}</h2>
                                                    <div style={{ margin: 8 }}>
                                                        <Droppable droppableId={columnId} key={columnId}>
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                        background: snapshot.isDraggingOver
                                                                            ? "lightblue"
                                                                            : "lightgrey",
                                                                        padding: 10,
                                                                        width: 300,
                                                                        minHeight: 500
                                                                        }}
                                                                    >
                                                                        {column.items.map((item, index) => {
                                                                            return (
                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                    {(provided, snapshot) => {
                                                                                        return (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={{
                                                                                                    userSelect: "none",
                                                                                                    padding: 0,
                                                                                                    margin: "0 0 8px 0",
                                                                                                    minHeight: "50px",
                                                                                                    backgroundColor: snapshot.isDragging ? "#263B4A" : "#456C86",
                                                                                                    color: "black",
                                                                                                    ...provided.draggableProps.style
                                                                                                }}
                                                                                            >
                                                                                                <Card>
                                                                                                    {/* <CardHeader><CardTitle>{item.title}</CardTitle></CardHeader> */}
                                                                                                    <CardBody>
                                                                                                        <h5>{item.title}</h5>
                                                                                                        {item.description}
                                                                                                    </CardBody>
                                                                                                    <CardFooter>
                                                                                                        <Button type="button" color="primary" className="waves-effect waves-light" onClick={() => alert('view me!')}>View</Button>
                                                                                                        {/* <Button onClick={() => alert('view me!')}>View</Button> */}
                                                                                                    </CardFooter>
                                                                                                </Card>
                                                                                            </div>
                                                                                        );
                                                                                    }}
                                                                                </Draggable>
                                                                            );
                                                                        })}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </DragDropContext>
                                </div>

                                {/* <Board style={{backgroundColor: 'white'}} data={this.state.boardData} cardDraggable={true} laneDraggable={false} hideCardDeleteIcon={true} onCardClick={() => alert("Clicked")} />
                                <p class="ard-title-desc">
                                    Alternative to above - just a tabular solution...
                                </p>
                                <div className="table-responsive">
                                        <Table className="table mb-0">

                                            <thead>
                                                <tr>
                                                    <th>Select</th>
                                                    <th>Criteria</th>
                                                    <th>Description</th>
                                                    <th>Customize</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><center><Input type="checkbox" /></center></td>
                                                    <td>Over 18</td>
                                                    <td>The applicant must be 18 years or older at the time of the application.</td>
                                                    <td><Button color="secondary" size="sm">Customize</Button></td>
                                                </tr>
                                                <tr>
                                                    <td><center><Input type="checkbox" /></center></td>
                                                    <td>Relative is Member</td>
                                                    <td>The applicant has an immediate relative who is a current member of the Credit Union.</td>
                                                    <td><Button color="secondary" size="sm">Customize</Button></td>
                                                </tr>
                                                <tr>
                                                    <td><center><Input type="checkbox" /></center></td>
                                                    <td>Affiliate Member</td>
                                                    <td>Applicant is part of an affiated company, community organization, or government agency.</td>
                                                    <td><Button color="secondary" size="sm">Customize</Button></td>
                                                </tr>
                                                <tr>
                                                    <td><center><Input type="checkbox" /></center></td>
                                                    <td>Join Non-Profit</td>
                                                    <td>The applicant must be willing to join a pre-approved or specified non-profit organization.</td>
                                                    <td><Button color="secondary" size="sm">Customize</Button></td>
                                                </tr>
                                                <tr>
                                                    <td><center><Input type="checkbox" /></center></td>
                                                    <td>Employment</td>
                                                    <td>The applicant must be a current or former employee, or a relative of a current of former employee, of the Credit Union.</td>
                                                    <td><Button color="secondary" size="sm">Customize</Button></td>
                                                </tr>
                                                <tr>
                                                    <td><center><Input type="checkbox" /></center></td>
                                                    <td>City Residency</td>
                                                    <td>The applicant must live, worship, or work in a specified city or cities.</td>
                                                    <td><Button color="secondary" size="sm">Customize</Button></td>
                                                </tr>
                                                <tr>
                                                    <td><center><Input type="checkbox" /></center></td>
                                                    <td>State Residency</td>
                                                    <td>The applicant must live, worship, or work in a specified state or states.</td>
                                                    <td><Button color="secondary" size="sm">Customize</Button></td>
                                                </tr>
                                                <tr>
                                                    <td><center><Input type="checkbox" /></center></td>
                                                    <td>Employee of SEG</td>
                                                    <td>The applicant must be a current or former member of a specified Select Employee Group (SEG).</td>
                                                    <td><Button color="secondary" size="sm">Customize</Button></td>
                                                </tr>
                                            </tbody>                                               
                                        </Table>
                                    </div> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>                
            </React.Fragment>
        )
    }
}

export default connect(null, {setBreadcrumbItems})(Pipeline)