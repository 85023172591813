// import React from 'react'

// import { CIcon } from '@coreui/icons-react'
// import ChartLineSimple from '../../../views/Charts/ChartLineSimple'

import testData from './testData'

import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, CardHeader, CardFooter, Badge, Form, FormGroup, Col, Row, Card, Input, Button, Label } from 'reactstrap';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'


class ViewApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "View Application", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            // username: ""
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("View Application", this.state.breadcrumbItems);
        // const result = getLoggedInUser() 
        // console.log("Logged In User");
        // console.log(result.username)
        // this.setState({username: result.username})
    }

    render() {
        return (
            <React.Fragment>

                                <Row>
                <Col md="6">
                    <Card>
                        <CardHeader>Application Overview</CardHeader>
                        <CardBody>
                            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                <FormGroup row>
                                    <Col md="12">
                                        <Label>PERSONAL INFORMATION</Label>
                                    </Col>
                                </FormGroup>
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel>Static</CLabel>
                                    </CCol>
                                    <CCol xs="12" md="9">
                                        <p className="form-control-static">Username</p>
                                    </CCol>
                                </CFormGroup> */}
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Full Name</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Text" value={testData.personal.fullName} disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Social Security Number (SSN)</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Text" value={testData.personal.ssn} disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Address</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Text" value={testData.personal.address.line1} disabled />
                                        <Input id="text-input" name="text-input" placeholder="Text" value={testData.personal.address.line2} disabled />
                                        <Row>
                                            <Col md="7"><Input id="text-input" name="text-input" placeholder="City" value={testData.personal.address.city} disabled /></Col>
                                            <Col md="2"><Input id="text-input" name="text-input" placeholder="State" value={testData.personal.address.state} disabled /></Col>
                                            <Col md="3"><Input id="text-input" name="text-input" placeholder="Zipcode" value={testData.personal.address.zipcode} disabled /></Col>
                                        </Row>
                                        {/* <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.address.city} disabled />
                                        <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.address.state} disabled />
                                        <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.address.zipcode} disabled /> */}
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Telephone</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Text" value={testData.personal.telephone} disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Email Address</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Text" value={testData.personal.email} disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="12">
                                        <Label>BUSINESS INFORMATION</Label>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Business Name</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Full Name" value={testData.business.businessName} disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Industry</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Full Name" value="Retail" disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Employer Identification Number (EIN)</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Full Name" value={testData.business.ein} disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Address</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Address " value={testData.business.address.line1} disabled />
                                        <Input id="text-input" name="text-input" placeholder="" value={testData.business.address.line2} disabled />
                                        <Row>
                                            <Col md="7"><Input id="text-input" name="text-input" placeholder="City" value={testData.business.address.city} disabled /></Col>
                                            <Col md="2"><Input id="text-input" name="text-input" placeholder="State" value={testData.business.address.state} disabled /></Col>
                                            <Col md="3"><Input id="text-input" name="text-input" placeholder="Zipcode" value={testData.business.address.zipcode} disabled /></Col>
                                        </Row>
                                        {/* <CInput id="text-input" name="text-input" placeholder="City" value={testData.business.address.city} disabled />
                                        <CInput id="text-input" name="text-input" placeholder="State" value={testData.business.address.state} disabled />
                                        <CInput id="text-input" name="text-input" placeholder="Zipcode" value={testData.business.address.zipcode} disabled /> */}
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Telephone</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Text" value={testData.business.telephone} disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Date Established</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Text" value={testData.business.established} disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Website</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input id="text-input" name="text-input" placeholder="Text" value={testData.business.website} disabled />
                                        {/* <CFormText>This is a help text</CFormText> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="12">
                                        <Label>LOAN INFORMATION</Label>
                                    </Col>
                                </FormGroup>
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel htmlFor="email-input">Email Input</CLabel>
                                    </CCol>
                                    <CCol xs="12" md="9">
                                        <CInput type="email" id="email-input" name="email-input" placeholder="Enter Email" autoComplete="email"/>
                                        <CFormText className="help-block">Please enter your email</CFormText>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel htmlFor="password-input">Password</CLabel>
                                    </CCol>
                                    <CCol xs="12" md="9">
                                        <CInput type="password" id="password-input" name="password-input" placeholder="Password" autoComplete="new-password" />
                                        <CFormText className="help-block">Please enter a complex password</CFormText>
                                    </CCol>
                                </CFormGroup> */}
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="text-input">Loan Amount</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text-input" id="text-input" name="text-input" placeholder="Text" value="$25,000" disabled />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="date-input">Date Applied</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="date" id="date-input" name="date-input" placeholder="date" value="06/01/2020" disabled />
                                    </Col>
                                </FormGroup>
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel htmlFor="disabled-input">Disabled Input</CLabel>
                                    </CCol>
                                    <CCol xs="12" md="9">
                                        <CInput id="disabled-input" name="disabled-input" placeholder="Disabled" disabled />
                                    </CCol>
                                </CFormGroup> */}
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="textarea-input">Purpose of Loan</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input 
                                            name="textarea-input" 
                                            id="textarea-input" 
                                            rows="9"
                                            placeholder="Content..." 
                                            disabled
                                            value="Need money for a new marketing push."
                                        />
                                    </Col>
                                </FormGroup>
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel htmlFor="select">Select</CLabel>
                                    </CCol>
                                    <CCol xs="12" md="9">
                                        <CSelect custom name="select" id="select">
                                            <option value="0">Please select</option>
                                            <option value="1">Option #1</option>
                                            <option value="2">Option #2</option>
                                            <option value="3">Option #3</option>
                                        </CSelect>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel htmlFor="selectLg">Select Large</CLabel>
                                    </CCol>
                                    <CCol xs="12" md="9" size="lg">
                                        <CSelect custom size="lg" name="selectLg" id="selectLg">
                                            <option value="0">Please select</option>
                                            <option value="1">Option #1</option>
                                            <option value="2">Option #2</option>
                                            <option value="3">Option #3</option>
                                        </CSelect>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel htmlFor="selectSm">Select Small</CLabel>
                                    </CCol>
                                    <CCol xs="12" md="9">
                                        <CSelect custom size="sm" name="selectSm" id="SelectLm">
                                            <option value="0">Please select</option>
                                            <option value="1">Option #1</option>
                                            <option value="2">Option #2</option>
                                            <option value="3">Option #3</option>
                                            <option value="4">Option #4</option>
                                            <option value="5">Option #5</option>
                                        </CSelect>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel htmlFor="disabledSelect">Disabled Select</CLabel>
                                    </CCol>
                                    <CCol xs="12" md="9">
                                        <CSelect 
                                            custom 
                                            name="disabledSelect" 
                                            id="disabledSelect" 
                                            disabled 
                                            autoComplete="name"
                                        >
                                            <option value="0">Please select</option>
                                            <option value="1">Option #1</option>
                                            <option value="2">Option #2</option>
                                            <option value="3">Option #3</option>
                                        </CSelect>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel>Radios</CLabel>
                                    </CCol>
                                    <CCol md="9">
                                        <CFormGroup variant="checkbox">
                                            <CInputRadio className="form-check-input" id="radio1" name="radios" value="option1" />
                                            <CLabel variant="checkbox" htmlFor="radio1">Option 1</CLabel>
                                        </CFormGroup>
                                        <CFormGroup variant="checkbox">
                                            <CInputRadio className="form-check-input" id="radio2" name="radios" value="option2" />
                                            <CLabel variant="checkbox" htmlFor="radio2">Option 2</CLabel>
                                        </CFormGroup>
                                        <CFormGroup variant="checkbox">
                                            <CInputRadio className="form-check-input" id="radio3" name="radios" value="option3" />
                                            <CLabel variant="checkbox" htmlFor="radio3">Option 3</CLabel>
                                        </CFormGroup>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel>Inline Radios</CLabel>
                                    </CCol>
                                    <CCol md="9">
                                        <CFormGroup variant="custom-radio" inline>
                                            <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                                            <CLabel variant="custom-checkbox" htmlFor="inline-radio1">One</CLabel>
                                        </CFormGroup>
                                        <CFormGroup variant="custom-radio" inline>
                                            <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                                            <CLabel variant="custom-checkbox" htmlFor="inline-radio2">Two</CLabel>
                                        </CFormGroup>
                                        <CFormGroup variant="custom-radio" inline>
                                            <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                                            <CLabel variant="custom-checkbox" htmlFor="inline-radio3">Three</CLabel>
                                        </CFormGroup>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CCol md="3"><CLabel>Checkboxes</CLabel></CCol>
                                    <CCol md="9">
                                        <CFormGroup variant="checkbox" className="checkbox">
                                            <CInputCheckbox 
                                                id="checkbox1" 
                                                name="checkbox1" 
                                                value="option1" 
                                            />
                                            <CLabel variant="checkbox" className="form-check-label" htmlFor="checkbox1">Option 1</CLabel>
                                        </CFormGroup>
                                        <CFormGroup variant="checkbox" className="checkbox">
                                            <CInputCheckbox id="checkbox2" name="checkbox2" value="option2" />
                                            <CLabel variant="checkbox" className="form-check-label" htmlFor="checkbox2">Option 2</CLabel>
                                        </CFormGroup>
                                        <CFormGroup variant="checkbox" className="checkbox">
                                            <CInputCheckbox id="checkbox3" name="checkbox3" value="option3" />
                                            <CLabel variant="checkbox" className="form-check-label" htmlFor="checkbox3">Option 3</CLabel>
                                        </CFormGroup>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel>Inline Checkboxes</CLabel>
                                    </CCol>
                                    <CCol md="9">
                                        <CFormGroup variant="custom-checkbox" inline>
                                            <CInputCheckbox 
                                                custom 
                                                id="inline-checkbox1" 
                                                name="inline-checkbox1" 
                                                value="option1" 
                                            />
                                            <CLabel variant="custom-checkbox" htmlFor="inline-checkbox1">One</CLabel>
                                        </CFormGroup>
                                        <CFormGroup variant="custom-checkbox" inline>
                                            <CInputCheckbox custom id="inline-checkbox2" name="inline-checkbox2" value="option2" />
                                            <CLabel variant="custom-checkbox" htmlFor="inline-checkbox2">Two</CLabel>
                                        </CFormGroup>
                                        <CFormGroup variant="custom-checkbox" inline>
                                            <CInputCheckbox custom id="inline-checkbox3" name="inline-checkbox3" value="option3" />
                                            <CLabel variant="custom-checkbox" htmlFor="inline-checkbox3">Three</CLabel>
                                        </CFormGroup>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CLabel col md="3" htmlFor="file-input">File input</CLabel>
                                    <CCol xs="12" md="9">
                                        <CInputFile id="file-input" name="file-input"/>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CCol md="3">
                                        <CLabel>Multiple File input</CLabel>
                                    </CCol>
                                    <CCol xs="12" md="9">
                                        <CInputFile 
                                        id="file-multiple-input" 
                                        name="file-multiple-input" 
                                        multiple
                                        custom
                                        />
                                        <CLabel htmlFor="file-multiple-input" variant="custom-file">Choose Files...</CLabel>
                                    </CCol>
                                </CFormGroup> */}
                                {/* <CFormGroup row>
                                    <CLabel col md={3}>Custom file input</CLabel>
                                    <CCol xs="12" md="9">
                                        <CInputFile custom id="custom-file-input"/>
                                        <CLabel htmlFor="custom-file-input" variant="custom-file">Choose file...</CLabel>
                                    </CCol>
                                </CFormGroup> */}
                            </Form>
                        </CardBody>
                        {/* <CCardFooter>
                            <CButton type="submit" size="sm" color="primary"><CIcon name="cil-scrubber" /> Submit</CButton>
                            <CButton type="reset" size="sm" color="danger"><CIcon name="cil-ban" /> Reset</CButton>
                        </CCardFooter> */}
                    </Card>
                </Col>
                <Col md="6">
                    <Row>
                        <Col md="6">
                            {/* <WidgetProgress inverse color="success" variant="inverse" value={72} header={testData.pki.creditScore} text="Personal Credit Score" footer="Lorem ipsum dolor sit amet enim." />
                            <WidgetProgress inverse color="warning" variant="inverse" value={85} header="85%" text="Debt to Income Ratio" footer="Lorem ipsum dolor sit amet enim."/> */}
                            {/* <CWidgetDropdown
                                color="gradient-primary"
                                header="9.823"
                                text="Members online"
                                footerSlot={
                                    <ChartLineSimple
                                    pointed
                                    className="c-chart-wrapper mt-3 mx-3"
                                    style={{height: '70px'}}
                                    dataPoints={[65, 59, 84, 84, 51, 55, 40]}
                                    pointHoverBackgroundColor="primary"
                                    label="Members"
                                    labels="months"
                                    />
                                }
                            >
                                <CDropdown>
                                    <CDropdownToggle color="transparent">
                                        <CIcon name="cil-settings"/>
                                    </CDropdownToggle>
                                    <CDropdownMenu className="pt-0" placement="bottom-end">
                                        <CDropdownItem>Action</CDropdownItem>
                                        <CDropdownItem>Another action</CDropdownItem>
                                        <CDropdownItem>Something else here...</CDropdownItem>
                                        <CDropdownItem disabled>Disabled action</CDropdownItem>
                                    </CDropdownMenu>
                                </CDropdown>
                            </CWidgetDropdown> */}
                            <Card className="mini-stat bg-primary">
                                <CardBody className="mini-stat-img">
                                    <div className="mini-stat-icon">
                                        <i className={"ti-arrow-down float-right"}></i>
                                    </div>
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3 font-size-16">Personal Credit Score</h6>
                                        <h2 className="mb-4">{725}</h2>
                                        <Badge color={'primary'}>- 5% </Badge> <span className="ml-2">from last quarter</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                            <Col xs="6">
                            <Card className="mini-stat bg-primary">
                                <CardBody className="mini-stat-img">
                                    <div className="mini-stat-icon">
                                        <i className={"ti-arrow-up float-right"}></i>
                                    </div>
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3 font-size-16">Business Credit Score</h6>
                                        <h2 className="mb-4">{790}</h2>
                                        <Badge color={'primary'}>+ 1% </Badge> <span className="ml-2">from last quarter</span>
                                    </div>
                                </CardBody>
                            </Card>
                            {/* <WidgetProgress inverse color="info" variant="inverse" value={70} header="698" text="Business Credit Score" footer="Lorem ipsum dolor sit amet enim."/>
                            <WidgetProgress inverse color="danger" variant="inverse" value={65} header="65%" text="CommunityBacker Risk Index" footer="Lorem ipsum dolor sit amet enim."/> */}
                        </Col>
                    </Row>
                    <Card>
                        <CardHeader>SBA DOCUMENTATION</CardHeader>
                        <CardBody>
                        <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">SBA Form #1</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <a href="#">SBAForm1.pdf</a>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">SBA Form #2</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <a href="#">SBAForm2.pdf</a>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">SBA Form #3</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <a href="#">SBAForm3.pdf</a>
                                        </Col>
                                    </FormGroup>
                        </CardBody>
                        <CardFooter><div align="right"><a href="#">Download Zip Bundle</a></div></CardFooter>
                    </Card>
                    <Card>
                        <CardHeader>APPLICATION SUPPORTING DOCUMENTATION</CardHeader>
                        <CardBody>
                            <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">Government ID</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <a href="#">GovernmentId.pdf</a>
                                            {/* <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.ssn} disabled /> */}
                                            {/* <CFormText>This is a help text</CFormText> */}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">Personal Tax Returns</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <a href="#">2019Taxes.pdf</a><br /><a href="#">2018Taxes.pdf</a>
                                            {/* <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.ssn} disabled /> */}
                                            {/* <CFormText>This is a help text</CFormText> */}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">Articles of Incorporation</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <a href="#">ArticlesOfIncorporation.pdf</a>
                                            {/* <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.ssn} disabled /> */}
                                            {/* <CFormText>This is a help text</CFormText> */}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">Business Tax Returns</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <a href="#">2019Taxes.pdf</a><br /><a href="#">2018Taxes.pdf</a>
                                            {/* <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.ssn} disabled /> */}
                                            {/* <CFormText>This is a help text</CFormText> */}
                                        </Col>
                                    </FormGroup>
                        </CardBody>
                        <CardFooter><div align="right"><a href="#">Download Zip Bundle</a></div></CardFooter>
                    </Card>
                    <Card>
                        <CardHeader>APPLICATION HISTORY</CardHeader>
                        <CardBody>
                        <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">June 12, 2020</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <p>Application Submitted</p>
                                            {/* <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.ssn} disabled /> */}
                                            {/* <CFormText>This is a help text</CFormText> */}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">June 14, 2020</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <p>Application Viewed by Steve<br />
                                            Application Added to Steve's Review Pipeline</p>
                                            {/* <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.ssn} disabled /> */}
                                            {/* <CFormText>This is a help text</CFormText> */}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="text-input">June 15, 2020</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <p>SBA Documentation Bundle Downloaded by Steve</p>
                                            {/* <CInput id="text-input" name="text-input" placeholder="Text" value={testData.personal.ssn} disabled /> */}
                                            {/* <CFormText>This is a help text</CFormText> */}
                                        </Col>
                                    </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
                <Col md="12" align="right">
                    <Button type="submit" size="sm" color="primary"> Approve</Button>
                    <Button type="reset" size="sm" color="danger"> Reject</Button>
                </Col>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(ViewApplication)