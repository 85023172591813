import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import { Card, CardBody, CardHeader, CardTitle, Button, Table, Row, Col, Input, Label, FormGroup } from 'reactstrap'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import axios from 'axios'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { config } from '../../config'
const API_URL = config.url.API_URL
const BASEAPI = `${API_URL}/api/v1`

const LoadingContainer = props => <div>Loading...</div>;


class Lender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "Lender Detail", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            lenderName: "",
            lenderId: "",
            loanId: "",
            lenderCity: "",
            lenderState: "",
            membership: "",
            membershipFee: "",
            phoneNo: "",
            aum: "",
            website: "",
            loans: []
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Lender Detail", this.state.breadcrumbItems);

        axios.get(`${BASEAPI}/lenders/${this.props.match.params.id}`).then(results => {
            console.log(results.data.apiData)
            this.setState({
                lenderId: results.data.apiData._id,
                lenderName: results.data.apiData.name, 
                lenderCity: results.data.apiData.address.city,
                lenderState: results.data.apiData.address.state,
                membership: results.data.apiData.membership.type,
                membershipFee: results.data.apiData.membership.fee,
                phoneNo: results.data.apiData.phoneNo,
                aum: results.data.apiData.aum,
                website: results.data.apiData.website,
                loans: results.data.apiData.loans
            })
            
        })
    }

    activateStreetView = (position) => {
        const mapObj = this.mapRef.map.getStreetView();
        mapObj.setPov({ heading: 34, pitch: 10 });
        mapObj.setPosition(position);
        mapObj.setVisible(true);
  }

    render() {

        const lenderId = this.state.lenderId

        var loanTableData = this.state.loans.map(function(loan) {
            return <tr key={loan._id}>
                <td>
                    <p className="m-0 text-muted font-14">{loan.loanId}</p>
                </td>
                <td>
                    <p className="m-0 text-muted font-14">{loan.loanName}</p>
                </td>
                <td>
                    <p className="m-0 text-muted font-14">{loan.loanTerms}</p>
                </td>
                <td>
                    <Link to={'/loan-application/' + lenderId + '/loans/' + loan._id}>
                        <Button color="secondary" size="sm" className="waves-effect waves-light">Apply</Button>
                    </Link>
                </td>
            </tr>
        })
        return (
            <React.Fragment>
                <Card>
                    <CardHeader>
                        <CardTitle><h4 className="card-title">{this.state.lenderName} ({this.state.lenderId})</h4></CardTitle></CardHeader>
                        <CardBody>
                            <Row xs="12">
                                <Col xs="6">
                                    <h4 className="card-title">Details</h4>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Lender Name</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.lenderName} placeholder="Lender Name" id="lenderName" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Lender City</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.lenderCity} placeholder="Lender City" id="lenderCity" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Lender State</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.lenderState} placeholder="Lender State" id="lenderState" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Membership</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.membership} placeholder="Membership" id="membership" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Membership Fee</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.membershipFee} placeholder="Membership Fee" id="membershipFee" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Phone No</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.phoneNo} placeholder="Phone No" id="phoneNo" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">Website</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.website} placeholder="Website" id="website" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-3 col-form-label">AUM</Label>
                                        <Col sm="9">
                                            <Input className="form-control" type="text" defaultValue={this.state.aum} placeholder="aum" id="aum" disabled />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <h4 className="card-title">Loans</h4>
                                    <div className="table-responsive">
                                        <Table className="table-centered table-vertical table-nowrap">
                                            <tbody>
                                                {loanTableData}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            
                            {/* <p>Lender ID: {this.state.lenderId}</p>
                            <p>Lender Name: {this.state.lenderName}</p>
                            <p>Lender City: {this.state.lenderCity}</p>
                            <p>Lender State: {this.state.lenderState}</p>
                            <p>Membership: {this.state.membership}</p>
                            <p>Phone No: {this.state.phoneNo}</p>
                            <p>AUM: {this.state.aum}</p>
                            <p>Website: {this.state.website}</p> */}

                            

                                    {/* <h4 className="card-title">Markers</h4>
                                    <p className="card-title-desc">Example of google maps.</p> */}

                                    <div
                                        id="gmaps-markers"
                                        className="gmaps"
                                        style={{ position: "relative" }}
                                    >
                                        <Map
                                            google={this.props.google}
                                            style={{ width: "100%", height: "100%" }}
                                            zoom={14}
                                        >
                                            <Marker
                                                title={"The marker`s title will appear as a tooltip."}
                                                name={"SOMA"}
                                                position={{ lat: 37.778519, lng: -122.40564 }}
                                            />
                                            <Marker name={"Dolores park"} />
                                            <InfoWindow>
                                                <div>
                                                <h1>{this.state.selectedPlace.name}</h1>
                                                </div>
                                            </InfoWindow>
                                        </Map>
                                    </div>

                        </CardBody>
                    </Card>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(GoogleApiWrapper({
    apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    LoadingContainer: LoadingContainer,
    v: "3"
})(Lender));
