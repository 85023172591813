import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import produce from 'immer'
import axios from 'axios'

import classnames from "classnames";

import {AgGridReact} from 'ag-grid-react'
import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css'
import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { CardBody, FormGroup, Col, Row, Card, Input, Button, Table, CardHeader, CardTitle, Pagination, PaginationItem, PaginationLink, Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';

import { MDBDataTable } from 'mdbreact';

import "../Tables/datatables.scss";

// import { data } from './data'

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Search", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            searchText : "",
            activeTabJustify: "1",
            activeSearchTab: "1",
            activeTab1: "1",
            data: {
                columns: [
                    {
                        label: 'ID',
                        field: '_id',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'CBID',
                        field: 'cbid',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'City',
                        field: 'city',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'State',
                        field: 'state',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Membership',
                        field: 'membership',
                        sort: 'asc',
                        width: 150
                    },
                ],
                rows: []
            },
            columnDefs: [
				{headerName: "ID", field: "_id", sortable: true, filter: true },
				{headerName: "CBID", field: "cbid", sortable: true, filter: true },
                {headerName: "Name", field: "name", sortable: true, filter: true },
                {headerName: "City", field: "city", sortable: true, filter: true },
                {headerName: "State", field: "state", sortable: true, filter: true },
                {headerName: "Membership", field: "membership", sortable: true, filter: true },
                {headerName: "View", field: 'url', cellRenderer: (params) => {
                    var link = document.createElement('a');
                    link.href = '/lender/' + params.value;
                    // link.innerText = params.value;
                    link.innerText = 'View Details';
                    link.addEventListener('click', (e) => {
                        e.preventDefault();
                        console.log(params.data.id);
                        this.props.history.push('/lender/' + params.value)
                    });
                    return link;
                }}

			],
			rowData: []
        }
    } 

    toggleCustomJustified(tab) {
        if (this.state.activeTabJustify !== tab) {
          this.setState({
            activeTabJustify: tab
          });
        }
      }

      toggleSearchTab(tab) {
        if (this.state.activeSearchTab !== tab) {
          this.setState({
            activeSearchTab: tab
          });
        }
      }

    onSearchChange = (e) => {
        this.setState({searchText : e.target.value})
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Search", this.state.breadcrumbItems);

        axios.get(`${BASEAPI}/lenders`).then(results => {
            console.log(results.data.apiData)
            
            let arr = []
            results.data.apiData.forEach(record => {
                let doc = {}
                doc['_id'] = record._id
                doc['cbid'] = record.cbid
                doc['name'] = record.name
                doc['city'] = record.address.city
                doc['state'] = record.address.state
                doc['membership'] = record.membership.type
                doc['url'] = record._id

                arr.push(doc)
            })

            this.setState(produce(draft => {
                draft.data.rows = arr
            }))

            this.setState(produce(draft => {
                draft.rowData = arr
            }))

            console.log(this.state.data.rowData)
        })
    }

    render() {

        var tableData = this.state.rowData.map(function(record) {
            console.log("processing record")
            return <tr key={record._id}>
               <td>
                   <p className="m-0 text-muted font-14"><Link to={"/lender/" + record._id}>{record.name}</Link></p>
               </td>
               <td>
                   <p className="m-0 text-muted font-14">{record.cbid}</p>
               </td>
               <td>
                   <p className="m-0 text-muted font-14">{record.city}</p>
               </td>
               <td>
                   <p className="m-0 text-muted font-14">{record.state}</p>
               </td>
               <td>
                   <p className="m-0 text-muted font-14">{record.membership}</p>
               </td>
               <td>
                    <Link to={"/lender/" + record._id}>
                        <Button color="secondary" size="sm" className="waves-effect waves-light">View</Button>
                    </Link>
               </td>
           </tr>
       })

       console.log(tableData)

       const fullWidth = { width : '100%' }

        return (
            <React.Fragment>


                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardHeader><CardTitle><h4 className="card-title">Credit Union Search</h4></CardTitle></CardHeader>
                                <CardBody>

                                <Nav tabs justified className="nav-tabs-custom" role="tablist">
                                        <NavItem>
                                        <NavLink
                                            style={{ cursor : "pointer" }}
                                            className={classnames({
                                            active: this.state.activeSearchTab === "1"
                                            })}
                                            onClick={() => {
                                            this.toggleSearchTab("1");
                                            }}
                                        >
                                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                <span className="d-none d-sm-block">Basic Search</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink
                                            style={{ cursor : "pointer" }}
                                                className={classnames({
                                                active: this.state.activeSearchTab === "2"
                                                })}
                                                onClick={() => {
                                                this.toggleSearchTab("2");
                                                }}
                                            >
                                                <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                <span className="d-none d-sm-block">Advanced Search</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>


                                    <TabContent activeTab={this.state.activeSearchTab}>
                                        <TabPane tabId="1" className="p-3">
                                        <center>
                                    <form className="app-search d-none d-lg-inline-block" style={fullWidth}>
                                        <div className="position-relative">
                                            <input type="text" className="form-control" placeholder="Search..." onClick={() => alert('Coming Soon - Site search not yet implemented')}/>
                                            <span className="fa fa-search"></span>
                                        </div>
                                    </form>
                                </center>
                                        </TabPane>
                                        <TabPane tabId="2" className="p-3">
                                            <p className="mb-0">
                                                Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit.
                                            </p>
                                        </TabPane>
                                    </TabContent>







                                {/* <center>
                                    <form className="app-search d-none d-lg-inline-block" style={fullWidth}>
                                        <div className="position-relative">
                                            <input type="text" className="form-control" placeholder="Search..." onClick={() => alert('Coming Soon - Site search not yet implemented')}/>
                                            <span className="fa fa-search"></span>
                                        </div>
                                    </form>
                                </center> */}

                                    

            <div className="table-responsive">
                    <Table className="table-centered table-vertical table-nowrap">
                        <tbody>
                            {tableData}
                        </tbody>
                    </Table>
                </div>
                <hr />
                <center>
                    <nav aria-label="Page navigation example">
                                        <Pagination>
                                            <PaginationItem>
                                                <PaginationLink href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">2</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                    </center>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(Search)