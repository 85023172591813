import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from "react-redux";

import CookieConsent, { Cookies } from "react-cookie-consent";

import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

import VerticalLayout from "./component/Layout/VerticalLayout";
import HorizontalLayout from "./component/Layout/HorizontalLayout/";
import NonAuthLayout from "./component/NonAuthLayout/NonAuthLayout";

// Import scss
import './theme.scss';

//Fake backend - comment this out to get axios to work, but need to replace this functionality
import fakeBackend from './helpers/fakeBackend';
//fakeBackend();

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
        case "horizontal":
            layoutCls = HorizontalLayout;
            break;
        default:
            layoutCls = VerticalLayout;
            break;
        }
        return layoutCls;
    };

    render() {
        const Layout = this.getLayout();

        Cookies.set("test", "nice");

        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        
                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                component={route.component}
                                layout={NonAuthLayout}
                                key={idx}
                                isAuthProtected={false}
                            />
                        ))}

                        {authProtectedRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                component={route.component}
                                layout={Layout}
                                key={idx}
                                isAuthProtected={true}
                            />
                        ))}
                        
                    </Switch>
                </Router>

                {/* <CookieConsent
                    buttonText="I accept"
                    onAccept={() => {
                        // alert("Accepted");
                    }}
                    debug={false}
                    enableDeclineButton
                    declineButtonText="Decline (optional)"
                    onDecline={() => {
                        // alert("Declined");
                    }}
                >
                    This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website 
                    and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and 
                    metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our Privacy Policy.
                    <br />  
                    <br />
                    If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember 
                    your preference not to be tracked.
                    <br />
                    <br />{" "}
                </CookieConsent> */}

            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, null)(App);
