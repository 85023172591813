import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, FormGroup, Col, Row, Card, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import axios from 'axios'

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import {AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const steps = [
    'Personal Information',
    'Home Address',
    'Business Information',
    'Business Address',
    'Supporting Documentation',
    'Other Information'
]

const stepContent = [
    'Please enter your personal information below',
    'Please enter your home address',
    'Please enter your business information below',
    'Please enter your business address below',
    'Please upload your supporting documentation below',
    'Please provide the following information'
]


class TestPlaid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "backabl", link : "#" },
                { title : "Testing Stuff", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            activeStep: 0,
            columnDefs: [
				      {headerName: "Make", field: "make", sortable: true, filter: true },
				      {headerName: "Model", field: "model", sortable: true, filter: true },
                {headerName: "Price", field: "price", sortable: true, filter: true },
                {headerName: "Link", field: 'link', cellRenderer: (params) => {
                    var link = document.createElement('a');
                    link.href = '#';
                    // link.innerText = params.value;
                    link.innerText = 'View';
                    link.addEventListener('click', (e) => {
                        e.preventDefault();
                        console.log(params.data.id);
                        this.props.history.push(params.value)
                    });
                    return link;
                }}

			      ],
            rowData: [],
            gridAPI: null,
            gridColumnAPI: null,
            testing: {
              myAlert: this.myAlert
            },
            displayJSON: {}
        }
        this.getTransactions = this.getTransactions.bind(this)
        this.getIdentity = this.getIdentity.bind(this)
        this.getCredit = this.getCredit.bind(this)
        this.getHoldings = this.getHoldings.bind(this)
        this.getIncome = this.getIncome.bind(this)
        this.getLiabilities = this.getLiabilities.bind(this)
        this.getBalance = this.getBalance.bind(this)
    } 

    myAlert() {
      alert('this was called')
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Testing Stuff", this.state.breadcrumbItems);
        const data = [
            {make: "Toyota", model: "Celica", price: 35000, link: '/account'},
            {make: "Ford", model: "Mondeo", price: 32000, link: '/account'},
            {make: "Porsche", model: "Boxter", price: 72000, link: '/account'}
        ]
        this.setState({rowData: data})
    }

    handleNext = () => {
        const current = this.state.activeStep
        this.setState({activeStep: current + 1})
        //this.state.activeStep = this.state.activeStep + 1
        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
    handleBack = () => {
        const current = this.state.activeStep
        this.setState({activeStep: current - 1})
        //this.state.activeStep = this.sstateactiveStep - 1
        //setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

    handleReset = () => {
        this.setState({activeStep: 0})
    };

    onFilterTextBoxChanged() {
        this.state.gridAPI.setQuickFilter('Ford');
    }

    onGridReady = (params) => {
        this.setState({ gridAPI: params.api })
        this.setState({ gridColumnAPI: params.columnApi })
    }

    renderModal1() {
      return (
        <Modal>
          <ModalHeader></ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      )
    }

    getTransactions() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get('http://localhost:9000/api/v1/lenders-plaid/transactions').then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getIdentity() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get('http://localhost:9000/api/v1/lenders-plaid/identity', { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getCredit() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get('http://localhost:9000/api/v1/lenders-plaid/credit', { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getHoldings() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get('http://localhost:9000/api/v1/lenders-plaid/holdings', { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getIncome() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user', { headers: {"Authorization" : `Bearer ${token}`} })).lenderId

        axios.get('http://localhost:9000/api/v1/lenders-plaid/income').then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getLiabilities() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get('http://localhost:9000/api/v1/lenders-plaid/liabilities', { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    getBalance() {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        axios.get('http://localhost:9000/api/v1/lenders-plaid/balance', { headers: {"Authorization" : `Bearer ${token}`} }).then(result => {
            console.log(result)
            this.setState({ displayJSON: result.data.apiData })
        })
    }

    render() {
        return (
            <React.Fragment>

                <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody> 
                                  {/* <Button onClick={this.state.testing.myAlert}>Hello</Button>
                                  <Button onClick={this.state.testing.myAlert}>Modal</Button> */}
                                {/* <input type="text" id="filter-text-box" placeholder="Filter..." onChange={() => this.onFilterTextBoxChanged()}/>
                <div
				className="ag-theme-balham"
                style={{height: '300px'}}
			>
				<AgGridReact
                    onGridReady={this.onGridReady}
					columnDefs={this.state.columnDefs}
					rowData={this.state.rowData}>
				</AgGridReact>
			</div> */}

            <Button onClick={this.getBalance}>Get Balance</Button> {' '}
            <Button onClick={this.getTransactions}>Get Transactions</Button> {' '}
            <Button onClick={this.getIdentity}>Get Identity</Button> {' '}
            <Button onClick={this.getCredit}>Get Credit</Button> {' '}
            <Button onClick={this.getHoldings}>Get Holdings</Button> {' '}
            <Button onClick={this.getIncome}>Get Income</Button> {' '}
            <Button onClick={this.getLiabilities}>Get Liabilities</Button>
            <br />
            <br />
            <div>
                <pre>
                    { JSON.stringify(this.state.displayJSON, null, 2)}
                </pre>
            </div>

            </CardBody></Card></Col></Row>
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(TestPlaid)