import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

class RecentActivity extends Component {

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardHeader><CardTitle><h4 className="card-title">Recent Activity</h4></CardTitle></CardHeader>
                    <CardBody>     
                        <ol className="activity-feed mb-0">
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">Jun 25, 2020</span>
                                    <span className="activity-text">Applied for loan at <Link to="/lender/5f2de87e35579ba58cbf7a97">NASA Federal Credit Union</Link></span>
                                </div>
                            </li>
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">Jun 24, 2020</span>
                                    <span className="activity-text">Loan Rejected by <Link to="/lender/5f2de87e35579ba58cbf7a99">Andrews Federal Credit Union</Link></span>
                                </div>
                            </li>
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">Jun 23, 2020</span>
                                    <span className="activity-text">Loan being reviewed by <Link to="/lender/5f2de87e35579ba58cbf7a99">Andrews Federal Credit Union</Link></span>
                                </div>
                            </li>
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">Jun 21, 2020</span>
                                    <span className="activity-text">Submitted Loan Request to <Link to="/lender/5f2de87e35579ba58cbf7a99">Andrews Federal Credit Union</Link></span>
                                </div>
                            </li>
                        </ol>

                        <div className="text-center">
                            <Link to="#" onClick={() => alert('Coming Soon!')} className="btn btn-sm btn-primary">Load More</Link>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default RecentActivity;