import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Card,
    CardBody, 
    Col,
    Label,
    FormGroup, 
    Input,
    InputGroupAddon,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Switch from "react-switch"
import Select from "react-select";
import { AvForm, AvField, AvInput, AvGroup  } from "availity-reactstrap-validation";

import axios from 'axios'

import { setBreadcrumbItems } from '../../store/actions'
import { getTest } from '../../services/testService'
import { getLoggedInUser } from '../../helpers/authUtils'
import myTeam from '../../pages/Demo/my-team';

import { config } from '../../config'

const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" }
      ]
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" }
      ]
    }
  ];

  const modalStyle = {
      minWidth: 500
  }

class Loan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Loan Management", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            username: "",
            loanModal: false,
            loanEditModal: false,
            loanEditData: {
                _id: '',
                reference: '',
                name: '',
                description: '',
                minAmount: '',
                maxAmount: '',
                apr: '',
                terms: ''
            },
            tempId: '',
            tempReference: '',
            tempName: '',
            tempDescription: '',
            tempMinAmount: '',
            tempMaxAmount: '',
            tempApr: '',
            tempTerms: '',
            tempStatus: false,
            loanProducts: [],
            switch1: true,

            selectedGroup: null,
            selectedMulti: null,
        }
        this.toggleLoanModal = this.toggleLoanModal.bind(this)
        this.toggleLoanEditModal = this.toggleLoanEditModal.bind(this)

        this.handleSelectGroup = this.handleSelectGroup.bind(this);
        this.handleMulti = this.handleMulti.bind(this);

        this.handleNewLoanSubmit = this.handleNewLoanSubmit.bind(this);
        this.handleEditLoanSubmit = this.handleEditLoanSubmit.bind(this);
        this.toggleIsPublished = this.toggleIsPublished.bind(this);
    } 

    toggleLoanModal() {
        this.setState(prevState => ({
            loanModal: !prevState.loanModal
        }))
    }

    toggleLoanEditModal() {
        this.setState(prevState => ({
            loanEditModal: !prevState.loanEditModal
        }))
    }

    toggleIsPublished(event) {
        var data = [...this.state.loanProducts]
        var index = data.findIndex(obj => obj._id === event.target.id )
        data[index].status = !data[index].status
        this.setState({data})
        //console.log(event.target.id)
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId
        const payload = { loanStatus: data[index].status }
        axios.patch(`${BASEAPI}/lenders/${lenderId}/loans/${event.target.id}`, payload, { headers: {"Authorization" : `Bearer ${token}`} }).then(results => {
            console.log('Updated!')
        })
    }

    handleSelectGroup = selectedGroup => {
        this.setState({ selectedGroup });
    };
    handleMulti = selectedMulti => {
        this.setState({ selectedMulti });
    };

    componentDidMount(){
        this.props.setBreadcrumbItems("Loan Management", this.state.breadcrumbItems);
        const result = getLoggedInUser() 
        console.log("Logged In User");
        console.log(result.username)
        this.setState({username: result.username})

        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId
        axios.get(`${BASEAPI}/lenders/${lenderId}/loans`, { headers: {"Authorization" : `Bearer ${token}`} }).then(results => {
            console.log(results)
            this.setState({loanProducts: results.data.apiData})
        })
    }

    handleNewLoanSubmit(event, values) {
        //alert('new Loan Submit Handler Called'    

        let data = {
            _id : 3,
            reference : values.loanRefCode,
            name : values.loanName,
            description : values.loanDesc,
            minAmount : values.loanMinAmount,
            maxAmount : values.loanMaxAmount,
            apr : values.loanAPR,
            terms : values.loanMonths,
            status : false
        }
       
        //console.log({data})

        // this.setState(prevState => ({
        //     loanProducts : [...prevState.loanProducts, data],
        // }))

        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        const payload = {
            lenderId : lenderId,
            reference : values.loanRefCode,
            name : values.loanName,
            description : values.loanDesc,
            minAmount : values.loanMinAmount,
            maxAmount : values.loanMaxAmount,
            apr : values.loanAPR,
            terms : values.loanMonths,
            status : false
        }

        console.log(payload)

        axios.post(`${BASEAPI}/lenders/${lenderId}/loans`, payload, { headers: {"Authorization" : `Bearer ${token}`} }).then(results => {
            console.log(results)
            data._id = results.data.apiData.insertedId
            console.log(results.data.apiData.insertedId)
            console.log(data)

            this.setState(prevState => ({
                loanProducts : [...prevState.loanProducts, data],
            }))

            this.toggleLoanModal()
        }).catch(err => {
            alert('There was an error ' + err)
        })
    }

    handleEditLoanSubmit(event, values) {
        const token = JSON.parse(localStorage.getItem('cb-lender-user')).token
        const lenderId = JSON.parse(localStorage.getItem('cb-lender-user')).lenderId

        const payload = {
            _id: this.state.tempId,
            lenderId : lenderId,
            reference : this.state.tempReference,
            name : this.state.tempName,
            description : this.state.tempDescription,
            minAmount : this.state.tempMinAmount,
            maxAmount : this.state.tempMaxAmount,
            apr : this.state.tempApr,
            terms : this.state.tempTerms,
            status : this.state.tempStatus
        }

        axios.put(`${BASEAPI}/lenders/${lenderId}/loans`, payload, { headers: {"Authorization" : `Bearer ${token}`} }).then(results => {
            console.log(results)

            var data = [...this.state.loanProducts]
            var index = data.findIndex(obj => obj._id === this.state.tempId )
            data[index].reference = this.state.tempReference
            data[index].name = this.state.tempName
            data[index].description = this.state.tempDescription
            data[index].minAmount = this.state.tempMinAmount
            data[index].maxAmount = this.state.tempMaxAmount
            data[index].apr = this.state.tempApr
            data[index].terms = this.state.tempTerms
            this.setState({data})
            this.toggleLoanEditModal()
            this.setState({tempId: ''})
            this.setState({tempReference: ''})
            this.setState({tempName: ''})
            this.setState({tempDescription: ''})
            this.setState({tempMinAmount: ''})
            this.setState({tempMaxAmount: ''})
            this.setState({tempApr: ''})
            this.setState({tempTerms: ''})
            this.setState({tempStatus: false})
        }).catch(err => {
            alert('There was an error ' + err)
        })
        // Copy the temp values to the main array
        // Blank the temp 
        // close the modal
    }

    editLoan(loanId) {
        alert('Editing loan ' + loanId)
        var data = [...this.state.loanProducts]
        var index = data.findIndex(obj => obj._id === loanId )
        var loan = data[index]
        
        this.setState({tempId: loanId})
        this.setState({tempReference: loan.reference})
        this.setState({tempName: loan.name})
        this.setState({tempDescription: loan.description})
        this.setState({tempMinAmount: loan.minAmount})
        this.setState({tempMaxAmount: loan.maxAmount})
        this.setState({tempApr: loan.apr})
        this.setState({tempTerms: loan.terms})
        this.setState({tempStatus: loan.status})

        this.toggleLoanEditModal()
    }

    render() {
        function Offsymbol(text){
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 12,
                  color: "#fff",
                  paddingRight: 2
                }}
              >
                {" "}
                {text}
              </div>
            );
          };
      
        function OnSymbol(text) {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 12,
                  color: "#fff",
                  paddingRight: 2
                }}
              >
                {" "}
                {text}
              </div>
            );
          };

        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Loan Management</h4>
                                <Button type="button" color="primary" className="waves-effect waves-light" onClick={this.toggleLoanModal}>Create New Loan</Button>
                                <Modal isOpen={this.state.loanModal} toggle={this.toggleLoanModal} autoFocus={true} size="lg" backdrop={'static'} data-toggle="modal" data-target="#loanModal">
                                <AvForm onValidSubmit={this.handleNewLoanSubmit}>
                                    <ModalHeader toggle={this.toggleLoanModal}>
                                        Create New Loan
                                    </ModalHeader>
                                    <ModalBody>
                                        
                                        <div style={{ minWidth: 500 }}>
                                        {/* <AvForm onValidSubmit={this.handleNewLoanSubmit}> */}
                                            <AvField
                                                name="loanRefCode"
                                                // label="Reference Code"
                                                placeholder="Enter a reference code"
                                                type="text"
                                                errorMessage="Missing reference code"
                                                validate={{ required: { value: true } }}
                                            />
                                            <AvField
                                                name="loanName"
                                                // label="Name"
                                                placeholder="Enter a name for your loan"
                                                type="text"
                                                errorMessage="Missing loan name"
                                                validate={{ required: { value: true } }}
                                            />
                                            <AvInput 
                                                //label="Another textarea" 
                                                rows="5" 
                                                type="textarea" 
                                                name="loanDesc" 
                                                id="loanDesc" 
                                                placeholder="Enter a loan description" 
                                                required />
                                            <AvField
                                                name="loanMinAmount"
                                                // label="Minimum Loan Amount"
                                                placeholder="Enter an minimum loan amount"
                                                type="text"
                                                errorMessage="Missing amount"
                                                validate={{ required: { value: true } }}
                                            />
                                            <AvField
                                                name="loanMaxAmount"
                                                // label="Maximum Loan Amount"
                                                placeholder="Enter an maximum loan amount"
                                                type="text"
                                                errorMessage="Missing amount"
                                                validate={{ required: { value: true } }}
                                            />
                                            <AvField
                                                name="loanAPR"
                                                // label="APR"
                                                placeholder="Enter an APR"
                                                type="text"
                                                errorMessage="Missing APR"
                                                validate={{ required: { value: true } }}
                                            />
                                            <AvField
                                                name="loanMonths"
                                                // label="Months"
                                                placeholder="Enter number of months"
                                                type="text"
                                                errorMessage="Missing months"
                                                validate={{ required: { value: true } }}
                                            />
                                        {/* </AvForm> */}
                                    </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleLoanModal}>Close</Button>
                                        <Button type="submit" color="primary" className="waves-effect waves-light">Submit</Button>
                                    </ModalFooter>
                                    </AvForm>
                                </Modal>
                                <Modal isOpen={this.state.loanEditModal} toggle={this.toggleLoanEditModal} autoFocus={true} size="lg" backdrop={'static'} data-toggle="modal" data-target="#loanModal">
                                <AvForm onValidSubmit={this.handleEditLoanSubmit}>
                                    <ModalHeader toggle={this.toggleLoanEditModal}>
                                        Edit Loan
                                    </ModalHeader>
                                    <ModalBody>
                                        
                                        <div style={{ minWidth: 500 }}>
                                        {/* <AvForm onValidSubmit={this.handleNewLoanSubmit}> */}
                                            <AvField
                                                name="loanRefCode"
                                                // label="Reference Code"
                                                placeholder="Enter a reference code"
                                                type="text"
                                                errorMessage="Missing reference code"
                                                validate={{ required: { value: true } }}
                                                value={this.state.tempReference}
                                                onChange={(e) => this.setState({tempReference : e.target.value})}
                                            />
                                            <AvField
                                                name="loanName"
                                                // label="Name"
                                                placeholder="Enter a name for your loan"
                                                type="text"
                                                errorMessage="Missing loan name"
                                                validate={{ required: { value: true } }}
                                                value={this.state.tempName}
                                                onChange={(e) => this.setState({tempName : e.target.value})}
                                            />
                                            <AvInput 
                                                //label="Another textarea" 
                                                rows="5" 
                                                type="textarea" 
                                                name="loanDesc" 
                                                id="loanDesc" 
                                                placeholder="Enter a loan description" 
                                                value={this.state.tempDescription}
                                                onChange={(e) => this.setState({tempDescription : e.target.value})}
                                                required />
                                            <AvField
                                                name="loanMinAmount"
                                                // label="Minimum Loan Amount"
                                                placeholder="Enter an minimum loan amount"
                                                type="text"
                                                errorMessage="Missing amount"
                                                validate={{ required: { value: true } }}
                                                value={this.state.tempMinAmount}
                                                onChange={(e) => this.setState({tempMinAmount : e.target.value})}
                                            />
                                            <AvField
                                                name="loanMaxAmount"
                                                // label="Maximum Loan Amount"
                                                placeholder="Enter an maximum loan amount"
                                                type="text"
                                                errorMessage="Missing amount"
                                                validate={{ required: { value: true } }}
                                                value={this.state.tempMaxAmount}
                                                onChange={(e) => this.setState({tempMaxAmount : e.target.value})}
                                            />
                                            <AvField
                                                name="loanAPR"
                                                // label="APR"
                                                placeholder="Enter an APR"
                                                type="text"
                                                errorMessage="Missing APR"
                                                validate={{ required: { value: true } }}
                                                value={this.state.tempApr}
                                                onChange={(e) => this.setState({tempApr : e.target.value})}
                                            />
                                            <AvField
                                                name="loanMonths"
                                                // label="Months"
                                                placeholder="Enter number of months"
                                                type="text"
                                                errorMessage="Missing months"
                                                validate={{ required: { value: true } }}
                                                value={this.state.tempTerms}
                                                onChange={(e) => this.setState({tempTerms : e.target.value})}
                                            />
                                        {/* </AvForm> */}
                                    </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button type="button" color="secondary" className="waves-effect" onClick={this.toggleLoanEditModal}>Close</Button>
                                        <Button type="submit" color="primary" className="waves-effect waves-light">Update</Button>
                                    </ModalFooter>
                                    </AvForm>
                                </Modal>

                                <br /><br />

                                <div className="table-responsive">
                                        <Table className="table mb-0">

                                            <thead>
                                                <tr>
                                                    <th>Ref</th>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Amount (Min)</th>
                                                    <th>Amount (Max)</th>
                                                    <th>APR</th>
                                                    <th>Term (Months)</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.loanProducts.map(row => {
                                                    return(
                                                        <tr>
                                                            <td>{`${row.reference}`}</td>
                                                            <td>{`${row.name}`}</td>
                                                            <td>{`${row.description}`}</td>
                                                            <td>{`${row.minAmount}`}</td>
                                                            <td>{`${row.maxAmount}`}</td>
                                                            <td>{`${row.apr}`}</td>
                                                            <td>{`${row.terms} months`}</td>
                                                            <td>
                                                                {/* <Switch
                                                                    uncheckedIcon={Offsymbol("Off")}
                                                                    checkedIcon={OnSymbol("On")}
                                                                    onColor="#626ed4"
                                                                    onChange={() =>
                                                                        this.setState({ switch1: !row.status })
                                                                    }
                                                                    checked={row.status}
                                                                    className="mr-1 mt-1"
                                                                /> */}
                                                                <center>
                                                                <Input 
                                                                    type="checkbox" 
                                                                    // defaultChecked={}
                                                                    checked={row.status}
                                                                    name="loanStatus"
                                                                    id={row._id}
                                                                    onChange={this.toggleIsPublished}
                                                                />
                                                                </center>
                                                            </td>
                                                                <td>

                                                                {/* <Button type="button" color="link" className="waves-effect">View</Button>{' | '} */}
                                                                <Button type="button" color="link" className="waves-effect" onClick={() => this.editLoan(row._id)}>Edit</Button>
                                                                {/* <a href={`/loan/${row._id}`}>View</a>{' | '}<a href={`/loan/${row._id}`}>Edit</a></td> */}
                                                                </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>                                               
                                        </Table>
                                    </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>                
            </React.Fragment>
        )
    }
}

export default connect(null, {setBreadcrumbItems})(Loan)