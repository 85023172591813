import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav } from 'reactstrap';
import { checkLogin, clearErrorLogin, clearError } from '../../store/actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import logodark from "../../assets/images/logo-dark.png";
import cblogo from '../../assets/images/cblogo.png' 
import { setLoggedInUser,postLogin } from '../../helpers/authUtils';

import axios from 'axios'

class PublicHome extends Component {

    checklogin(username,password,history) {
        localStorage.setItem('user',JSON.stringify({username: "cbtest01@communitybacker.com"}))
        history.push('/home')
    }

    

    constructor(props) {
        super(props);
        this.state = {
            username: "cbtest01@communitybacker.com",
            password: "123456"
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event, values) {
        this.checklogin(values.username,values.password,this.props.history)
        //this.props.checkLogin(values.username, values.password, this.props.history);

    }

    componentDidMount() {
        this.props.clearErrorLogin();
        this.props.clearError();
    }

    render() {

        return (
            <React.Fragment>
                
                
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user, loginError, loading } = state.Login;
    return { user, loginError, loading };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, clearErrorLogin, clearError })(PublicHome));





