import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardBody, FormGroup, Col, Row, Card, Input, Button } from 'reactstrap';
//import Calendar from 'react-widgets/lib/Calendar'
import Calendar from 'react-calendar';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { getTest } from '../../services/testService'

import { getLoggedInUser } from '../../helpers/authUtils'

import 'react-widgets/dist/css/react-widgets.css';
import '../../assets/css/slim.css'

import 'react-calendar/dist/Calendar.css';

import { config } from '../../config'
const API_URL = config.url.API_URL
const COMPANY_NAME = config.company.name
const BASEAPI = `${API_URL}/api/v1`

class Activity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : COMPANY_NAME, link : "#" },
                { title : "Activity", link : "#" },
                // { title : "Blank page", link : "#" },
            ],
            // username: ""
        }
    } 

    componentDidMount(){
        this.props.setBreadcrumbItems("Activity", this.state.breadcrumbItems);
        // const result = getLoggedInUser() 
        // console.log("Logged In User");
        // console.log(result.username)
        // this.setState({username: result.username})
    }

    render() {
        return (
            <React.Fragment>

                {/* <Row>
                    <Col xs="12"> */}
                        {/* <Card>
                            <CardBody>
                                <h4 className="card-title">Activity</h4>
                                <p className="card-title-desc">
                                    Coming Soon!
                                </p>
                                </CardBody>
                                </Card> */}
                                
                                
                                {/* <div class="slim-mainpanel"> */}
      {/* <div class="container"> */}
        {/* <div class="slim-pageheader">
          <ol class="breadcrumb slim-breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Pages</a></li>
            <li class="breadcrumb-item active" aria-current="page">Timeline</li>
          </ol>
          <h6 class="slim-pagetitle">Timeline</h6>
        </div> */}

        <div class="row row-sm row-timeline">
          <div class="col-lg-9">

            <div class="card pd-30">
              <div class="timeline-group">
                <div class="timeline-item timeline-day">
                  <div class="timeline-time">&nbsp;</div>
                  <div class="timeline-body">
                    <p class="timeline-date">Today</p>
                  </div>
                </div>
                <div class="timeline-item">
                  <div class="timeline-time">10:30pm</div>
                  <div class="timeline-body">
                    <p class="timeline-title"><a href="">Application for Small Business Loan ABC for Business Expansion</a>(Status: Viewed)</p>
                    <p class="timeline-author"><a href="">Elisse Joson</a> PC Repair Shop, Smallville, MD</p>
                    <p class="timeline-text">Looking for a loan to help expand my business  </p>
                    {/* <p class="tx-12 mg-b-0"><a href="">Like</a> &nbsp;&nbsp; <a href="">Comment</a></p> */}
                    <Button color="primary" className="w-md waves-effect waves-light" type="submit">View Application</Button>
                  </div>
                </div>
                <div class="timeline-item">
                  <div class="timeline-time">8:15pm</div>
                  <div class="timeline-body">
                    <p class="timeline-title"><a href="">Application for Small Business Loan XYZ for Starting Business</a>(Status: New)</p>
                    <p class="timeline-author"><a href="">Katherine Lumaad</a> Oakland, CA</p>
                    <p class="timeline-text">Looking for a loan to start a flower shop in my neighborhood.</p>
                    <Button color="primary" className="w-md waves-effect waves-light" type="submit">View Application</Button>
                    {/* <Button type="button" color="link" className="waves-effect">View Application</Button> */}

                    {/* <div class="row mg-b-15">
                      <div class="col-6">
                        <a href=""><img src="http://via.placeholder.com/1000x667" class="img-fluid" alt="" /></a>
                      </div>
                    </div> */}

                    {/* <p class="tx-12 mg-b-0"><a href="">Like</a> &nbsp;&nbsp; <a href="">Comment</a></p> */}
                  </div>
                </div>
                <div class="timeline-item">
                  <div class="timeline-time">3:30pm</div>
                  <div class="timeline-body">
                    <p class="timeline-title"><a href="">Application for Commercial Car Loan</a></p>
                    <p class="timeline-author"><a href="">Elisse Joson</a> PC Repair Shop, Smallville, MD</p>
                    <p class="timeline-text">Looking for a loan to buy van for repair shop</p>
                    <Button color="primary" className="w-md waves-effect waves-light" type="submit">View Application</Button>
                    {/* <p class="tx-12 mg-b-0"><a href="">Like</a> &nbsp;&nbsp; <a href="">Comment</a></p> */}
                  </div>
                </div>
                {/* <div class="timeline-item timeline-day">
                  <div class="timeline-time">&nbsp;</div>
                  <div class="timeline-body">
                    <p class="timeline-date">Yesterday</p>
                  </div>
                </div> */}
                {/* <div class="timeline-item">
                  <div class="timeline-time">6:30pm</div>
                  <div class="timeline-body">
                    <p class="timeline-title"><a href="">An Engineer Explains Why You Should Always Order the Larger Pizza</a></p>
                    <p class="timeline-author"><a href="">Elisse Joson</a> San Francisco, CA</p>
                    <p class="timeline-text">In this lesson, you create a layout in XML that includes a text field and a button. In the next lesson, your app responds when the </p>

                    <div class="row row-xs mg-b-15">
                      <div class="col-6">
                        <a href=""><img src="http://via.placeholder.com/1000x667" class="img-fluid" alt="" /></a>
                      </div>
                      <div class="col-6">
                        <a href=""><img src="http://via.placeholder.com/1000x667" class="img-fluid" alt="" /></a>
                      </div>
                    </div>

                    <p class="tx-12 mg-b-0"><a href="">2 Likes</a> &nbsp;&nbsp; <a href="">1 Comment</a></p>
                  </div>
                </div> */}
                {/* <div class="timeline-item">
                  <div class="timeline-time">1:15pm</div>
                  <div class="timeline-body">
                    <p class="timeline-title"><a href="">The Strange Persistence of First Languages</a></p>
                    <p class="timeline-author"><a href="">Elisse Joson</a> San Francisco, CA</p>
                    <p class="timeline-text">In this lesson, you create a layout in XML that includes a text field and a button. In the next lesson, your app responds when the </p>
                    <p class="tx-12 mg-b-0"><a href="">Like</a> &nbsp;&nbsp; <a href="">Comment</a></p>
                  </div>
                </div> */}
                {/* <div class="timeline-item timeline-day">
                  <div class="timeline-time">&nbsp;</div>
                  <div class="timeline-body">
                    <p class="timeline-date">December 20, 2017</p>
                  </div>
                </div> */}
                {/* <div class="timeline-item">
                  <div class="timeline-time">11:45pm</div>
                  <div class="timeline-body">
                    <p class="timeline-title"><a href="">How to Win Your Next Political Argument</a></p>
                    <p class="timeline-author"><a href="">Elisse Joson</a> San Francisco, CA</p>
                    <p class="timeline-text">In this lesson, you create a layout in XML that includes a text field and a button. In the next lesson, your app responds when the </p>
                    <p class="tx-12 mg-b-0"><a href="">Like</a> &nbsp;&nbsp; <a href="">Comment</a></p>
                  </div>
                </div> */}
              </div>
            </div>

          </div>
          <div class="col-lg-3 mg-t-20 mg-lg-t-0">
            {/* <div id="datepicker" class="timeline-datepicker"></div> */}
            {/* <Calendar /> */}

            <div class="card pd-20">
              <Calendar />
              <br />
              <label class="slim-card-title">Past Updates</label>
              <div class="post-group">
                <div class="post-item">
                  <span class="post-date">Today, 10:30pm</span>
                  <p class="post-title"><a href=""><a href="">Application for Small Business Loan ABC for Business Expansion</a>(Status: Viewed)</a></p>
                  <p class="tx-12 mg-b-0"><a href="">Elisse Joson</a> PC Repair Shop, Smallville, MD</p>
                  <p class="tx-12 mg-b-0">Looking for a loan to expand my business.</p>
                  <Button color="primary" className="w-md waves-effect waves-light" type="submit">View Application</Button>
                </div>
                <div class="post-item">
                  <span class="post-date">Today, 8:15pm</span>
                  <p class="post-title"><a href="">Application for Small Business Loan XYZ for Starting Business</a>(Status: New)</p>
                  <p class="tx-12 mg-b-10"><a href="">Katherine Lumaad</a> Oakland, CA</p>
                  <p class="tx-12 mg-b-0">Looking for a loan to start a flower shop in my neighborhood.</p>
                  <Button color="primary" className="w-md waves-effect waves-light" type="submit">View Application</Button>
                </div>
                <div class="post-item">
                  <span class="post-date">Today, 3:30pm</span>
                  <p class="post-title"><a href="">Application for Commercial Car Loan</a></p>
                  <p class="tx-12 mg-b-10"><a href="">Elisse Joson</a> PC Repair Shop, Smallville, MD</p>
                  <p class="tx-12 mg-b-15">Looking for a loan to buy van for repair shop</p>
                  <Button color="primary" className="w-md waves-effect waves-light" type="submit">View Application</Button>
                  {/* <a href=""><img src="http://via.placeholder.com/500x334" class="img-fluid" alt="" /></a> */}
                </div>
                {/* <div class="post-item">
                  <span class="post-date">Dec 21, 2017 5:00am</span>
                  <p class="post-title"><a href="">What Romance Really Means After 10 Years of Marriage</a></p>
                  <p class="tx-12 mg-b-10"><a href="">Elisse Joson</a> San Francisco, CA</p>
                  <p class="tx-12 mg-b-0">In this lesson, you create a layout in XML that includes a text field and a button...</p>
                </div> */}
                {/* <div class="post-item">
                  <span class="post-date">Dec 20, 2017 4:20am</span>
                  <p class="post-title"><a href="">Buying organic veggies at the supermarket is a waste of money</a></p>
                  <p class="tx-12 mg-b-10"><a href="">Elisse Joson</a> San Francisco, CA</p>
                  <p class="tx-12 mg-b-0">In this lesson, you create a layout in XML that includes a text field and a button...</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>



      {/* </div> */}
    {/* </div> */}





                                
                                {/* </Col></Row> */}
                              
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(Activity)