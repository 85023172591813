import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkLogin, clearErrorLogin, clearError } from '../../store/actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import logodark from "../../assets/images/logo-dark.png";
import cblogo from '../../assets/images/cb-logo.png' 
import { setLoggedInUser,postLogin } from '../../helpers/authUtils';
import axios from 'axios'
//import './login.css'
import { config } from '../../config'
const API_URL = config.url.API_URL
const BASEAPI = `${API_URL}/api/v1`

class Pageslogin extends Component {

    checklogin(username,mfaCode,password,history) {

        const payload = {
            emailAddress: username,
            mfaCode: mfaCode,
            password: password
        }

        axios.post(`${BASEAPI}/lenders-auth/login`, payload).then(result => {
            console.log(result)
            if (result.data.apiStatus == 'success') {
                console.log("success")
                console.log(result.data.apiData)

                // axios.get(`${BASEAPI}/lenders-utils/account/${result.data.apiData._id}`).then(account => {
                //     console.log(account)
                // })
                
                console.log("SUCCESS")
                console.log(result.data.apiData)
                localStorage.setItem('cb-lender-user',JSON.stringify({
                    _id: result.data.apiData._id,
                    cbid: result.data.apiData.cbid,
                    token: result.data.apiData.token,
                    firstName: result.data.apiData.firstName,
                    lastName: result.data.apiData.lastName,
                    lenderId: result.data.apiData.lenderId,
                    username: username
                }))
                history.push('/home')
            } else {
                console.log('error')
                this.setState({ loginError: true, loginErrorMessage: "There was a problem with your login attempt. Please try again!" })
            }
        })
        .then()
        .catch(error => {
            this.setState({ loginError: true, loginErrorMessage: "There was a problem with your login attempt. Please try again!" })
        })
        //localStorage.setItem('user',JSON.stringify({username: "cbtest01@communitybacker.com"}))
        //history.push('/home')
    }

    getCode() {
        const payload = {
            emailAddress: this.state.username
        }
        console.log(payload)
        axios.post(`${BASEAPI}/lenders-auth/verify`, payload).then(result => {
            console.log(result)
        })
        .catch(error => {

        })

    }

    constructor(props) {
        super(props);
        this.state = {
            // username: "cbtest01@communitybacker.com",
            // password: "123456",
            username: "",
            mfaCode: "",
            password: "",
            loginError: false,
            loginErrorMessage: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
 
    handleSubmit(event, values) {
        this.checklogin(values.username, values.mfaCode, values.password, this.props.history)
        //this.props.checkLogin(values.username, values.password, this.props.history);

    }

    componentDidMount() {
        this.props.clearErrorLogin();
        this.props.clearError();
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <h3 className="text-center mt-4">
                                            <Link to="\" className="logo logo-admin"><img src={cblogo} height="30" alt="logo" /></Link>
                                        </h3>
                                        <div className="p-3">
                                            <h4 className="text-muted font-size-18 mb-1 text-center">Welcome Back !</h4>
                                            <p className="text-muted text-center">Sign in below to continue to the backabl lender portal</p>
                                            {this.props.user && <Alert color="success">
                                                Your Login is successfull.</Alert>}

                                            {this.state.loginError && <Alert color="danger">
                                                {this.state.loginErrorMessage}</Alert>}
                                            <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleSubmit}>

                                                <label htmlFor="username">Username</label>
                                                <AvField name="username" placeholder="Enter Email" value={ this.state.username } onChange={e => this.setState({username: e.target.value})} type="email" required />

                                                <div className="form-group row mt-4">
                                                    <Col xs="12" className="text-right">
                                                        <Button color="primary" className="w-md waves-effect waves-light" onClick={() => this.getCode()}>Get Code</Button>
                                                    </Col>
                                                </div>

                                                <label htmlFor="usercode">MFA Code</label>
                                                <AvField name="mfaCode" type="number" value={ this.state.mfaCode } onChange={e => this.setState({mfaCode: e.target.value})} placeholder="Enter code" required />

                                                <label htmlFor="userpassword">Password</label>
                                                <AvField name="password" type="password" value={ this.state.password } onChange={e => this.setState({password: e.target.value})} placeholder="Enter password" required />

                                                <div className="form-group row mt-4">
                                                    <Col xs="6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                        </div>
                                                    </Col>
                                                    <Col xs="6" className="text-right">
                                                        <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                    </Col>
                                                </div>
                                                <div className="form-group mb-0 row">
                                                    <Col xs="12" className="mt-4">
                                                        <Link to="/forget-password" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                                                    </Col>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>&copy; {new Date().getFullYear()} - Built with <i className="mdi mdi-heart text-danger"></i> in Washington, D.C. by Backabl, Inc.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user, loginError, loading } = state.Login;
    return { user, loginError, loading };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, clearErrorLogin, clearError })(Pageslogin));





